import instance from '../api.js';

class FileUploadProxy {
  static uploadFile(file) {
    const formData = new FormData();
    formData.append("file", file);
    return instance.post('registration-request/uploadfile', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  }
}

export default FileUploadProxy;