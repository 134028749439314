export class NewRegistrationItemDto {
  constructor(
    documentTypeId,
    files,
    territorialPlanningRegulationFiles,
    stateSnapshot,
    geometrySourceInfo,
    geometry
  ) {
    this.documentTypeId = documentTypeId;
    this.files = files;
    this.territorialPlanningRegulationFiles = territorialPlanningRegulationFiles;
    this.stateSnapshot = stateSnapshot;
    this.geometrySourceInfo = geometrySourceInfo;
    this.geometry = geometry;
  }
}

export class UpdateRegistrationItemDto extends NewRegistrationItemDto {
  constructor(
    docId,
    documentTypeId,
    files,
    territorialPlanningRegulationFiles,
    stateSnapshot,
    geometrySourceInfo,
    geometry
  ) {
    super(
      documentTypeId,
      files,
      territorialPlanningRegulationFiles,
      stateSnapshot,
      geometrySourceInfo,
      geometry
    );
    this.id = docId;
  }
}
