<template>
  <button class="button button--light" @click="showModal">
    <slot>Удалить</slot>
  </button>
  <modal-window :visible="visibleModal" @close="closeModal">
    <template #title>Удаление документов</template>
    Вы хотите удалить все созданные документы?
    <template #footer>
      <div class="group">
        <button class="button button--primary group__item" @click="onConfirmDeleteClick">
          Удалить
        </button>
        <button class="button button--light group__item" @click="closeModal">Отмена</button>
      </div>
    </template>
  </modal-window>
</template>

<script>
import { ref } from "vue";
export default {
  emits:["remove"],
  setup(_, { emit }) {
    const visibleModal = ref(false);

    function showModal() {
      visibleModal.value = true;
    }
    function closeModal() {
      visibleModal.value = false;
    }

    function onConfirmDeleteClick() {
      visibleModal.value = false;
      deleteRegistrationObject();
    }
    async function deleteRegistrationObject() {
      emit("remove");
      visibleModal.value = false;
    }

    return {
      visibleModal,
      onConfirmDeleteClick,
      showModal,
      closeModal,
    };
  },
};
</script>