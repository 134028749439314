<template>
  <div>
    <div class="form-control" data-qa="fromOktmo">
      <label class="label" for="oktmo">ОКТМО</label>
      <input
          class="field"
          id="oktmo"
          name="oktmo"
          type="text"
          maxlength="11"
          :value="geometrySourceInfo.oktmo"
          @blur="updateOktmo($event)"
          @keypress="onKeyPress"
      />
    </div>
    <br/>
    <GeometryToolbar
        :apply-geometry-fn="applyGeometry"
        :remove-geometry-fn="removeGeometry"
    ></GeometryToolbar>
  </div>
</template>

<script>
import GeometryProxy from "../../../proxies/geometryProxy";
import {
  GeometrySource,
  GeometrySourceInfoFromOKTMO,
} from "../../../models/geometrySource";
import GeometryToolbar from "./GeometryToolbar";
import {ref, inject} from "vue";

export default {
  props: {
    geometrySourceInfo: {
      type: Object,
      required: true,
    },
    geometry: {
      type: [Object, String],
      required: true,
    },
  },
  emits: ["update:geometrySource"],
  components: {GeometryToolbar},
  setup(props, {emit}) {
    const toast = inject("toast");
    const geometry = ref({});

    function applyGeometry() {
      if (!props.geometrySourceInfo.oktmo) {
        toast.warning(
            "Чтобы сформировать территорию действия, укажите код ОКТМО."
        );
        return;
      }
      removeGeometry();

      try {
        GeometryProxy.getFromOKTMO(props.geometrySourceInfo.oktmo).then(
            (result) => {
              geometry.value = result;
              emit(
                  "update:geometrySource",
                  new GeometrySource(
                      new GeometrySourceInfoFromOKTMO(props.geometrySourceInfo.oktmo),
                      geometry
                  )
              );

              if (geometry.value.original.geometry.includes("Line"))
              {
                emit("error", "Тип геометрии у объекта должен быть полигон");
              }
            }
        ).catch((error) => {
          if (error == "Cancel") return;
          emit("error", "У объекта нет геометрии");
        });
        // DS: Try-catch оставлен в целях обработки ошибок основанных не на promises
      } catch
          (error) {
        if (error == "Cancel") return;
        emit("error", "У объекта нет геометрии");
      }
    }

    function onKeyPress(event) {
      let char = String.fromCharCode(event.keyCode);
      if (/^[0-9]+$/.test(char)) return true;
      else event.preventDefault();
    }

    function removeGeometry() {
      emit(
          "update:geometrySource",
          new GeometrySource(
              new GeometrySourceInfoFromOKTMO(props.geometrySourceInfo.oktmo),
              null
          )
      );
    }

    function updateOktmo(event) {
      emit(
          "update:geometrySource",
          new GeometrySource(
              new GeometrySourceInfoFromOKTMO(event.target.value),
              props.geometrySourceInfo.geometry
          )
      );
    }

    return {
      applyGeometry,
      onKeyPress,
      removeGeometry,
      updateOktmo,
    };
  },
}
;
</script>

<style>
/* .help {
  display: block;
  font-size: 0.75rem;
  margin-top: 0.25rem;
}
.help--error {
  color: var(--basic-red);
} */
</style>
