<template>
  <div class="navbar-dropdown" :class="{ 'navbar-dropdown--active': showMenu }">
    <OnClickOutside :do="close">
    <div class="navbar-dropdown__value" @click="showMenu = !showMenu">
      <button
        class="navbar__menu-item navbar__menu-item--user menu-button"
        aria-label="Личный кабинет"
        title="Личный кабинет"
        data-qa="user"
      >
      <svg
          width="36"
          height="36"
          viewBox="0 0 36 36"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <use xlink:href="#icon-account" />
        </svg>
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <use xlink:href="#icon-chevron-down" />
        </svg>
      </button>
    </div>
    </OnClickOutside>
    <ul class="navbar-dropdown__menu navbar-dropdown__menu--right" v-show="showMenu">
      <li class="navbar-dropdown__item" @click="logout">
        <svg
          width="16"
          height="16"
          viewBox="0 0 36 36"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <use xlink:href="#icon-logout" />
        </svg>
        Выход
      </li>
    </ul>
  </div>
</template>

<script>
import {ref} from "vue";
import useAuthorization from '../../authorization';
import OnClickOutside from '../basic/OnClickOutside.vue';

export default {
  components: { OnClickOutside },
  setup() {
    const showMenu = ref(false);
    const { signOut } = useAuthorization();

    async function logout() {
      await signOut();
    }

    function close() {
      showMenu.value = false;
    }

    return { showMenu, logout, close };
  },
};
</script>

<style scoped>
.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 40px;
  padding-right: 40px;
}

.navbar__menu-item {
  margin-left: 10px;
}

.menu-button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 44px;
  cursor: pointer;
}
.navbar__menu-item--help {
  width: 44px;
}

.navbar__menu-item--user {
  width: 56px;
  background-color: transparent;
  border: none;
  outline: none;
}

.menu-button svg {
  stroke: var(--basic-black);
}

.menu-button:hover svg {
  stroke: var(--secondary-blue-darker);
}
.navbar-dropdown {
  position: relative;
}

.navbar-dropdown__value {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.navbar-dropdown__text {
  margin-right: 5px;
}

.navbar-dropdown__value:hover .dropdown__text {
  color: var(--primary-blue-light);
}

.navbar-dropdown__value:hover path {
  stroke: var(--primary-blue-light);
}

.navbar-dropdown__menu {
  position: absolute;
  top: 100%;
  z-index: 20;
  display: none;
  width: 100%;
  min-width: 200px;
  max-width: 800px;
  max-height: 270px;
  padding: 6px 0px;
  margin-top: 0;
  overflow-y: auto;
  border: 1px solid var(--basic-grey);
  border-radius: 6px;
  background-color: var(--basic-white);
  filter: var(--dropdown-shadow);
}

.navbar-dropdown--active .navbar-dropdown__menu {
  display: block;
}

.navbar-dropdown__menu--right {
  right: 0%;
}

.navbar-dropdown__item {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 6px 12px;
  color: var(--secondary-blue-darker);
  font-size: 16px;
  line-height: 20px;
  cursor: pointer;
}

.navbar-dropdown__item:hover {
  background-color: var(--secondary-blue-lighter);
  color: var(--basic-black);
}

a.navbar-dropdown__item {
  text-align: inherit;
  white-space: nowrap;
  width: 100%;
}
</style>
