<template>
  <div class="buttons">
    <router-link
      :to="{
        name: 'registrationDocumentCreate',
        params: { number: number },
      }"
      class="button button--primary button--left-icon button-new-doc"
      data-qa="newDocument"
      >Новый документ</router-link
    >
    <router-link
      :to="{
        name: 'registrationDocumentCreateChange',
        params: { number: number },
      }"
      class="button button--primary button--left-icon button-change-doc"
      data-qa="newChange"
      >Изменения в документ
    </router-link>
  </div>
</template>

<script>
export default {
  props: {
    number: String,
  },
};
</script>
<style scoped>
.buttons>:not(:first-child) {
  margin-left: 12px;
}
.button-new-doc::before {
  background-image: url("../../assets/svg/new-doc.svg");
}
.button-change-doc::before {
  background-image: url("../../assets/svg/change-doc.svg");
}
</style>