<template>
  <div>
    <article class="box">
      <card-header>
        <template #header-text>
          {{ doc.documentType.displayName }} №{{ doc.stateSnapshot.docNum }} от {{ $filters.toDateString(doc.stateSnapshot.docDate) }}
        </template>
        <template #header-status>
          <doc-status
            :status="doc.status"
            class="doc-status--inprocess"
          ></doc-status>
        </template>
        <template #header-button>
          <doc-delete-button
            :inboxdataNumber="inboxdataNumber"
            :registrationItemId="doc.id"
            @remove="onRemove"
          ></doc-delete-button>
        </template>
      </card-header>
      <card-content
        ><files-field
          :files="doc.files"
          :readonly="true"
          :outline="false"
        ></files-field
      ></card-content>
      <div class="card-footer">
        <router-link
          class="button button--primary"
          :to="{
            name: 'registrationDocumentEdit',
            params: { number: inboxdataNumber, id: doc.id },
          }"
          >Открыть</router-link
        >
      </div>
    </article>
  </div>
</template>
<script>
import FilesField from '../../basic/form/files/FilesField';
import RegistrationDocumentStatus from './RegistrationDocumentStatus';
import RegistrationDocumentDeleteButton from './RegistrationDocumentDeleteButton';
import CardHeader from './RegistrationDocumentCardHeader';
import CardContent from './RegistrationDocumentCardContent';
export default {
  props: {
    doc: {
      required: true,
      type: Object,
    },
    inboxdataNumber: String,
  },
  emits: ['removeDocument'],
  components: {
    CardHeader,
    CardContent,
    FilesField,
    DocStatus: RegistrationDocumentStatus,
    DocDeleteButton: RegistrationDocumentDeleteButton,
  },
  methods: {
    onRemove(event) {
      this.$emit('removeDocument', event);
    },
  },
};
</script>
<style scoped>
.card-footer {
  display: flex;
  justify-content: flex-end;
  padding-top: 20px;
}
</style>
