import SectionDocument from "./sectionDocument"

export default class DocumentWithCorrection {
    constructor(
        correctionDate,
        sectionDocument
    ) {
        this.correctionDate = correctionDate,
        this.sectionDocument = sectionDocument
    }

    static fromDto(dto, inboxDataNumber) {
        return new DocumentWithCorrection(
            dto.correctionDate ? new Date(dto.correctionDate) : null,
            SectionDocument.fromDto(dto.documentState, inboxDataNumber)
        )
    }
}