import useAuthorization from '../../authorization';
import { useRouter } from 'vue-router';

export default {
  setup() {
    const { getCallback } = useAuthorization();
    const router = useRouter();
    getCallback().then((user) => {
      router.push(user.state || '/');
    });
    return {};
  },
};
