<template>
  <header class="doc__header doc-header">
    <strong class="doc-header__text title-16">
      <slot name="header-text"></slot>
    </strong>
    <slot name="header-status"></slot>
    <slot name="header-button"></slot>
  </header>
</template>
<script>
export default {
};
</script>
<style scoped>
.doc-header {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.doc-header--placed {
  grid-template-columns: min-content 1fr min-content min-content;
}

.doc-header__text {
  width: 100%;
}

.doc-header__delimiter {
  border-top: 1px solid var(--basic-grey-dark);
}

.doc-header__collapse-button--down {
  transform: rotate(-180deg);
}
</style>
