import useSectionRoutes from '../hooks/sectionRoutes';

const {reesterRoutes, docRoutes} = useSectionRoutes();
const routes = [];
reesterRoutes.forEach((r) => {
	if (r.number == 11)
		routes.push({
			...r,
			component: () =>
				import(`../components/Section11Page`),
		});
	else 
		routes.push({
			...r,
			component: () =>
				import(`../components/gisogdDocuments/DocumentsPage`),
		});
});
docRoutes.forEach((r) => {
	routes.push({
		...r,
		component: () =>
			import(`../components/gisogdDocuments/DocumentPage`),
	});
});

export default routes;
