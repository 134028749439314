export default function beforeRouterEnter(to, from, next) {
  next((vm) => {
    vm.backLinkName = 'Назад';
    if (from && from.meta && from.meta.name) {
      vm.backLinkName += ` ${from.meta.name}`;

      if (from.params.number) vm.backLinkName += from.params.number;
    }
  });
}
