import OidcCallback from '../authorization/component/OidcCallback';
import OidcOutCallback from '../authorization/component/OidcOutCallback';
const routes = [
  {
    path: '/oidc-callback',
    component: OidcCallback,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/oidc-out-callback',
    component: OidcOutCallback,
    meta: {
      requiresAuth: false,
    },
  }
];

export default routes;
