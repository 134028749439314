import instance from '../api.js';
import Geometry from '../models/geometry';
import { GeometryFileDto } from '../dtos/storageFileDto';

const baseURL =
  process.env.NODE_ENV === 'production'
    ? window.location.origin + '/api/search/geometry'
    : 'http://localhost:65070/api/search/geometry';

let source = null;

class GeometryProxy {
  static async getFromFiles(files) {
    if (source) source.cancel();
    source = instance.CancelToken.source();

    const dtos = files.map((f) => new GeometryFileDto(f.name, f.fileUrl));
    const dto = await instance.post(``, dtos, {
      noProjectContext: true,
      baseURL,
      cancelToken: source.token,
    });
    return new Geometry(dto.original, dto.wgs84);
  }

  static async getFromOKTMO(oktmo) {
    if (source) source.cancel();
    source = instance.CancelToken.source();

    const dto = await instance.get(`oktmo/${oktmo}`, {
      baseURL,
      cancelToken: source.token,
    });
    return new Geometry(dto.original, dto.wgs84);
  }

  static async getFromKN(kn) {
    if (source) source.cancel();
    source = instance.CancelToken.source();

    const dto = await instance.post(`kn`, kn, {
      baseURL,
      cancelToken: source.token,
    });
    return new Geometry(dto.original, dto.wgs84);
  }

  static async getFromVector(type, vector) {
    if (source) source.cancel();
    source = instance.CancelToken.source();

    const dto = await instance.post(type, vector, {
      baseURL,
      cancelToken: source.token,
    });
    return new Geometry(dto.original, dto.wgs84);
  }
}

export default GeometryProxy;
