export default class DocBase {
  constructor(
    id,
    docStatus,
    docClass,
    docName,
    docNum,
    docDate,
    orgName,
    canceledDocuments=[],
    note,
    affectedMunicipalities = [],
    guid
  ) {
    this.id = id || 0;
    this.docStatus = docStatus;
    this.docClass = docClass;
    this.docName = docName;
    this.docNum = docNum;
    this.docDate = docDate;
    this.orgName = orgName || {};
    this.canceledDocuments = canceledDocuments;
    this.note = note;
    this.affectedMunicipalities = affectedMunicipalities;
    this.guid = guid;
  }
}