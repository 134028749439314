import SubjectStatus from "./subjectStatus";

export default class Person {
    constructor(firstName, lastName, middleName, phone, email, ogrnip, passportSeries, passportNumber, snils, inn, externalId, displayName, type, subjectStatusKey, guid) {
        this.firstName = firstName;
        this.lastName = lastName;
        this.middleName = middleName;
        this.phone = phone;
        this.email = email;
        this.ogrnip = ogrnip;
        this.passportSeries = passportSeries;
        this.passportNumber = passportNumber;
        this.snils = snils;
        this.inn = inn;
        this.externalId = externalId;
        this.displayName = displayName;
        this.type = type;
        this.subjectStatus = SubjectStatus.fromDto(subjectStatusKey);
        this.guid = guid;
    }

    static fromDto(dto) {
        return new Person(dto.firstName, dto.lastName, dto.middleName, dto.phone, dto.email, dto.ogrnip, dto.passportSeries, dto.passportNumber, dto.snils, dto.inn, dto.externalId, dto.displayName, dto.type, dto.subjectStatusKey, dto.guid);
    }

    static fromDtos(dtos) {
        return dtos.map((dto) => new Person(dto.firstName, dto.lastName, dto.middleName, dto.phone, dto.email, dto.ogrnip, dto.passportSeries, dto.passportNumber, dto.snils, dto.inn, dto.externalId, dto.displayName, dto.type, dto.subjectStatusKey, dto.guid));
    }
}