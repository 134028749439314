import DocBase from "./docBase";
export default class DocSection8 extends DocBase {
  constructor(
    id,
    docStatus,
    docClass,
    docName,
    docNum,
    docDate,
    orgName,
    canceledDocuments=[],
    note,
    kindsOfEngIz = [],
    organizationWhoPrepared = {},
    affectedMunicipalities = [],
    guid
  ) {
    super(
      id,
      docStatus,
      docClass,
      docName,
      docNum,
      docDate,
      orgName,
      canceledDocuments,
      note,
      affectedMunicipalities,
      guid
    );
    this.kindsOfEngIz = kindsOfEngIz;
    this.organizationWhoPrepared = organizationWhoPrepared;
  }
}