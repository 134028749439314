import axios from 'axios';
import eventBus from './eventBus';
import useAuthorization from './authorization';
import useProjectAlias from './hooks/projectAlias';

const { getUser } = useAuthorization();

axios.defaults.headers.get['Accepts'] = 'application/json';
axios.defaults.headers.get['Content-Type'] =
  'application/json, text/plain, */*';

const instance = axios.create({
  baseURL:
    process.env.NODE_ENV === 'production'
      ? window.location.origin + '/api'
      : 'http://localhost:5050/api',
  headers: {
    'Content-Type': 'application/json, text/plain, */*',
  },
});
instance.interceptors.response.use(
  function(response) {
    if (response.data instanceof Blob) return response;
    return response.data;
  },
  async function(error) {
    if (axios.isCancel(error)) {
      return Promise.reject(error);
    } else {
      await handleError(error);
    }
    return Promise.reject(error);
  }
);
instance.interceptors.request.use(
  async function(config) {
    await applyToken(config);
    applyProjectAlias(config);
    return config;
  },
  async function(error) {
    if (axios.isCancel(error)) {
      return Promise.reject(error);
    } else {
      await handleError(error);
    }
    return Promise.reject(error);
  }
);
instance.CancelToken = axios.CancelToken;

const applyToken = async function(config) {
  if (!config.noAuth) {
    const user = await getUser();
    if (user)
      config.headers.common['Authorization'] = 'Bearer ' + user.access_token;
  }
};

const applyProjectAlias = function(config) {
  if (!config.noProjectContext) {
    const { projectAlias } = useProjectAlias();
    if (projectAlias.value) {
      config.baseURL = `${config.baseURL}/${projectAlias.value}`;
      config.headers.common['X-Project-Alias'] = projectAlias.value;
    }
  }
};

const handleError = async function(error) {
  let message = error.response
    ? 'Произошла непредвиденная ошибка.'
    : 'Ошибка сети';
  if (error.response) {
    if (
      error.request.responseType === 'blob' &&
      error.response.data instanceof Blob
    ) {
      error.response.data = await parseErrorMessageFromBlob(
        error.response.data
      );
    }
    switch (error.response.status) {
      case 413:
        message = 'Невозможно загрузить файл размером больше 1Гб.';
        break;
      case 404: {
        eventBus.emit('404', { message: error.response.data });
        return;
      }
      case 400: {
        return;
      }
      case 403: {
        message = 'У вас недостаточно прав для этого действия.';
        break;
      }
      case 409: {
        return;
      }
      default:
        message = error.response.data || error.response.statusText;
        break;
    }
  }

  if(error.response.data === "None of the specified endpoints were reachable")
    message = "Некоторые поля ввода не загрузили данные в выпадающие списки, так как сервис RabbitMQ не работает. Попробуйте позже. Если проблема не решится, сообщите администратору.";

  eventBus.emit('error', { message });
};

const parseErrorMessageFromBlob = function(blob) {
  return new Promise((resolve) => {
    let reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result);
    };

    reader.readAsText(blob);
  });
};

export default instance;
