import Person from "./person";
import Organization from "./organization";
import Entrepreneur from "./enterprenuer";

export default class Subject {
  constructor(id, displayName, type) {
    this.id = id;
    this.displayName = displayName;
    this.type = type;
  }

  static fromDtos(dtos) {
    return dtos.map((dto) => new Subject(dto.id, dto.displayName, dto.type));
  }
  static fromDto(dto) {
    return new Subject(dto.id, dto.displayName, dto.type);
  }
}

export class SubjectResult {
  constructor(isSuccess, error, dto) {
    this.isSuccess = isSuccess;
    this.error = error;

    if(dto)
    {
      switch (dto.type.toLowerCase()) {
        case 'person':
          this.value = Person.fromDto(dto)
          break;
        case 'organization':
          this.value = Organization.fromDto(dto)
          break;
        case 'enterpreneur':
          this.value = Entrepreneur.fromDto(dto)
          break;
      }
    }
  }

  static fromDtos(dtos) {
    return dtos.map((dto) => new SubjectResult(dto.isSuccess, dto.error, dto.value));
  }
  static fromDto(dto) {
    return new SubjectResult(dto.isSuccess, dto.error, dto.value);
  }
}
