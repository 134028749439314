<template>
  <div class="page technical-correction-page">
    <page-header class="container">
      <template #title>
        {{ editableDocument.fullname }}
      </template>
      <template #subtitle>
        <BackLink>Назад</BackLink>
      </template>
      <template #buttons v-if="haveEditRight">
        <!-- <SaveButton
          v-if="currentStep === pageSteps.step1"
          @click="updateDocument"
        ></SaveButton> -->
        <button
          class="button button--outline button--left-icon button-back"
          v-if="currentStep.back"
          @click="moveBackStep"
          data-qa="back"
        >
          Назад
        </button>
        <button
          class="button button--primary button--right-icon button-next"
          @click="moveNextStep"
          data-qa="next"
          v-if="currentStep.next"
        >
          Далее
        </button>
        <button
          class="button button--primary button--left-icon button-save"
          @click="completeUpdatingDocument"
          data-qa="next"
          v-if="!currentStep.next"
        >
          Завершить исправление
        </button>
      </template>
    </page-header>
    <Wizard
      class="container"
      :class="!pageSteps.step3 ? 'narrow' : ''"
      v-if="state.document"
    >
      <Step
        :name="pageSteps.step1.tag"
        :is-active="currentStep == pageSteps.step1"
        description="Информация о документе"
        :done="pageSteps.step1.done"
      >
        <div class="box container">
          <form class="form form--fullwidth grid3on2" @submit.prevent="">
            <div>
              <fieldset class="form__fieldset">
                <legend class="form__legend">Основные свойства</legend>
              </fieldset>
              <TextField
                :modelValue="editableDocument.registrationNumber"
                :isReadonly="true"
              >
                <label class="label">Регистрационный номер</label>
              </TextField>
              <!-- todo: стили на инпут -->
              <DateField
                :modelValue="editableDocument.registrationDate"
                :isReadonly="true"
              >
                <label class="label"
                  >Дата размещения в информационной системе</label
                >
              </DateField>
              <component
                v-if="state.documentType"
                class="form__fieldset"
                :is="documentTypeName"
                v-model="state.document"
                @update:vector="updateVector"
                :document-type-id="state.documentType.id"
                :document-reg-num="editableDocument.registrationNumber"
              ></component>
            </div>
            <fieldset class="form__fieldset">
              <legend class="form__legend">Файлы</legend>
              <checked-files-field
                v-model="state.documentFiles"
                :files="state.availableFiles"
                :errors="v$.documentFiles.$errors"
              >
                <template #file="{ file }">
                  <territorial-planning-regulation-file
                    v-if="isTerritorialPlanningRegulationDocument"
                    :file="file"
                    :disabled="getFileDisableState(file)"
                    @update:file="updateFile"
                  />
                  <base-file v-else :file="file" />
                </template>
                Выберите файлы, относящиеся к документу
              </checked-files-field>
              <FileUpload
                @startUpload="onStartUpload"
                @endUpload="onEndUpload"
                @failUpload="onFailUpload"
                class="form-control"
              />
            </fieldset>
          </form>
        </div>
      </Step>
      <Step
        :name="pageSteps.step2.tag"
        :is-active="currentStep == pageSteps.step2"
        description="Выбор территории действия"
        :done="pageSteps.step2.done"
      >
        <TabSpinner v-if="isLoading" />
        <div class="box container" v-else>
          <GeometrySource
            v-model:geometrySource="state.geometrySource"
            :stateSnapshot="state.document"
            :files="state.availableFiles"
          />
        </div>
        <SignModal
          :isModalWindowlVisible="isSignModalVisible"
          :dataForSign="dataToSign.byteBuffer"
          @completeSign="editDocumentWithSign"
          @closeModal="closeSignModal"
        >
        </SignModal>
      </Step>
      <Step
        :name="pageSteps.step3.tag"
        v-if="pageSteps.step3"
        :is-active="currentStep == pageSteps.step3"
        description="Выбор муниципальных образований"
        :done="pageSteps.step3.done"
      >
        <TabSpinner v-if="isLoading" />
        <div class="box container" v-else>
          <form class="form form--fullwidth" @submit.prevent="">
            <legend class="title-20">Выбор муниципальных образований</legend>
            <div class="form-control">
              <label class="label"
                >Муниципальные образования, для которых разработан
                документ</label
              >
              <affected-municipalities v-model="state.document">
              </affected-municipalities>
            </div>
          </form>
        </div>
        <SignModal
          :isModalWindowlVisible="isSignModalVisible"
          :dataForSign="dataToSign.byteBuffer"
          @completeSign="editDocumentWithSign"
          @closeModal="closeSignModal"
        >
        </SignModal>
      </Step>
    </Wizard>
  </div>
</template>

<script>
import eventBus from "../../eventBus";
import { useRoute, onBeforeRouteUpdate, useRouter } from "vue-router";
import { ref, computed, reactive, watch } from "vue";
import beforeRouteEnter from "./hooks/beforeRouterEnter";
import DocFactory from "../../models/documents/factory";
import useAuthorization from "../../authorization";
import useEditableDocument from "./hooks/editableDocument";
import useProjectAlias from "@/hooks/projectAlias";
import {
  GeometrySource as GeometrySourceClass,
  GeometrySourceInfoFromDocument,
} from "../../models/geometrySource";
import { helpers } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
// components
import CheckedFilesField from "../basic/form/files/CheckedFilesField";
import BaseFile from "../basic/files/BaseFile";
import TerritorialPlanningRegulationFile from "../basic/files/TerritorialPlanningRegulationFile";
import SaveButton from "../basic/form/SaveButton";
import TextField from "../basic/form/TextField.vue";
import DateField from "../basic/form/DateField.vue";
import Wizard from "../basic/wizard/BaseWizard";
import Step from "../basic/wizard/BaseStep";
import GeometrySource from "../RegistrationProcess/RegistrationDocumentTerritory/GeometrySource.vue";
import AffectedMunicipalities from "../RegistrationProcess/AffectedMunicipalities.vue";
import FileUpload from "../basic/files/fileUpload/FileUpload";
import BackLink from "@/components/basic/BackLink";
// import GisogdDocumentsProxy from "../RegistrationProcess/proxies/gisogdDocumentsProxy";
// import GeometryProxy from "../../proxies/geometryProxy";
// import { getGeometrySourceForDocIzRs } from "../../models/geometrySource";
import SignModal from "../sign/SignModal.vue";
import SignProxy from "../../proxies/signProxy.js";
import TabSpinner from "../basic/tabs/TabSpinner.vue";

export default {
  components: {
    SaveButton,
    CheckedFilesField,
    BaseFile,
    TerritorialPlanningRegulationFile,
    TextField,
    DateField,
    Wizard,
    Step,
    GeometrySource,
    AffectedMunicipalities,
    FileUpload,
    BackLink,
    SignModal,
    TabSpinner,
  },
  setup() {
    const router = useRouter();
    const route = useRoute(); // todo: рут к человеческому виду
    const { currentProject } = useProjectAlias();
    let step1HasChanges = false;
    let step2HasChanges = false;
    let step3HasChanges = false;
    const pageSteps = reactive({
      step1: {
        tag: "1",
        pageTitle: route.meta.isDocChange
          ? "Информация об изменении документа"
          : "Информация о документе",
        pageName: "objectFields",
        back: null,
        next: "step2",
        done: false,
        isValid() {
          step1HasChanges = isDocumentDirty() || isFilesChanged();
          v$.value.$touch();
          if (v$.value.$error) return false;
          v$.value.$reset();
          return true;
        },
      },
      step2: {
        tag: "2",
        pageTitle: "Выбор территории действия",
        pageName: "territory",
        back: "step1",
        next: "step3",
        done: false,
        isValid() {
          step2HasChanges = isGeometryChanged();
          if (!state.geometrySource.geometry)
            return false;
          if (state.geometrySource.geometry.original.geometry.includes("Line")) 
          {
            eventBus.emit("warning", {
              message:
                "Чтобы завершить исправление документа необходимо указать территорию действия в виде полигона",
            });
            return false;
          }
          return true;
        },
      },
      step3: {
        tag: "3",
        pageTitle: "Выбор муниципального образования действия",
        pageName: "affectedMunicipalities",
        back: "step2",
        next: null,
        isValid() {
          step3HasChanges = areAffectedMunicipalitiesChanged();
          return true;
        },
      },
    });

    if (!currentProject.value.isRegion) {
      delete pageSteps.step3;
      pageSteps.step2.next = null;
    }

    const { canEdit } = useAuthorization();
    const haveEditRight = canEdit();
    const docNumber = route.params.number;
    const {
      editableDocument,
      updateEditableDocument,
      getSignData,
    } = useEditableDocument(docNumber);
    const initialState = {};

    const isSignDisabled = ref(true);
    const isSignModalVisible = ref(false);
    const dataToSign = ref({ byteBuffer: "" });
    const isLoading = ref(false);

    SignProxy.GetSettings().then((result) => {
      isSignDisabled.value = result;
    });

    const state = reactive({
      availableFiles: [],
      document: null,
      documentType: null,
      stateSnapshot: null,
      documentFiles: [],
      geometrySource: null,
    });

    watch(
      () => editableDocument.value,
      (newValue) => {
        if (newValue != null) {
          state.document = DocFactory.create(
            newValue.documentType,
            newValue.stateSnapshot
          );
          initialState.document = JSON.parse(JSON.stringify(state.document)); //нужна именно deep-copy
          if (state.availableFiles.length === 0) {
            state.availableFiles = newValue.files;
            state.documentFiles = newValue.files;
            initialState.documentFiles = [...newValue.files];
            initialState.territorialPlanningRegulationFiles = [
              ...newValue.files.filter(
                (x) => x.isTerritorialPlanningRegulation
              ),
            ];
          }
          state.documentType = newValue.documentType;
          state.geometrySource = newValue.geometrySource;
          initialState.geometrySource = JSON.parse(
            JSON.stringify(state.geometrySource)
          );
        }
      }
    );

    const documentTypeName = computed(() => {
      return DocFactory.getDocTypeName(state.documentType);
    });

    let currentStep = ref(pageSteps.step1);

    const rules = {
      documentFiles: {
        required: helpers.withMessage(
          "Для размещения документа в ГИСОГД отметьте файлы, содержащие положение о территориальном планировании",
          (value) => {
            if (isTerritorialPlanningRegulationDocumentIz.value) return true;
            if (!isTerritorialPlanningRegulationDocument.value) return true;
            if (!value) return true;
            return value.some(
              (x) => x !== null && x["isTerritorialPlanningRegulation"] == true
            );
          }
        ),
      },
    };

    const v$ = useVuelidate(rules, state);

    function isDocumentDirty() {
      const newState = JSON.parse(JSON.stringify(state.document));

      for (let ownPropertyName of Object.getOwnPropertyNames(newState)) {
        let currentStateFieldValue = newState[ownPropertyName];
        let initialStateFieldValue = initialState.document[ownPropertyName];

        if (compareFieldValue(initialStateFieldValue, currentStateFieldValue))
          return true;
        else continue;
      }
      return false;

      function compareFieldValue(oldValue, newValue) {
        //отдельная обработка null-значений и пустых объектов
        if (JSON.stringify(newValue) === "{}") newValue = null;

        if (JSON.stringify(oldValue) === "{}") oldValue = null;

        if (newValue == null && oldValue == null) return false;

        //-------------------!!!!!ВАЖНО!!!---------------------------
        //ЭТОТ БЛОК ДОЛЖЕН ИДТИ ДО ПРОВЕРКИ НА ПУСТЫЕ ОБЪЕКТЫ НИЖЕ, ПОТОМУ ЧТО И ARRAY И DATE ЭТО OBJECT
        //если поля дат сравниваем как строки
        if (newValue instanceof Date || oldValue instanceof Date)
          return newValue?.toString() != oldValue?.toString(); //обязательно нестрогое соответствие. null для нас равен пустой строке!

        //а еще поля дат могут быть строками - сравниваем как строки
        if (typeof newValue == "string" || typeof oldValue == "string")
          return newValue != oldValue;

        if (Array.isArray(newValue) || Array.isArray(oldValue)) {
          if (newValue == null || oldValue == null)
            //если проверка выше прошла значит нужно просто проверить что массив могли занулить или сделать не нулевым
            return true;
          if (newValue.length !== oldValue.length)
            //если не совпали по длине - уже считаем что изменилось
            return true;
          else {
            for (let i = 0; i < newValue.length; i++)
              if (compareFieldValue(oldValue[i], newValue[i])) return true;
          }
          return false;
        }
        //---------------------------------------------------------

        if (
          (newValue == null && oldValue != null) ||
          (oldValue == null && newValue != null)
        )
          return true;

        //если поле содержит объект при этом старое или новое значение = пустота, то узнаем об этом путем сравнения по составу полей в объекте
        // - если оно изменилось (при занулении) - это тоже изменение)
        if (newValue instanceof Object || oldValue instanceof Object)
          if (newValue?.externalId || oldValue?.externalId)
            //если объект из GeoMeta просто сравниваем по ID
            return ((newValue?.externalId !== oldValue?.externalId) || (newValue?.name !== oldValue?.name));
          else return JSON.stringify(newValue) != JSON.stringify(oldValue);

        //если простые поля
        if (newValue != oldValue) return true;
        return false;
      }
    }

    function isFilesChanged() {
      let isFilesChanged = false;
      let isFilesTpChanged = false;
      let filesDiff = [];

      //сперва простые проверки на количественный состав файлов
      if (state.documentFiles.length !== initialState.documentFiles.length)
        return true;

      const currentTerritorialPlaningRegulationFiles = state.documentFiles.filter(
        (x) => x.isTerritorialPlanningRegulation
      );
      if (
        currentTerritorialPlaningRegulationFiles.length !==
        initialState.territorialPlanningRegulationFiles.length
      )
        return true;

      state.documentFiles.forEach((file) => {
        filesDiff.push(
          initialState.documentFiles.find((x) => x.fileUrl === file.fileUrl)
        );
      });
      //если нашелся файл которого нет в сходном списке - true
      isFilesChanged = filesDiff.includes(undefined);

      //тоже самое по файлам терпланирования
      filesDiff = [];
      currentTerritorialPlaningRegulationFiles.forEach((file) => {
        filesDiff.push(
          initialState.territorialPlanningRegulationFiles.find(
            (x) => x.fileUrl === file.fileUrl
          )
        );
      });
      isFilesTpChanged = filesDiff.includes(undefined);

      // //если сбросили файлы терпланирования - true
      // if (state.documentFiles.find(x => x.isTerritorialPlanningRegulation === false) !== undefined)
      //   isFilesTpChanged = true;

      if (isFilesChanged || isFilesTpChanged) return true;

      return false;
    }

    function isGeometryChanged() {
      if (state.geometrySource?.geometry == null) return true;
      const newGeometry = JSON.parse(
        JSON.stringify(state.geometrySource.geometry)
      );
      return (
        JSON.stringify(newGeometry) !==
        JSON.stringify(initialState.geometrySource.geometry)
      );
    }

    function areAffectedMunicipalitiesChanged() {
      const newState = JSON.parse(JSON.stringify(state.document));
      const newValue = newState.affectedMunicipalities;
      const oldValue = initialState.document.affectedMunicipalities;

      if (newValue == null && oldValue == null) return false;

      if ((!newValue && oldValue) || (!oldValue && newValue)) return true;

      if (newValue && oldValue && newValue.length !== oldValue.length)
        return true;

      let moDiff = [];
      newValue.forEach((mo) => {
        moDiff.push(oldValue.find((x) => x === mo));
      });

      return moDiff.includes(undefined);
    }

    async function completeUpdatingDocument() {
      if (currentStep.value.isValid()) {
        //если на любом шаге
        if (
          !(
            step1HasChanges ||
            step2HasChanges ||
            (step3HasChanges && currentProject.value.isRegion)
          )
        ) {
          eventBus.emit("warning", {
            message:
              "Чтобы завершить исправление документа необходимо внести хотя бы одно изменение.",
          });
          return;
        }

        isLoading.value = true;

        let newDocumentEdit = { ...editableDocument.value };
        newDocumentEdit.files = state.documentFiles;
        newDocumentEdit.stateSnapshot = state.document;
        newDocumentEdit.geometrySource = state.geometrySource;

        if (!isSignDisabled.value) {
          dataToSign.value = await getSignData(newDocumentEdit);
          isSignModalVisible.value = true;
          return;
        }

        await updateDocument(
          docNumber,
          null,
          newDocumentEdit,
          null,
          null,
          null
        );
      }
    }

    async function editDocumentWithSign(signedData) {
      let newDocumentEdit = { ...editableDocument.value };
      newDocumentEdit.files = state.documentFiles;
      newDocumentEdit.stateSnapshot = state.document;
      newDocumentEdit.geometrySource = state.geometrySource;

      await updateDocument(
        dataToSign.value.registrationNumber,
        dataToSign.value.updateDate,
        newDocumentEdit,
        dataToSign.value.rawJson,
        signedData.signResult,
        signedData.certificateFN
      );
    }

    async function updateDocument(
      registrationNumber,
      updatingDate,
      editableDocument,
      rawJson,
      signResult,
      certificateFN
    ) {
      await updateEditableDocument(
        registrationNumber,
        updatingDate,
        editableDocument,
        rawJson,
        signResult,
        certificateFN
      );
      router.push({
        name: `sectionDocument`,
        params: { number: docNumber },
      });
    }

    function closeSignModal(isSuccess) {
      if (!isSuccess) isLoading.value = false;

      isSignModalVisible.value = false;
    }

    function updateFile(newFile) {
      if (newFile) {
        let index = state.documentFiles.findIndex(
          (x) => x.fileUrl === newFile.fileUrl
        );

        if (index !== -1)
          state.documentFiles[index].isTerritorialPlanningRegulation =
            newFile.isTerritorialPlanningRegulation;
      }
    }

    function moveBackStep() {
      if (currentStep.value.back) {
        v$.value.$reset();
        currentStep.value = pageSteps[currentStep.value.back];
      }
    }

    async function moveNextStep() {
      if (currentStep.value.isValid()) {
        // if (
        //   currentStep.value === pageSteps.step1 &&
        //   state.documentType.id === 1306
        // ) {
        //   const vectors = await GisogdDocumentsProxy.GetVectorsFromDocIzRsOriginRegistration(
        //     state.document.documentsOriginRegistration.map(function(item) {
        //       return item.registrationNumber;
        //     })
        //   );
        //   const geometry = await GeometryProxy.getFromVector(
        //     "LandPlot",
        //     vectors
        //   );
        //   state.geometrySource = getGeometrySourceForDocIzRs(geometry);
        // }

        currentStep.value.done = true;
        currentStep.value = pageSteps[currentStep.value.next];
      } else {
        currentStep.value.done = false;
        if (currentStep.value === pageSteps.step2) {
          eventBus.emit("warning", {
            message:
              "Чтобы перейти к шагу 'Размещение в ГИСОГД', необходимо применить территорию действия к документу.",
          });
        }
      }
    }

    const isTerritorialPlanningRegulationDocument = computed(() => {
      if (!state.documentType || !state.documentType.id) return false;

      return (
        state.documentType.id < 400 ||
        (state.documentType.id > 20100 && state.documentType.id < 20401)
      );
    });

    const isTerritorialPlanningRegulationDocumentIz = computed(() => {
      if (!state.documentType || !state.documentType.id) return false;

      return state.documentType.id > 20100 && state.documentType.id < 20401;
    });

    function updateVector(newVector) {
      if (newVector) {
        state.geometrySource = new GeometrySourceClass(
          new GeometrySourceInfoFromDocument()
        );
      } else {
        state.geometrySource = new GeometrySourceClass();
      }
    }

    onBeforeRouteUpdate(async (to, from) => {
      if (to.params.number !== from.params.number) {
        editableDocument.value = useEditableDocument(docNumber);
      }
    });

    function getFileDisableState(file) {
      return !state.documentFiles.some(function(item) {
        return item.fileUrl === file.fileUrl;
      });
    }

    function onStartUpload(inputFiles) {
      const files = state.availableFiles;
      const newArr = inputFiles.slice();
      state.availableFiles = newArr.concat(files);
    }

    function onEndUpload(file) {
      const files = state.availableFiles;
      const documentFiles = [...state.documentFiles];
      const uploadedFile = files.find((f) => f.uid === file.uid);
      uploadedFile.setLoaded();
      state.availableFiles = files.slice();

      documentFiles.push(ref(file).value);
      state.documentFiles = documentFiles;
    }

    function onFailUpload(file) {
      const files = state.availableFiles;
      const newArr = files.slice();
      const index = newArr.findIndex((f) => f.uid === file.uid);
      if (index > -1) {
        newArr.splice(index, 1);
        state.availableFiles = newArr;
      }
    }

    return {
      documentTypeName,
      haveEditRight,
      isTerritorialPlanningRegulationDocument,
      pageSteps,
      currentStep,
      editableDocument,
      updateDocument,
      moveNextStep,
      moveBackStep,
      getFileDisableState,
      updateFile,
      updateVector,
      docNumber,
      v$,
      state,
      onStartUpload,
      onEndUpload,
      onFailUpload,
      editDocumentWithSign,
      closeSignModal,
      dataToSign,
      isSignModalVisible,
      isLoading,
      completeUpdatingDocument,
    };
  },
  beforeRouteEnter,
};
</script>

<style lang="scss" scoped>
.button:not(:first-child) {
  margin-left: 12px;
}

.button-back:before {
  background-image: url("~@/assets/svg/chevron-left.svg");
}

.button-next::after {
  background-image: url("~@/assets/svg/chevron-right.svg");
}

.button-save::before {
  background-image: url("~@/assets/svg/save.svg");
}

.narrow ::v-deep .wizard__steps {
  width: 55%;
}

.technical-correction-page ::v-deep .expertise--kind--checkbox {
  display: none;
}
</style>
