<template>
  <header class="doc__header doc-header">
    <strong class="doc-header__text title-20">
      Размещенные сведения, документы, материалы
    </strong>
    <button
      class="doc-header__collapse-button doc-header__collapse-button--up"
      :class="{ 'doc-header__collapse-button--down': isCollapsed }"
      @click.prevent="toggleCollapsed"
    ></button>
  </header>
  <div v-if="!isCollapsed">
    <router-link
      v-for="document in documents"
      :key="document.documentNumber"
      :to="{
        name: `sectionDocument`,
        params: { number: document.documentNumber },
      }"
    >
      <a class="document link"> {{ document.documentName }} </a>
    </router-link>
  </div>
</template>

<script>
export default {
  props: {
    documents: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      isCollapsed: false,
    };
  },
  methods: {
    toggleCollapsed() {
      this.isCollapsed = !this.isCollapsed;
    },
  },
};
</script>

<style scoped>
.doc-header {
  display: grid;
  grid-template-columns: 1fr min-content;
  align-items: center;
}

.doc-header--placed {
  grid-template-columns: min-content 1fr min-content min-content;
}

.doc-header__text {
  display: flex;
  align-items: center;
}

.doc-header__text::after {
  display: block;
  content: "";
  flex-grow: 1;
  flex-shrink: 0;
  height: 1px;
  background-color: var(--basic-grey-dark);
  margin-left: 20px;
  margin-right: 20px;
}

.doc-header__collapse-button {
  width: 20px;
  height: 20px;
  border: none;
  outline: none;
  cursor: pointer;
  background-image: url("../../assets/svg/chevron-left.svg");
  background-color: transparent;
}

.doc-header__collapse-button--up {
  transform: rotate(90deg);
}

.doc-header__collapse-button--down {
  transform: rotate(-90deg);
}

.document {
  display: block;
  font-size: 16px;
  line-height: 20px;
  padding-top: 23px;
  padding-bottom: 24px;
}

.document:not(:last-child) {
  border-bottom: 1px solid var(--basic-grey);
}
</style>