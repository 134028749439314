<template>
  <div>
    <form class="form form--fullwidth grid3on2" @submit.prevent="">
      <div>
        <fieldset class="form__fieldset">
          <legend class="form__legend">Основные свойства</legend>
          <UniversalSelectField
            id="geometrySourceType"
            name="geometrySourceType"
            data-qa="territoryType"
            :modelValue="selectedGeometrySourceType"
            @update:modelValue="updateGeometrySourceType($event)"
            :keyProp="'id'"
            :displayProp="'displayName'"
            :options="geometrySourceInfos"
            @filter="fetchWithDelay($event)"
          >
            <label class="label">Вид описания территории действия</label>
          </UniversalSelectField>
          <component
              :is="geometrySourceType"
              :files="localFiles"
              :geometrySourceInfo="geometrySource.geometrySourceInfo"
              :geometry="geometrySource.geometry"
              @update:geometrySource="updateGeometrySource"
              @error="error"
          ></component>
        </fieldset>
      </div>
    </form>
    <br/>
  </div>
</template>

<script>
import { ref, computed, reactive, toRefs } from "vue";
import { GEOMETRY_SOURCE_TYPES } from "@/models/geometrySourceType";
import {
  GeometrySourceInfoFromFile,
  GeometrySourceInfoFromKN,
  GeometrySourceInfoFromOKTMO,
  GeometrySourceInfoFromLocalFile,
  GeometrySource,
  GeometrySourceInfoFromInfoSet,
} from "@/models/geometrySource";

import GeometryFiles from "../RegistrationDocumentTerritory/GeometryFiles";
import GeometryLocalFiles from "../RegistrationDocumentTerritory/GeometryLocalFiles";
import GeometryKN from "../RegistrationDocumentTerritory/GeometryKN";
import GeometryOKTMO from "../RegistrationDocumentTerritory/GeometryOKTMO";
import Map from "../../map/TheMap";
import UniversalSelectField from "@/components/basic/form/select/UniversalSelectField.vue";

export default {
  props: {
    geometrySource: {
      type: Object,
      required: true,
    },
    files: Array,
  },
  components: {
    UniversalSelectField,
    GeometryFiles,
    GeometryLocalFiles,
    GeometryKN,
    GeometryOKTMO,
    Map
  },
  emits: ["update:geometrySource", "error"],
  setup(props, {emit}) {
    const {geometrySource} = toRefs(props);
    const geometrySourceTypes = reactive(GEOMETRY_SOURCE_TYPES);
    const geometryFileExtensions = [
      ".tab",
      ".mid",
      ".mif",
      ".shp",
      ".dat",
      ".id",
      ".map",
      ".cpg",
      ".dbf",
      ".prj",
      ".shx",
    ];

    const geometrySourceInfos = ref([
      geometrySourceTypes.fromFile,
      geometrySourceTypes.kn,
      geometrySourceTypes.oktmo,
      geometrySourceTypes.fromLocalFile,
    ]);

    const geometrySourceType = computed(() => {
      switch (geometrySource.value?.geometrySourceInfo.type) {
        case geometrySourceTypes.null:
          return null;
        case geometrySourceTypes.fromFile:
          return "GeometryFiles";
        case geometrySourceTypes.kn:
          return "GeometryKN";
        case geometrySourceTypes.oktmo:
          return "GeometryOKTMO";
        case geometrySourceTypes.fromLocalFile:
          return "GeometryLocalFiles";
        case geometrySourceTypes.fromInfoSet:
          return null;
        case geometrySourceTypes.fromDocument:
          return null;
        case geometrySourceTypes.fromOriginDocument:
          return null;
        default:
          throw new Error("Неизвестный вид описания территории действия");
      }
    });
    const selectedGeometrySourceType = computed({
      get: () => {
        return geometrySource.value?.geometrySourceInfo.type;
      },
      set: (val) => {
        getGeometrySourceType(val);
      },
    });

    const localFiles = computed(() => {
      return props.files.filter(function (item) {
        var extensionPosition = item.name.lastIndexOf(".");
        var fileNameExtension = item.name.substr(extensionPosition);

        return geometryFileExtensions.includes(fileNameExtension.toLowerCase());
      });
    });

    function updateGeometrySourceType(value) {
      getGeometrySourceType(value);
    }

    function getGeometrySourceType(val) {
      if (!val) return;
      switch (val) {
        case geometrySourceTypes.fromFile:
          return emit(
              "update:geometrySource",
              new GeometrySource(new GeometrySourceInfoFromFile())
          );
        case geometrySourceTypes.kn:
          return emit(
              "update:geometrySource",
              new GeometrySource(new GeometrySourceInfoFromKN())
          );
        case geometrySourceTypes.oktmo:
          return emit(
              "update:geometrySource",
              new GeometrySource(new GeometrySourceInfoFromOKTMO())
          );
        case geometrySourceTypes.fromLocalFile:
          return emit(
              "update:geometrySource",
              new GeometrySource(new GeometrySourceInfoFromLocalFile())
          );
        case geometrySourceTypes.fromInfoSet:
          return emit(
              "update:geometrySource",
              new GeometrySource(GeometrySourceInfoFromInfoSet())
          );
        default:
          throw new Error("Не известный вид описания территории действия");
      }
    }

    function updateGeometrySource(value) {
      emit("update:geometrySource", value);
    }

    function error(message) {
      emit("error", message);
    }

    return {
      geometrySourceType,
      geometrySourceInfos,
      selectedGeometrySourceType,
      localFiles,
      updateGeometrySource,
      error,
      updateGeometrySourceType,
    };
  },
};
</script>

<style scoped lang="scss">
.form {
  position: relative;
  z-index: 50;
}

.map {
  z-index: 40;
}
</style>
