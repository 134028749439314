<template>
  <button
    class="button button-icon button--small"
    title="Удалить черновик"
    @click.stop="showModal"
    v-bind="$attrs"
  >
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.5 5.8335H17.5"
        stroke="#518CFF"
        stroke-width="1.4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.66669 5.83333V2.5H13.3334V5.83333"
        stroke="#518CFF"
        stroke-width="1.4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M4.16669 5.8335V17.5002H15.8334V6.25016"
        stroke="#518CFF"
        stroke-width="1.4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.33331 10V13.3333"
        stroke="#518CFF"
        stroke-width="1.4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11.6667 10V13.3333"
        stroke="#518CFF"
        stroke-width="1.4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </button>
  <modal-window :visible="visibleModal" @close="closeModal">
    <template #title>Удаление черновика</template>
    Хотите удалить черновик?
    <template #footer>
      <div class="group">
        <button
          class="button button--primary group__item"
          @click="onConfirmDeleteClick"
        >
          Удалить
        </button>
        <button class="button button--light group__item" @click="closeModal">
          Отмена
        </button>
      </div>
    </template>
  </modal-window>
</template>
<script>
import { ref } from 'vue';
export default {
  props: {
    registrationRequestNumber: String,
  },
  emits: ['registration-request-deleted'],
  setup(props, { emit }) {
    const visibleModal = ref(false);

    function showModal() {
      visibleModal.value = true;
    }
    function closeModal() {
      visibleModal.value = false;
    }

    function onConfirmDeleteClick() {
      visibleModal.value = false;
      deleteRegistrationRequest();
    }
    async function deleteRegistrationRequest() {
      emit('registration-request-deleted', props.registrationRequestNumber);
      visibleModal.value = false;
    }
    return {
      visibleModal,
      onConfirmDeleteClick,
      showModal,
      closeModal,
    };
  },
};
</script>
<style scoped></style>
