export default class DocIzBase {
  constructor(
    id,
    docStatus,
    docClass,
    docName,
    docNum,
    docDate,
    orgName,
    note,
    description,
    documentsOriginRegistration = [],
    affectedMunicipalities = [],
    guid
  ) {
    this.id = id || 0;
    this.docStatus = docStatus;
    this.docClass = docClass;
    this.docName = docName;
    this.docNum = docNum;
    this.docDate = docDate;
    this.orgName = orgName || {};
    this.note = note;
    this.description = description;
    this.documentsOriginRegistration = documentsOriginRegistration;
    this.affectedMunicipalities = affectedMunicipalities;
    this.guid = guid;
  }
}
