import { ref } from "vue";
import useRegistrationProcess from "./registrationProcess";
import RegistrationProcessProxy from "../proxies/registrationProcessProxy";
import { RegistrationDocument } from "../models/registrationDocument";
import { useRouter } from "vue-router";

export default function useRegistrationProcessItem(inboxDataNumber, itemId) {
  const registrationProcessItem = ref(new RegistrationDocument());
  const avaliableFiles = ref([]);
  const router = ref(useRouter());

  async function fetch(inboxDataNumber, itemId) {
    if (itemId !== undefined) {
      registrationProcessItem.value = await RegistrationProcessProxy.getItem(
        inboxDataNumber,
        itemId
      );
      return registrationProcessItem;
    }
  }

  async function addItem(inboxDataNumber, item) {
    registrationProcessItem.value = await RegistrationProcessProxy.addItem(
      inboxDataNumber,
      item
    );
    return registrationProcessItem;
  }

  async function updateItem(inboxDataNumber, item) {
    await RegistrationProcessProxy.updateItem(inboxDataNumber, item);
  }

  async function registerItem(
    inboxDataNumber,
    item,
    registrationNumber,
    registrationDate,
    docJSON,
    sig,
    sigFN
  ) {
    await RegistrationProcessProxy.registerDocument(
      inboxDataNumber,
      item.id,
      registrationNumber,
      registrationDate,
      docJSON,
      sig,
      sigFN
    )
    .catch(() => {
      router.value.push({
        name: "registrationProcess",
        params: { number: inboxDataNumber.value },
      });
    });
  }

  async function getSignData(item) {
    return await RegistrationProcessProxy.getSignData(item.id);
  }

  const { fetch: fetchRegistrationProcess } = useRegistrationProcess();

  fetchRegistrationProcess(inboxDataNumber.value).then(
    (registrationProcess) => {
      if (itemId && itemId.value) {
        fetch(inboxDataNumber.value, itemId.value)
          .then((registrationProcessItem) => {
            avaliableFiles.value = registrationProcess.value.files.concat(
              registrationProcessItem.value.files
            );
          })
          .catch(() => {
            router.value.push({
              name: "registrationProcess",
              params: { number: inboxDataNumber.value },
            });
          });
      } else {
        avaliableFiles.value = registrationProcess.value.files;
      }
    }
  );

  return {
    registrationProcessItem,
    avaliableFiles,
    addItem,
    updateItem,
    registerItem,
    getSignData,
  };
}
