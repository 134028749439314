export default class SubjectStatus {
    constructor(id) {
        this.id = id;

        switch (id) {
            case 0:
                this.displayName = "Не определен";
                break;
            case 1:
                this.displayName = "Актуальный";
                break;
            case 2:
                this.displayName = "Неактуальный";
                break;
        }
    }

    static fromDto(dto) {
        return new SubjectStatus(dto);
    }
}