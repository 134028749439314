import useSections from './sections';

export default function useSectionRoutes() {
  const { sections } = useSections();
  const docRoutes = sections.map((s) => {
    return {
      path: `document/:number([\\w-]+)`,
      //path: `section-:sectionNumber/:number([\\w-]+)`,
      name: `sectionDocument`,
      title: s.name,
      number: s.number,
      meta: {
        requiresAuth: true,
      },
    };
  });

  const reesterRoutes = sections.map((s) => {
    return {
      //path: `section-${s.number}`,
      path: `section-:sectionNumber`,
      name: `section${s.number}Documents`,
      title: s.name,
      number: s.number,
      meta: {
        requiresAuth: true        
      },
    };
  });

  return {
    docRoutes,
    reesterRoutes,
  };
}
