import { StorageFile } from './storageFile';

export default class RegistrationRequest {
  constructor(id, number, requestDate, outgoingNumber, outgoingDate, nameOfSenderSystem, requestSender = {}, wayOfReceival = {}, dataFormat = {}, files = []) {
    this.id = id;
    this.number = number;
    this.requestDate = requestDate ? requestDate : new Date();
    this.outgoingNumber = outgoingNumber;
    this.outgoingDate = outgoingDate;
    this.nameOfSenderSystem = nameOfSenderSystem;
    this.requestSender = requestSender;
    this.wayOfReceival = wayOfReceival;
    this.dataFormat = dataFormat;
    this.files = files;
  }

  static fromDto(dto) {
    const files = dto.files.map((f) => new StorageFile(f.fileName, f.fileStorageUrl, f.contentType, f.size));
    return new RegistrationRequest(dto.id, dto.number, dto.requestDate == null ? null : new Date(dto.requestDate), dto.outgoingNumber, dto.outgoingDate == null ? null : new Date(dto.outgoingDate), dto.nameOfSenderSystem, dto.requestSender, dto.wayOfReceival, dto.dataFormat, files);
  }
}
