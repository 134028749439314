<template>
  <OnClickOutside :do="onBlur">
    <div class="dropdown form-control" :class="{ 'dropdown--active': isActive }"
         @keydown.down="highlightByIdx(1)"
         @keydown.up="highlightByIdx(-1)"
         @keydown.enter="onPressEnter"
         @keydown.space="onPressSpacebar">
      <label class="label">
        <slot></slot>
      </label>
      <slot name="title"></slot>

      <div class="dropdown__group">
        <input
            class="field text dropdown__input"
            :class="inputClass"
            type="text"
            v-model="localValue"
            @input="onChangeInput"
            @keydown.esc="toggleActive"
            @keydown.enter.prevent=""
            @click="toggleActive"
            :readonly="!canFind"
            spellcheck="false"
        />
        <div>
          <slot name="infoButton"></slot>
        </div>
        <div>
          <slot name="removeButton"></slot>
        </div>
      </div>

      <ul class="dropdown__menu" :class="{ up: isUp || forceDropUp }" ref="optionsElements">
        <li v-if="!isEmptySlots" class="dropdown__item border-down" @mousedown="$emit('firstRowClick')"
            :class="{focused: focused === 0 }">
          <slot name="firstRow"></slot>
        </li>
        <slot name="dropdownMenu" :options="coptions?.map(opt => opt.option)" :focused="focused" :doHide="doHide">
          <li
              @click="onSelectItem(opt.option)"
              class="dropdown__item"
              v-for="(opt, index) in coptions"
              :key="index"
              :class="{ focused: isFirstRowFull? focused === index + 1 : focused === index, checked: opt.checked }"
          >
            <div class="">
              <input type="checkbox" v-if="isMultiSelect" v-model="opt.checked">
              {{ opt.option[displayProp] }}
              <div class="schema__description" v-if="opt.option.schemaName?.length > 0">{{ opt.option.schemaName }}</div>
            </div>
          </li>
        </slot>
      </ul>
      <slot name="validation" v-if="!isMultiSelect">
        <div v-if="validationRule">
          <div v-for="(error, index) of v$.$errors" v-bind:key="index">
            <div class="field-message--error">{{ error.$message }}</div>
          </div>
        </div>
      </slot>
      <div v-else v-for="(error, index) of errors" :key="index">
        <div class="field-message--error">{{ error.$message }}</div>
      </div>
    </div>
  </OnClickOutside>
</template>

<script>
import {computed, nextTick, ref, toRefs, watch, watchEffect} from "vue";
import OnClickOutside from "@/components/basic/OnClickOutside.vue";
import useValidator from "@/components/RegistrationProcess/RegistrationDocumentFields/validator";

export default {
  components: {OnClickOutside},
  props: {
    isMultiSelect: {
      default: false,
      type: Boolean,
    },
    modelValue: {
      required: true,
    },
    displayProp: {
      default: "displayName",
      type: String,
    },
    keyProp: {
      default: "id",
      type: [Number, String],
    },
    errors: {
      type: Array,
      default: () => [],
    },
    validationRule: {
      type: Object,
      default: null
    },
    options: {
      type: Array,
      default: () => [],
    },
    canFind: {
      type: Boolean,
      default: false,
    },
    forceDropUp: {
      type: Boolean,
      default: false,
      required: false
    },
    isFirstRowFull: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  emits: ["update:modelValue", "filter", "firstRowClick"],
  setup(props, {emit, slots}) {
    const isActive = ref(false);
    const localValue = ref("");
    const focused = ref(-1);
    const optionsElements = ref(null);
    const isUp = ref(false);
    const coptions = ref([]);
    let v$ = null;
    const {modelValue: data, validationRule: rules} = toRefs(props);

    if (props.validationRule !== null) {
      v$ = useValidator(data, rules.value);
    }

    watchEffect(() => {
      if (props.isMultiSelect) {
        coptions.value.splice(0);
        for (const opt of props.options) {
          coptions.value.push({
            option: opt,
            checked: !!props.modelValue.find(value => value[props.displayProp].includes(opt[props.displayProp]))
          });
        }
        if (
            props.modelValue !== null &&
            props.modelValue !== undefined &&
            typeof props.modelValue !== "string"
        )
          localValue.value = props.modelValue.map((item) => {
            return item[props.displayProp]
          }).join(', ');

      } else {
        if (props.modelValue !== undefined && props.modelValue !== null) {
          localValue.value = props.modelValue[props.displayProp];
        }

      }
    });

    watch(() => props.options, (newValue) => {
      if (newValue) {
        coptions.value = newValue.map((opt) => ({option: opt}));
      }
    }, {
      immediate: true
    });

    watchEffect(() => {
      if (isActive.value === true) {
        nextTick(() => {
          const elementBottom = optionsElements?.value.parentElement.getBoundingClientRect().bottom;
          isUp.value = window.innerHeight < elementBottom + optionsElements?.value.offsetHeight;
        })
        const elem = optionsElements?.value?.children[focused.value];
        if (optionsElements.value) {
          optionsElements.value.scrollTop = elem?.offsetTop
        }
      }
    });

    watch(() => isActive.value, (newValue) => {
      if (newValue === true) {
        document.addEventListener('keydown', preventKeyBoardScroll);
      } else {
        document.removeEventListener('keydown', preventKeyBoardScroll);
      }
    });

    const preventKeyBoardScroll = (e) => {
      if (e.key === 'ArrowDown' || e.key === 'ArrowUp') {
        e.preventDefault();
        return false;
      }
    }

    const isEmptySlots = computed(() => {
      return slots.firstRow === undefined;
    });

    const inputClass = computed(() => {
      let cls = !props.canFind ? "dropdown__input--pointer" : "";
      cls += hasErrors.value ? " field--error" : "";
      return cls;
    });

    const hasErrors = computed(() => {
      return props.errors.some(() => true);
    });

    function toggleActive() {
      if (typeof props.modelValue === "string") {
        emit("update:modelValue", null);
      }
      isActive.value = !isActive.value;
    }

    function doHide() {
      isActive.value = false;
    }

    function onPressOption() {
      const selectOption = coptions.value.find((opt, index) =>
          props.isFirstRowFull ?
              index + 1 === focused.value
              : index === focused.value)?.option;

      props.isFirstRowFull && focused.value === 0 ?
          emit('firstRowClick') :
          onSelectItem(selectOption)
    }

    function onPressEnter() {
      if (!props.isMultiSelect && focused.value > -1) {
        onPressOption();
      } else if (props.isMultiSelect) {
        onBlur();
      }
    }

    function onPressSpacebar() {
      if (props.isMultiSelect) {
        onPressOption();
      }
    }

    function onSelectItem(option) {
      if (props.isMultiSelect) {
        let selectedValue = coptions.value.find(x => x.option === option);
        selectedValue.checked = !selectedValue.checked;

        let ret = [];
        for (const copt of coptions.value) {
          if (copt.checked)
            ret.push(copt.option);
        }

        localValue.value = ret.map((item) => {
          return item[props.displayProp]
        }).join(', ');

        emit("update:modelValue", ret);
      } else {
        isActive.value = false;
        emit("update:modelValue", option);
        focused.value = -1;
        localValue.value = option[props.displayProp];
      }
    }

    function onBlur() {
      isActive.value = false;
      focused.value = -1;
    }

    function onChangeInput(event) {
      if (props.canFind) {
        if (!event.target.value.startsWith('/') && !event.target.value.startsWith('\\')) {
          emit("filter", event.target.value);
          isActive.value = true;
        } else {
          coptions.value = [];
        }
      }
    }

    function highlightByIdx(delta) {
      const optionsLength = props.isFirstRowFull ? coptions.value.length : coptions.value.length - 1;
      if (focused.value === 0 && delta === -1 || focused.value === -1 && delta === -1) {
        focused.value = optionsLength;
      } else if (focused.value === optionsLength && delta === 1) {
        focused.value = 0;
      } else {
        focused.value += delta;
      }
    }

    return {
      isActive,
      toggleActive,
      onBlur,
      doHide,
      onSelectItem,
      localValue,
      onChangeInput,
      onPressSpacebar,
      onPressEnter,
      highlightByIdx,
      inputClass,
      isEmptySlots,
      v$,
      coptions,
      focused,
      optionsElements,
      isUp
    };
  },
};
</script>
<style scoped>
.border-down::before {
  content: "";
  position: absolute;
  width: 94.5%;
  height: 1px;
  background-color: var(--basic-grey-light);
  top: 42px;
}

.schema__description { 
  color: var(--secondary-blue-darker);
}
</style>
