import { ref } from 'vue';
//import InboxData from '../models/inboxData';
import InboxDataProxy from '../proxies/inboxDataProxy';
import InboxDataDictionaryProxy from '../proxies/inboxDataDictionaryProxy';

export default function useInboxData(inboxdataNumber) {
  const isLoading = ref(true);
  const inboxData = ref(null);
  const rejectionReasonsRefs = ref([]);
  const isRegistered = ref(false);

  if (inboxdataNumber !== undefined)
    //todo как-то избавиться от повторных авто запросов. пока так
    fetch(inboxdataNumber.value);


  fetchRejectionReasons();

  async function fetch(inboxdataNumber) {
    inboxData.value = await InboxDataProxy.fetch(inboxdataNumber);    
    isRegistered.value = inboxData.value.status.id == 2 || inboxData.value.status.id == 3;
    isLoading.value=false;
  }

  async function completeInboxdata(number) {
    await InboxDataProxy.completeInboxdata(number);
    return await fetch(number);
  }

  async function completePartiallyInboxdata(number) {
    await InboxDataProxy.completePartiallyInboxdata(number);
    return await fetch(number);
  }

  async function fetchRejectionReasons() {
    rejectionReasonsRefs.value = await InboxDataDictionaryProxy.getRejectionReasons();
  }

  async function rejectPlacementInboxdata(number, rejectionReason) {
    await InboxDataProxy.rejectPlacementInboxdata(number, rejectionReason);
    return await fetch(number);
  }

  return {
    isRegistered,
    isLoading,
    inboxData,
    rejectionReasonsRefs,
    completeInboxdata,
    completePartiallyInboxdata,
    rejectPlacementInboxdata,
    fetch
  };
}
