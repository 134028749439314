<template>
  <div class="group">
    <save-button class="group__item" @click="save" data-qa="save"></save-button>
    <a class="button button--primary group__item" @click="readyToInboxData" data-qa="readyToPlacement"
      >Готово для размещения</a
    >
  </div>
</template>
<script>
import SaveButton from "../basic/form/SaveButton.vue";
export default {
  emits: ["save", "readyToInboxData"],
  components: { SaveButton },
  methods: {
    save() {
      this.$emit("save");
    },
    readyToInboxData() {
      this.$emit("readyToInboxData");
    },
  },
};
</script>
<style scoped>
</style>