<template>
  <MapLoader class="map" data-qa="map">
    <template #default="{ leaflet, map }">
      <BackgroundLayer
        :leaflet="leaflet"
        :map="map"
        :background-layer-url="$consts.bacgroundLayerUrl"
        :background-layer-attribution="$consts.bacgroundLayerAttribution"
      ></BackgroundLayer>
      <MapFitBound
        :leaflet="leaflet"
        :map="map"
        :geo-json-string="geoJson"
      ></MapFitBound>
      <MapSelection :map="map" :leaflet="leaflet" :geo-json-string="geoJson" />
    </template>
  </MapLoader>
</template>

<script>
import MapLoader from "./MapLoader";
import BackgroundLayer from "./BackgroundLayer";
import MapFitBound from "./MapFitBound";
import MapSelection from "./MapSelection";

export default {
  props: {
    geoJson: {
      type: String,
    },
  },
  components: {
    MapLoader,
    BackgroundLayer,
    MapFitBound,
    MapSelection,
  },
  setup() {
    return {};
  },
};
</script>
<style>
.map {
  height: 400px;
  width: 100%;
  border-radius: 8px;
  margin-top: 8px;
}

.map--error {
  border: 1px solid var(--basic-red);
}
</style>
