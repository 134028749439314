import { StorageFile } from '../../../models/storageFile';

export default class SectionDocument {
  constructor(
    sectionNumber,
    type,
    registrationNumber,
    inboxDataNumber,
    fullname,
    geometry,
    fields,
    files = [],
    territorialPlanningRegulationFiles = [],
  ) {
    this.sectionNumber = sectionNumber;
    this.type = type;
    this.registrationNumber = registrationNumber;
    this.inboxDataNumber = inboxDataNumber;
    this.fields = fields;
    this.fullname = fullname;
    this.geometry = geometry;
    this.files = files;
    this.territorialPlanningRegulationFiles = territorialPlanningRegulationFiles;
  }

  static fromDto(dto, inboxDataNumber) {
    const files = dto.files.map(
      (f) =>
        new StorageFile(f.fileName, f.fileStorageUrl, f.contentType, f.size)
    );
	let territorialPlanningRegulationFiles = null;
	if (dto.territorialPlanningRegulationFiles)
		territorialPlanningRegulationFiles = dto.territorialPlanningRegulationFiles
			.map((f) => new StorageFile(f.fileName, f.fileStorageUrl, f.contentType, f.size));
    return new SectionDocument(
      dto.sectionNumber,
      dto.type,
      dto.registrationNumber,
      inboxDataNumber,
      dto.fullname,
      dto.geometry,
      dto.fields,
      files,
      territorialPlanningRegulationFiles,
    );
  }
}
