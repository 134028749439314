<template>
  <modal-window
    :visible="isModalWindowlVisible"
    @close="closeModal"
    :sign="true"
  >
    <template #title>Выберите сертификат для подписи</template>
    <Certificate
      v-for="certificate in certificates"
      :key="certificate.thumbprint"
      :certificate="certificate"
      @onCertificateClick="chooseCertificate(certificate)"
    ></Certificate>
    <template #footer>
      <div class="group">
        <button
          class="button button--light group__item"
          @click="closeModal(false)"
        >
          Отмена
        </button>
        <button
          class="button button--primary group__item"
          @click="signDocument"
        >
          Подписать
        </button>
      </div>
    </template>
  </modal-window>
</template>

<script>
import { ref, inject, watch } from "vue";
import Certificate from "./Certificate";
import {
  getUserCertificates,
  createDetachedSignature,
  createHash,
} from "crypto-pro";
export default {
  props: {
    isModalWindowlVisible: {
      required: true,
      type: Boolean,
    },
    dataForSign: {
      required: true,
      type: String,
    },
  },
  components: { Certificate },
  emits: ["completeSign", "closeModal"],
  renderTracked() {
    this.$nextTick(() => {
      var element = document.getElementsByClassName("card-item")[0];
      element?.click();
      element?.focus();
    });
  },
  setup(props, { emit }) {
    const certificates = ref([]);
    const certificate = ref(null);
    const toast = inject("toast");

    function chooseCertificate(newValue) {
      certificate.value = newValue;
    }

    function signDocument() {
      closeModal(true);
      createHash(props.dataForSign)
        .then((hash) => {
          createDetachedSignature(certificate.value.thumbprint, hash)
            .then((signResult) => {
              const result = {
                signResult: signResult,
                certificateFN: certificate.value.subjectName,
              };

              emit("completeSign", result);
            })
            .catch((e) => {
              toast.error(e.message);
              closeModal(false);
            });
        })
        .catch((e) => {
          toast.error(e.message);
          closeModal(false);
        });
    }

    watch(
      () => props.isModalWindowlVisible,
      (result) => {
        if (result && certificates.value.length == 0)
          getUserCertificates()
            .then((certs) => {
              var validCerts = [];
              var promises = [];

              certs.forEach((cer) => {
                promises.push(cer.isValid());
              });

              Promise.all(promises).then((validResult) => {
                for (var i = 0; i < validResult.length; i++) {
                  if (validResult[i] === true) validCerts.push(certs[i]);
                }

                certificates.value = validCerts.sort(
                  (a, b) => new Date(b.validTo) - new Date(a.validTo)
                );
                certificate.value = certificates.value[0];
              });
            })
            .catch((e) => {
              if (
                e.message ==
                "Ошибка при инициализации модуля для работы с Cades plugin"
              )
                toast.error(
                  "Не удалось начать процесс подписания документа. Проверьте работу расширения «КриптоПро» в вашем браузере."
                );
              else if (e.message == "Нет доступных сертификатов")
                toast.error(
                  "Не удалось обнаружить сертификат, с использованием которого можно было бы подписать документ. Возможно, Ваш сертификат не установлен"
                );
              else toast.error(e.message);
              closeModal(false);
            });
      }
    );

    function closeModal(isSuccess = false) {
      emit("closeModal", isSuccess);
    }

    return {
      signDocument,
      closeModal,
      certificates,
      chooseCertificate,
    };
  },
};
</script>
