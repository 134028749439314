<script>
import { h, resolveComponent } from "vue";
import RegistrationDocumentCardInProcess from "./RegistrationDocumentCardInProcess";
import RegistrationDocumentCardReady from "./RegistrationDocumentCardReady";
import RegistrationDocumentCardPlaced from "./RegistrationDocumentCardPlaced";
export default {
  props: {
    doc: {
      required: true,
      type: Object,
    },
    inboxdataNumber: {
      type: String
    }
  },
  components: { RegistrationDocumentCardInProcess,RegistrationDocumentCardReady,RegistrationDocumentCardPlaced },
  render() {
    //todo статусы разрешать не по dispalyName
    switch (this.doc.status) {
      case "В процессе": {
        return h(resolveComponent("RegistrationDocumentCardInProcess"), {
          doc: this.doc, inboxdataNumber: this.inboxdataNumber
        });
      }
      case "Размещен": {
        return h(resolveComponent("RegistrationDocumentCardPlaced"), {
          doc: this.doc, inboxdataNumber: this.inboxdataNumber
        });
      }
      case "Готов к размещению": {
        return h(resolveComponent("RegistrationDocumentCardReady"), {
          doc: this.doc, inboxdataNumber: this.inboxdataNumber
        });
      }
      default:
        throw new Error(
          `Unknown '${this.doc.status}' registration document status`
        );
    }
  },
};
</script>
