export default class DictionaryValue {
  constructor(id, displayName) {
    this.id = id;
    this.displayName = displayName;
  }

  static fromDtos(dtos) {
    return dtos.map((dto) => new DictionaryValue(dto.id, dto.displayName));
  }
  static fromDto(dto) {
    return new DictionaryValue(dto.id, dto.displayName);
  }
}
