import instance from '../api.js';
import { SubjectResult } from '../models/subject';
import Person from '../models/person';
import { OrganizationDto } from '../dtos/organizationDto';
import { PersonDto } from '../dtos/personDto';
import Organization from '../models/organization.js';
import { EntrepreneurDto } from '../dtos/entrepreneurDto';
import Entrepreneur from '../models/enterprenuer.js';
import { NewPersonWithActivePersonsDto } from '../dtos/newPersonWithActivePersonsDto.js';
import { NewOrganizationWithActiveOrganizationsDto } from '../dtos/newOrganizationWithActiveOrganizationsDto.js';
import DictionaryValue from '../models/dictionaryValue'

class SubjectProxy {
  static async fetchPerson(id) {
    return instance.get(`subject/person/${id}`).then((response) => {
      return Person.fromDto(response);
    });
  }

  static async fetchOrganization(id) {
    return instance.get(`subject/organization/${id}`).then((response) => {
      return Organization.fromDto(response);
    });
  }

  static async fetchPersons(filter) {
    if (filter === undefined)
      return instance.get(`subject/persons`).then((response) => {
        return Person.fromDtos(response);
      });
    else
      return instance.get(`subject/persons/${filter}`).then((response) => {
        return Person.fromDtos(response);
      });
  }

  static async fetchOrganizations(filter) {
    if (filter === undefined)
      return instance.get(`subject/organizations`).then((response) => {
        return Organization.fromDtos(response);
      });
    else
      return instance.get(`subject/organizations/${filter}`).then((response) => {
        return Organization.fromDtos(response);
      });
  }

  static async fetchEntrepreneur(id) {
    return instance.get(`subject/enterpreneur/${id}`).then((response) => {
      return Entrepreneur.fromDto(response);
    });
  }

  static async fetchEntrepreneurs(filter) {
    if (filter === undefined)
    return instance.get(`subject/enterpreneurs`).then((response) => {
      return Entrepreneur.fromDtos(response);
    });
    else
      return instance.get(`subject/enterpreneurs/${filter}`).then((response) => {
        return Entrepreneur.fromDtos(response);
      });
  }

  static async createPerson(person) {
    const dto = new PersonDto(person.firstName, person.lastName, person.middleName, person.phone, person.email, person.ogrnip, person.passportSeries, person.passportNumber, person.snils, person.inn, person.subjectStatus.id);
    return instance.put(`subject/person`, dto).then((response) => {
      return SubjectResult.fromDto(response);
    });
  }

  static async createOrganization(organization) {
    const dto = new OrganizationDto(organization.name, organization.organizationalForm, organization.inn, organization.ogrn, organization.phone, organization.email, organization.subjectStatus.id);
    return instance.put('subject/organization', dto).then((response) => {
      return SubjectResult.fromDto(response);
    });
  }

  static async createEntrepreneur(enterpreneur) {
    const dto = new EntrepreneurDto(enterpreneur.firstName, enterpreneur.lastName, enterpreneur.middleName, enterpreneur.phone, enterpreneur.email, enterpreneur.ogrnip, enterpreneur.passportSeries, enterpreneur.passportNumber, 
      enterpreneur.snils, enterpreneur.inn, enterpreneur.externalId, enterpreneur.displayName, enterpreneur.type, enterpreneur.subjectStatus.id, enterpreneur.guid, enterpreneur.enterpreneurShortName, enterpreneur.enterpreneurFullName);
    return instance.put(`subject/enterpreneur`, dto).then((response) => {
      return SubjectResult.fromDto(response);
    });
  }

  static async makePersonsInactive(activePersonsExternalId) {
    const dto = new NewPersonWithActivePersonsDto(activePersonsExternalId);
    return instance.put('subject/persons/inactive', dto);
  }

  static async makeOrganizationsInactive(activeOrganizationsExternalId) {
    const dto = new NewOrganizationWithActiveOrganizationsDto(activeOrganizationsExternalId);
    return instance.put('subject/organizations/inactive', dto);
  }

  static async getSubjectStatus() {
    const dto = await instance.get(`subject/statuses`);
    return DictionaryValue.fromDtos(dto);
  }
}

export default SubjectProxy;
