import Toast from './toast';

Toast.install = (app, options) => {
  const toast = new Toast(options);
  app.config.globalProperties.$toast = toast;
  app.provide('toast', toast);
  app.component('toast', Toast);

  app.config.globalProperties.eventBus.on('error', (e) =>
    toast.error(e.message)
  );
  app.config.globalProperties.eventBus.on('warning', (e) =>
    toast.warning(e.message)
  );
};
export default Toast;
