<template>
  <div class="form-control-vector">
    <ArrayField
      v-slot="{ add, remove }"
      :modelValue="modelValue"
      @update:modelValue="updateArray($event, i)"
    >
      <label class="label"><slot></slot></label>
      <div style="margin-top: -20px" v-for="(value, i) in modelValue" :key="i">
        <UniversalSelectField
            @update:modelValue="updateArray($event, i)"
            :modelValue="value"
            :displayProp="'name'"
            @filter="updateFilterWithDelay($event)"
            :hasError="hasError"
            :validationRule = itemValidationRule
            :options="filteredOptions"
            :can-find="true"
        >
          <template #infoButton>
            <button
              @mouseup="
                remove(i);
                updateFilterWithDelay();
              "
              class="button button-icon button--small"
            >
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2.3999 13.6L13.5999 2.39999M13.5999 13.6L2.3999 2.39999"
                  stroke="#518CFF"
                  stroke-width="1.4"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </button>
          </template>
          <!-- <template #validation>
            <ValidationMsg :errors="v$.$errors"></ValidationMsg>
          </template> -->
        </UniversalSelectField>
      </div>
      <div class="form-control-vector__add-element">
        <a
          class="link link--icon link--plus"
          @click="
            add();
            updateFilterWithDelay();
          "
        >
          <slot name="add">Добавить</slot>
        </a>
        <ValidationMsg :errors="errors"></ValidationMsg>
      </div>
    </ArrayField>
  </div>
</template>

<script>
import ArrayField from "./renderless/ArrayField.vue";
import ValidationMsg from "./form/select/ValidationMsg";
import { computed } from "vue";
import UniversalSelectField from "@/components/basic/form/select/UniversalSelectField.vue";
export default {
  props: {
    modelValue: {
      type: Array,
      default: () => [{}],
    },
    options: {
      type: Array,
      default: () => [],
    },
    errors: {
      type: Array,
      default: () => [],
    },
    keyProp: {
      type: String,
      default: ''
    },
    itemValidationRule: {
      type: Object,
      default: null
    }
  },
  components: {
    UniversalSelectField,
    ArrayField,
    ValidationMsg,
  },
  emits: ["reload", "update:modelValue"],
  setup(props, { emit }) {
    function updateFilterWithDelay(value) {
      emit("reload", value);
    }

    const hasError = computed(() => {
      return props.errors.length > 0;
    });

    const filteredOptions = computed(() => {
      return props.options.filter(
        (opt) =>
          !props.modelValue.some((el) => el[props.keyProp] === opt[props.keyProp])
      );
    });

    function updateArray(value, position) {
      const newValue = [...props.modelValue];
      newValue[position] = value;
      emit("update:modelValue", newValue);
    }

    return {
      updateArray,
      updateFilterWithDelay,
      hasError,
      filteredOptions,
    };
  },
};
</script>

<style scoped>
.form-control-vector {
  margin-top: 24px;
  width: 100%;
}

.form-control-vector__add-element {
  margin-top: 7px;
}
</style>
