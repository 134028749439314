<template>
  <div>
    <main-page />
  </div>
</template>

<script>
import MainPage from "./components/MainPage";

export default {
  components: { MainPage },
};
</script>

<style lang="scss">
@import "./assets/sass/main.scss";
</style>
