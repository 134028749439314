<template>
  <div :class="cls">
    <div class="sidebar__header">
      <button
        class="sidebar__button"
        @click="onChangeState"
        aria-label="Свернуть панель"
        data-qa="hidePannel"
      ></button>
      <TheLogo @click="goHome" class="sidebar__logo" data-qa="logo"></TheLogo>
    </div>
    <div class="sidebar__body">
      <ul class="sidebar__list">
        <router-link
          custom
          v-slot="{ href, isActive, navigate }"
          :to="{ name: 'reesters', params: { projectAlias: projectAlias } }"
        >
          <li role="link" @click="navigate" @keypress.enter="navigate">
            <a
              class="sidebar__item clickable__item sidebar__item--icon sidebar__item--accounting-information"
              :href="href"
              :class="{ 'sidebar__item--active': isActive|| linkSectionNumber===0 }"
              data-qa="informationRegister"
            >
              <span class="sidebar__section-name">Реестр учета сведений</span>
            </a>
          </li>
        </router-link>
        <li>
          <a
            class="sidebar__item sidebar__item--icon sidebar__item--sections-ISOGD"
          >
            <span class="sidebar__section-name"
              >Реестр тематических наборов данных</span
            >
          </a>
        </li>
        <router-link
          v-for="sectionRoute in sectionRoutes"
          :key="sectionRoute.number"
          custom
          v-slot="{ href, isActive, navigate }"
          :to="{
            name: sectionRoute.name,
            params: {
              projectAlias: projectAlias,
              sectionNumber: sectionRoute.number,
            },
          }"
        >
          <li @click="navigate" @keypress.enter="navigate">
            <a
              class="sidebar__item clickable__item sidebar__item--number"
              :href="href"
              :class="{ 'sidebar__item--active': isActive || linkSectionNumber===sectionRoute.number}"
              :data-qa="'section-' + sectionRoute.number"
            >
              {{ sectionRoute.number
              }}<span class="sidebar__section-name"
                >. {{ sectionRoute.title }}</span
              >
            </a>
          </li>
        </router-link>
      </ul>
    </div>
  </div>
</template>

<script>
import TheLogo from "./TheLogo.vue";
import { ref, computed, watch} from "vue";
import { useRoute, useRouter } from "vue-router";
import useProjectAlias from "../hooks/projectAlias";
import useSectionRoutes from "../hooks/sectionRoutes";

export default {
  components: {
    TheLogo,
  },
  setup(_, { emit }) {
    const isExpanded = ref(true);
    const sectionNumberFromLink = ref(null);
    const { projectAlias } = useProjectAlias();
    const cls = computed(() => {
      return isExpanded.value
        ? "sidebar sidebar--expanded"
        : "sidebar sidebar--collapsed";
    });

    function onChangeState() {
      isExpanded.value = !isExpanded.value;
      emit("change", isExpanded.value);
    }
    const router = useRouter();
    const route = useRoute();

    function goHome() {
      router.push({
        name: "home",
      });
    }

    const { reesterRoutes: sectionRoutes } = useSectionRoutes();

    watch(() => route.params, (newValue) => {
      if(newValue.sectionNumber) {
        sectionNumberFromLink.value = Number(newValue.sectionNumber);
      }
      else if(!newValue.sectionNumber && !newValue.number) {
        sectionNumberFromLink.value = 0;
      }
      else {
        sectionNumberFromLink.value = getSectionFromDocumentNumber(newValue.number);
      }
    });


    function getSectionFromDocumentNumber(number){
      if(number.length > 4) {
        return Number(number.split('-')[1]);
      }
      return 0;
    }

    return {
      sectionRoutes,
      linkSectionNumber: sectionNumberFromLink,
      projectAlias,
      onChangeState,
      cls,
      route,
      goHome
    };
  },
};
</script>

<style scoped>
.sidebar {
  position: relative;
  display: grid;
  grid-template-rows: min-content 1fr min-content;
  height: 100%;
  background-color: var(--primary-blue-darker);
  transition: 0.2s;
}
.sidebar__logo {
  cursor: pointer;
}
.sidebar--collapsed {
  width: 92px;
}

.sidebar--expanded {
  max-width: 360px;
  width: 30vw;
}

/*sidebar__header*/

.sidebar__header {
  position: relative;
  min-height: 28px;
  padding: 24px 32px;
  border-bottom: 1px solid var(--basic-white-transparent-12);
}

.sidebar__button {
  position: absolute;
  width: 28px;
  height: 28px;
  border: none;
  border-radius: 6px;
  outline: none;
  cursor: pointer;
  z-index: 10;
}

.sidebar__button::before {
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-position: 0 0;
  left: 4px;
  top: 4px;
}

.sidebar--expanded .sidebar__button {
  background-color: var(--primary-blue-darker);
  right: 24px;
}

.sidebar--collapsed .sidebar__button {
  background-color: var(--primary-blue-lighter);
  right: -15px;
}

.sidebar--collapsed .sidebar__button::before {
  background-image: url("../assets/svg/sidebar-open.svg");
}

.sidebar--expanded .sidebar__button::before {
  background-image: url("../assets/svg/sidebar-close.svg");
}

.sidebar--expanded .sidebar__button:hover {
  background-color: var(--basic-white-transparent-8);
}

.sidebar--expanded .sidebar__button:focus,
.sidebar--expanded .sidebar__button:active {
  background-color: var(--primary-blue-lighter);
}

.sidebar--collapsed .sidebar__button:hover {
  background-color: var(--primary-blue-light);
}

.sidebar__body {
  padding-top: 11px;
  padding-bottom: 16px;
  overflow-y: auto;
}

.sidebar__list {
  margin: 0;
  padding: 0;
}

.sidebar__item {
  display: block;
  color: var(--basic-white);
  padding: 16px 32px 16px 60px;
  list-style: none;
  position: relative;
  box-sizing: border-box;
  min-height: 49px;
  cursor: default;
}

.sidebar--collapsed .sidebar__item--number {
  padding-left: 42px;
}

.sidebar--collapsed .sidebar__item--icon::before {
  left: calc(50% - 10px);
}

.sidebar__item--icon::before {
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-position: 0 0;
  top: 15px;
  left: 52px;
}

.clickable__item:hover {
  background-color: var(--basic-white-transparent-8);
  cursor: pointer;
}

.clickable__item:focus,
.clickable__item:active,
.sidebar__item--active,
.clickable__item:hover.sidebar__item--active {
  background-color: var(--primary-blue-lighter);
}

.sidebar__item--accounting-information::before {
  background-image: url("../assets/svg/accounting.svg");
  margin-left: -20px;
}

.sidebar__item--providing-information::before {
  background-image: url("../assets/svg/newspaper.svg");
  margin-left: -20px;
}

.sidebar__item--sections-ISOGD::before {
  background-image: url("../assets/svg/menu-left.svg");
  margin-left: -20px;
}

.sidebar--collapsed .sidebar__section-name {
  display: none;
}

.sidebar--collapsed .sidebar__item {
  text-align: center;
  margin-left: -10px;
}

.sidebar--collapsed .sidebar__item--sections-ISOGD::before {
  margin-left: 5px;
}

.sidebar--collapsed .sidebar__item--accounting-information::before {
  margin-left: 5px;
}

</style>
