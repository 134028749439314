import {StorageFile} from "./storageFile";
import {TerritorialPlanningRegulationStorageFile} from "./territorialPlanningRegulationStorageFile";
import DictionaryValue from "./dictionaryValue";
import {geometrySourceFactory, GeometrySource} from "./geometrySource";
import Factory from './documents/factory';

export class RegistrationDocument {
	constructor(id, name, documentType, files = [], status, stateSnapshot, geometrySource = new GeometrySource(), gisogdRegistrationInfo) {
		this.id = id;
		this._name = name;
		this.documentType = documentType;
		this.files = files;
		this.status = status;
		this.stateSnapshot = stateSnapshot;
		this.geometrySource = geometrySource;
		this.gisogdRegistrationInfo = gisogdRegistrationInfo;
	}

	get name() {
		return this._name
			? this._name
			: (this.documentType && this.documentType.displayName
				? this.documentType.displayName
				: "Новый документ")
	}

	static fromDto(dto) {
		const documentType = DictionaryValue.fromDto(dto.documentType);
		const files = dto.files.map(
			(f) => new StorageFile(f.fileName, f.fileStorageUrl, f.contentType, f.size)
		);
		const territorialPlanningRegulationFiles = dto.territorialPlanningRegulationFiles.map(
			(f) => new TerritorialPlanningRegulationStorageFile(f.fileName, f.fileStorageUrl, f.contentType, f.size)
		);
		// const territorialPlanningRegulationFiles = dto.territorialPlanningRegulationFiles.map(
		// 	(f) => new StorageFile(f.fileName, f.fileStorageUrl, f.contentType, f.size)
		// );
		const stateSnapshot = JSON.parse(dto.stateSnapshot);
		const geometrySource = geometrySourceFactory(dto.geometrySource);
		return new RegistrationDocument(
			dto.id,
			stateSnapshot.docName,
			documentType,
			files.concat(territorialPlanningRegulationFiles),
			dto.status,
			Factory.create(documentType, stateSnapshot),
			geometrySource,
			dto.gisogdRegistrationInfo
		);
	}
}
