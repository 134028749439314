import DocVectorZu from './docVectorZu';
export default class DocRS extends DocVectorZu {
  constructor(id, docStatus, docClass, docName, docNum, docDate, orgName, canceledDocuments=[], note, zu, docViewConstruction, nameFromPD, developers = [], untilDate, oks = [], affectedMunicipalities = [], guid) {
    super(id, docStatus, docClass, docName, docNum, docDate, orgName, canceledDocuments, note, zu, affectedMunicipalities, guid);
    this.docViewConstruction = docViewConstruction;
    this.nameFromPD = nameFromPD;
    this.developers = developers;
    this.untilDate = untilDate;
    this.oks = oks;
  }
}
