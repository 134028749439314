<template>
  <div>
    <GeometrySourceAuto
      v-if="isFromObjectForTerritory"
      :geometrySource="geometrySource"
      @update:geometrySource="updateGeometrySource($event)"
      @error="showError"
      :stateSnapshot="stateSnapshot"
    >
    </GeometrySourceAuto>
    <GeometrySourceManual
      v-else
      :geometrySource="geometrySource"
      @update:geometrySource="updateGeometrySource($event)"
      @error="showError"
      :files="files"
    >
    </GeometrySourceManual>
    <span
      v-if="isMapFailed"
      class="field-message--error field-message--icon-error"
    >
      {{ error }}
    </span>
    <Map
      :key="Date.now()"
      v-if="isMapNeed2Show"
      class="map"
      :class="mapStyle"
      :geo-json="geoJson"
    ></Map>
  </div>
</template>

<script>
import { computed, ref } from "vue";

import GeometrySourceManual from "./GeometrySourceManual";
import GeometrySourceAuto from "./GeometrySourceAuto";
import Map from "../../map/TheMap";

export default {
  props: {
    geometrySource: {
      type: Object,
      required: true,
    },
    stateSnapshot: {
      type: Object,
      required: true,
    },
    files: Array,
  },
  components: {
    GeometrySourceManual,
    GeometrySourceAuto,
    Map,
  },
  setup(props, { emit }) {
    const error = ref(null);
    /* const isMapFailed = computed(() => {
      if (!props.geometrySource) return false;
      return props.geometrySource.geometrySourceInfo.hasErrors || error.value;
    }); */

    const isMapFailed = computed(() => {
      return error.value !== null;
    });

    const isMapNeed2Show = computed(() => {
      return geoJson.value || isMapFailed.value;
    });

    const geoJson = computed(() => {
      if (props.geometrySource && props.geometrySource.geometry)
        return props.geometrySource.geometry.wgs84.geometry;
      return null;
    });

    const isFromObjectForTerritory = computed(() => {
      if (
        props.stateSnapshot.zu ||
        props.stateSnapshot.settlement ||
        props.stateSnapshot.artZuRef ||
        props.stateSnapshot.zouitRef ||
        props.stateSnapshot.oopts ||
        props.stateSnapshot.forestryRef ||
        props.stateSnapshot.forestLandPlotRef || 
        props.geometrySource.geometrySourceInfo.type.id == 7
      )
        return true;

      return false;
    });

    const mapStyle = computed(() => {
      return isMapFailed.value ? "map--error" : "";
    });

    function updateGeometrySource(value) {
      emit("update:geometrySource", value);
      error.value = null;
    }

    function showError(message) {
      error.value = message;
    }

    return {
      isFromObjectForTerritory,
      updateGeometrySource,
      showError,
      error,
      isMapFailed,
      isMapNeed2Show,
      mapStyle,
      geoJson
    };
  },
};
</script>

<style scoped lang="scss">
.form {
  position: relative;
  z-index: 50;
}

.map {
  z-index: 40;
}
</style>
