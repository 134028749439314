<template>
  <div class="table-container">
    <table class="register-table" data-qa="table">
      <tr class="register-table__header">
        <th
            v-for="(column, idx) in columns"
            :key="idx"
            class="register-table__header-text"
        >
          {{ column }}
        </th>
      </tr>
      <tr
          v-for="item in data"
          :key="item.id"
          @click="onRowClick(item)"
          class="register-table__tr"
          :class="getStatusStyle(item.processingStatusID)"
      >
        <td>
          <a v-html="highlight(query, item.number)"> </a>
        </td>
        <td
            v-html="highlight(query, $filters.toDateString(item.requestDate))"
        ></td>
        <td
            v-html="highlight(query, item.outgoingNumberCoverLetter)"
        ></td>
        <td
            v-html="highlight(query, $filters.toDateString(item.outgoingDateCoverLetter))"
        ></td>
        <td v-html="highlight(query, item.nameOfSenderSystem)"></td>
        <td v-html="highlight(query, item.subject)"></td>
        <td v-html="highlight(query, item.wayOfReceival)"></td>
        <td v-html="highlight(query, item.dataFormat)"></td>
        <td class="register-table__status">
          <Status
              :status="item.status"
              :process-status="item.processingStatusID"
              :query="query"
          ></Status>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import {useRouter} from "vue-router";
import Status from "../InboxData/statuses/InboxDataStatus";
import useTextHighlight from "../../hooks/textHighlight";

export default {
  props: {
    data: Array,
    query: String,
  },
  components: {Status},
  setup() {
    const columns = [
      "Номер записи",
      "Дата поступления",
      "Номер сопроводительного письма",
      "Дата сопроводительного письма",      
      "Наименование ИС, из которой передаются данные",
      "Лицо, направившее сведения",
      "Способ направления данных",
      "Форма данных",
      "Статус",
    ];
    const router = useRouter();

    function onRowClick(item) {
      router.push({
        name: "inboxData",
        params: {
          number: item.number,
        },
      });
    }

    function getStatusStyle(processStatus) {
      if (processStatus === 2) return "register-table__tr--status-yellow";
      if (processStatus === 3) return "register-table__tr--status-green";
    }

    const {highlight} = useTextHighlight();
    return {columns, onRowClick, getStatusStyle, highlight};
  },
};
</script>

<style scoped>
.register-table {
  border-collapse: collapse;
  width: 100%;
}

.register-table__header-text {
  background-color: var(--basic-grey-light);
  text-align: left;
  padding: 16px 30px;
  font-weight: normal;
  font-size: 16px;
  color: var(--basic-black);
}

.register-table__header-text:first-child,
.register-table__tr td:first-child {
  padding-left: 62px;
}

.register-table__tr td:first-child {
  position: relative;
}

.register-table__tr td:first-child::before {
  content: "";
  position: absolute;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: var(--secondary-blue-darker);
  left: 30px;
  top: calc(50% - 4px);
}

.register-table__tr:hover {
  background-color: var(--secondary-blue-lighter);
  cursor: pointer;
}

.register-table__tr td {
  border-bottom: 1px solid var(--basic-grey);
  padding: 18px 30px 19px;
  /* Для ограничения ширины колонки.  todo: не лучшее решение */
  max-width: 300px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.register-table__status {
  color: var(--secondary-blue-darker);
}

.register-table__tr--status-yellow td:first-child::before {
  background-color: var(--basic-yellow);
}

.register-table__tr--status-green td:first-child::before {
  background-color: var(--basic-green);
}
</style>