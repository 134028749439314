import { GEOMETRY_SOURCE_TYPES } from "./geometrySourceType";
import { StorageFile } from "./storageFile";
export class GeometrySource {
  constructor(geometrySourceInfo, geometry = "") {
    this.geometrySourceInfo =
      geometrySourceInfo || new NullGeometrySourceInfo();
    this.geometry = geometry;
  }

  static fromDto() {
    return new GeometrySource();
  }
}

export class GeometrySourceInfo {
  constructor(type, hasErrors = false) {
    this.type = type;
    this.hasErrors = hasErrors;
  }
  get displayName() {
    return this.type.displayName;
  }
}

export class NullGeometrySourceInfo extends GeometrySourceInfo {
  constructor() {
    super(GEOMETRY_SOURCE_TYPES.null);
  }
  static fromDto() {
    return new NullGeometrySourceInfo();
  }
}

export class GeometrySourceInfoFromFile extends GeometrySourceInfo {
  constructor(files = []) {
    super(GEOMETRY_SOURCE_TYPES.fromFile);
    this.files = files;
  }

  static fromDto(dto) {
    const files = dto.files
      ? dto.files.map(
          (f) => new StorageFile(f.name, f.fileUrl, f.contentType, f.size)
        )
      : [];
    return new GeometrySourceInfoFromFile(files);
  }
}

export class GeometrySourceInfoFromKN extends GeometrySourceInfo {
  constructor(kn, hasErrors = false) {
    super(GEOMETRY_SOURCE_TYPES.kn, hasErrors);
    this.kn = kn;
  }
  static fromDto(dto) {
    return new GeometrySourceInfoFromKN(dto.kn);
  }
}

export class GeometrySourceInfoFromDocument extends GeometrySourceInfo {
  constructor(hasErrors = false) {
    super(GEOMETRY_SOURCE_TYPES.fromDocument, hasErrors);
  }
}

export class GeometrySourceInfoFromInfoSet extends GeometrySourceInfo {
  constructor(hasErrors = false) {
    super(GEOMETRY_SOURCE_TYPES.fromInfoSet, hasErrors);
  }
}

export class GeometrySourceInfoFromOKTMO extends GeometrySourceInfo {
  constructor(oktmo, hasErrors = false) {
    super(GEOMETRY_SOURCE_TYPES.oktmo, hasErrors);
    this.oktmo = oktmo;
  }
  static fromDto(dto) {
    return new GeometrySourceInfoFromOKTMO(dto.oktmo);
  }
}

export class GeometrySourceInfoFromLocalFile extends GeometrySourceInfo {
  constructor(files = []) {
    super(GEOMETRY_SOURCE_TYPES.fromLocalFile);
    this.files = files;
  }
  static fromDto(dto) {
    return new GeometrySourceInfoFromLocalFile(dto.files);
  }
}

export class GeometrySourceInfoFromOriginDocument extends GeometrySourceInfo {
    constructor(hasErrors = false) {
    super(GEOMETRY_SOURCE_TYPES.fromOriginDocument, hasErrors);
  }
}

function proxyGeometry(geometry) {
  return new Proxy(geometry, {
    get(target, prop) {
      if (
        (prop === "original" || prop === "wgs84") &&
        typeof target[prop] === "string"
      ) {
        const srid = prop === "original" ? 0 : 4326; //EPSG:4326 (wgs84) default value
        return { geometry: target[prop], srid };
      } else return target[prop];
    },
  });
}

export const geometrySourceFactory = function(geometrySourceDto) {
  if (!geometrySourceDto || !JSON.parse(geometrySourceDto.geometrySourceInfo))
    return new GeometrySource();

  const geometrySourceInfoDto = JSON.parse(
    geometrySourceDto.geometrySourceInfo
  );

  const geometry =
    geometrySourceDto.geometry !== null
      ? proxyGeometry(JSON.parse(geometrySourceDto.geometry))
      : geometrySourceDto.geometry;
  switch (geometrySourceInfoDto.type.id) {
    case GEOMETRY_SOURCE_TYPES.null.id:
      return new GeometrySource();
    case GEOMETRY_SOURCE_TYPES.fromFile.id:
      return new GeometrySource(
        GeometrySourceInfoFromFile.fromDto(geometrySourceInfoDto),
        geometry
      );
    case GEOMETRY_SOURCE_TYPES.kn.id:
      return new GeometrySource(
        GeometrySourceInfoFromKN.fromDto(geometrySourceInfoDto),
        geometry
      );
    case GEOMETRY_SOURCE_TYPES.oktmo.id:
      return new GeometrySource(
        GeometrySourceInfoFromOKTMO.fromDto(geometrySourceInfoDto),
        geometry
      );
    case GEOMETRY_SOURCE_TYPES.fromLocalFile.id:
      return new GeometrySource(
        GeometrySourceInfoFromLocalFile.fromDto(geometrySourceInfoDto),
        geometry
      );
    case GEOMETRY_SOURCE_TYPES.fromDocument.id:
      return new GeometrySource(new GeometrySourceInfoFromDocument(), geometry);
    case GEOMETRY_SOURCE_TYPES.fromInfoSet.id:
      return new GeometrySource(new GeometrySourceInfoFromInfoSet(), geometry);
    case GEOMETRY_SOURCE_TYPES.fromOriginDocument.id:
      return new GeometrySource(new GeometrySourceInfoFromOriginDocument(), geometry);
    default:
      throw new Error("Неизвестный вид описания территории действия");
  }
};

export const getGeometrySourceForDocIzRs = function(geometry) {
  return new GeometrySource(
    new GeometrySourceInfoFromOriginDocument(),
    geometry
  );
};
