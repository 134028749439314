<template>
  <ul class="file-list" v-if="files.length > 0">
    <li
      class="file-list__item"
      :class="{
        'file-list__item--checkable': checkable,
        'file-list__item--removable': removable,
      }"
      v-for="(file, idx) in files"
      :key="idx"
    >
      <input
        v-if="checkable"
        type="checkbox"
        :value="file"
        v-model="selectedFiles"
        @change="onChange"
      />

      <slot name="file" :file="file">
      </slot>

      <remove-file-button
        v-if="removable"
        class="file-list__remove-button"
        @click="removeFile(file)"
      ></remove-file-button>
    </li>
  </ul>
</template>
<script>
import RemoveFileButton from '../../files/RemoveFileButton';
export default {
  emits: ['update:modelValue', 'file-remove'],
  props: {
    checkable: {
      type: Boolean,
      default: false,
    },
    removable: {
      type: Boolean,
      default: false,
    },
    files: {
      type: Array,
      required: true,
      default: () => [],
    },
    modelValue: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  components: { RemoveFileButton },
  data() {
    return {
      selectedFiles: this.modelValue ? this.modelValue.slice() : [],
    };
  },
  methods: {
    onChange() {
      this.$emit('update:modelValue', this.selectedFiles);
    },
    removeFile(file) {
      const index = this.selectedFiles.indexOf(file);
      const newArr = this.selectedFiles.slice();
      newArr.splice(index, 1);
      this.$emit('update:modelValue', newArr);
      this.$emit('file-remove', file);
    },
  },
  watch: {
    modelValue(newVal) {
      this.selectedFiles = newVal.slice();
    },
  },
};
</script>
<style scoped>
.file-list {
  list-style: none;
  padding: 0;
  margin-block-end: 0;
  margin-block-start: 0;
  width: 100%;
}

.file-list--outline {
  border: 1px solid var(--basic-grey);
  border-radius: 6px;
  margin-top: 5px;
}
.file-list__item {
  display: grid;
  gap: 10px;
  grid-template-columns: 1fr;
  align-items: start;
  padding: 14px 16px;
}
.file-list__item--checkable {
  grid-template-columns: min-content 1fr;
}
.file-list__item--removable {
  grid-template-columns: 1fr min-content;
}
.file-list__item:not(:last-child) {
  border-bottom: 1px solid var(--basic-grey);
}

.file-list__remove-button {
  width: 20px;
  height: 20px;
  border: none;
  outline: none;
  cursor: pointer;
  background-color: transparent;
}
</style>
