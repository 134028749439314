<template>
  <transition name="fade" v-show="showing">
    <div class="form form--fullwidth">
      <slot></slot>
    </div>
  </transition>
</template>
<script>
export default {
  props: {
    showing: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
<style scoped>
/* vue transition animation */
.fade-enter-active {
  transition: all 0.3s ease-in;
}
.fade-leave-active {
  transition: all 0.3s ease-in;
}
.fade-enter-from,
.fade-leave-to {
 /*  opacity: 0; */
  height: 0;
}
.fade-enter-to,
.fade-leave-from {
  /* opacity: 1; */
  height: 100%;
}
</style>