<script>
import FileUploadProxy from "../../../../proxies/fileUploadProxy.js";
import { UploadFile } from "../../../../models/uploadFile.js";

export default {
  props: {
    isBlocked: {
      type: Boolean,
      default: false
    },
    isGeometryFiles: {
      type: Boolean,
      default: false,
    }
  },
  emits: ["startUpload", "endUpload", "failUpload"],
  setup(props, ctx) {
    const geometryFileExtensions = [".tab", ".mid", ".mif", ".shp", ".dat", ".id", ".map", ".cpg", ".dbf", ".prj", ".shx"]

    function generateUUID() {
      var d = new Date().getTime();
      if (
        typeof performance !== "undefined" &&
        typeof performance.now === "function"
      ) {
        d += performance.now(); //use high-precision timer if available
      }
      var newGuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
        /[xy]/g,
        function (c) {
          var r = (d + Math.random() * 16) % 16 | 0;
          d = Math.floor(d / 16);
          return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
        }
      );

      return newGuid;
    }

    function handleFiles(inFiles) {
      let files = [...inFiles];

      if (!files.length) return;

      const uploadingFiles = [];
      for (let i = 0; i < files.length; i++) {

        if(props.isGeometryFiles)
        {
          const extensionPosition = files[i].name.lastIndexOf('.');
          const fileNameExtension = files[i].name.substr(extensionPosition);

          if(!geometryFileExtensions.includes(fileNameExtension.toLowerCase()))
          {
            continue;
          }
        }

        let uid = generateUUID();
        let file = new UploadFile(uid, files[i].name, files[i].type || 'application/octet-stream', files[i].size);
        uploadingFiles.push({ file: file, sourceFile: files[i] });
      }

      ctx.emit("startUpload", uploadingFiles.map(f=>f.file));

      uploadingFiles.forEach((ff) => {
        FileUploadProxy.uploadFile(ff.sourceFile)
          .then(function (result) {
            ff.file.addStorageUrl(result.fileUID);
            ctx.emit("endUpload", ff.file);
          })
          .catch(function () {
            ctx.emit("failUpload", ff.file);
          });
      });
    }

    function onChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      handleFiles(files);
    }

    function onDrop(e) {
      e.preventDefault();
      if (props.isBlocked) return;
      var files = e.target.files || e.dataTransfer.files;
      handleFiles(files);
    }

    return () => ctx.slots.default({
      onChange: onChange,
      onDrop: onDrop
    })
  },
};
</script>