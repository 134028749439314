import { defineAsyncComponent } from "vue";

const components = [
  {
    name: "DocFields",
    value: defineAsyncComponent(() =>
      import("../../RegistrationProcess/RegistrationDocumentFields/DocFields")
    ),
  },
  {
    name: "DocFieldsEGRZ",
    value: defineAsyncComponent(() =>
      import(
        "../../RegistrationProcess/RegistrationDocumentFields/DocFieldsEGRZ"
      )
    ),
  },
  {
    name: "DocFieldsEGRZ_EGRN",
    value: defineAsyncComponent(() =>
      import(
        "../../RegistrationProcess/RegistrationDocumentFields/DocFieldsEGRZ_EGRN"
      )
    ),
  },
  {
    name: "DocFieldsNotificationAboutBuilding",
    value: defineAsyncComponent(() =>
      import(
        "../../RegistrationProcess/RegistrationDocumentFields/DocFieldsNotificationAboutBuilding"
      )
    ),
  },
  {
    name: "DocFieldsNotificationAboutBuildingEgrn",
    value: defineAsyncComponent(() =>
      import(
        "../../RegistrationProcess/RegistrationDocumentFields/DocFieldsNotificationAboutBuildingEgrn"
      )
    ),
  },
  {
    name: "DocFieldsNotificationAboutDemolition",
    value: defineAsyncComponent(() =>
      import(
        "../../RegistrationProcess/RegistrationDocumentFields/DocFieldsNotificationAboutDemolition"
      )
    ),
  },
  {
    name: "DocFieldsRS",
    value: defineAsyncComponent(() =>
      import("../../RegistrationProcess/RegistrationDocumentFields/DocFieldsRS")
    ),
  },
  {
    name: "DocFieldsGPZU",
    value: defineAsyncComponent(() =>
      import(
        "../../RegistrationProcess/RegistrationDocumentFields/DocFieldsGPZU"
      )
    ),
  },
  {
    name: "DocFieldsGisogdInfoModelOKS",
    value: defineAsyncComponent(() =>
      import(
        "../../RegistrationProcess/RegistrationDocumentFields/DocFieldsGisogdInfoModelOKS"
      )
    ),
  },
  {
    name: "DocFieldsRV",
    value: defineAsyncComponent(() =>
      import("../../RegistrationProcess/RegistrationDocumentFields/DocFieldsRV")
    ),
  },
  {
    name: "DocFieldsTechPlan",
    value: defineAsyncComponent(() =>
      import(
        "../../RegistrationProcess/RegistrationDocumentFields/DocFieldsTechPlan"
      )
    ),
  },
  {
    name: "DocFieldsWithOKSHb",
    value: defineAsyncComponent(() =>
      import(
        "../../RegistrationProcess/RegistrationDocumentFields/DocFieldsWithOKSHb"
      )
    ),
  },
  {
    name: "DocFieldsWithOKSEgrn",
    value: defineAsyncComponent(() =>
      import(
        "../../RegistrationProcess/RegistrationDocumentFields/DocFieldsWithOKSEgrn"
      )
    ),
  },
  {
    name: "DocFieldsWithServitute",
    value: defineAsyncComponent(() =>
      import(
        "../../RegistrationProcess/RegistrationDocumentFields/DocFieldsWithServitute"
      )
    ),
  },
  {
    name: "DocFieldsSection1",
    value: defineAsyncComponent(() =>
      import(
        "../../RegistrationProcess/RegistrationDocumentFields/DocFieldsSection1"
      )
    ),
  },
  {
    name: "DocFieldsSection7",
    value: defineAsyncComponent(() =>
      import(
        "../../RegistrationProcess/RegistrationDocumentFields/DocFieldsSection7"
      )
    ),
  },
  {
    name: "DocFieldsSection8",
    value: defineAsyncComponent(() =>
      import(
        "../../RegistrationProcess/RegistrationDocumentFields/DocFieldsSection8"
      )
    ),
  },
  {
    name: "DocFieldsSection9",
    value: defineAsyncComponent(() =>
      import(
        "../../RegistrationProcess/RegistrationDocumentFields/DocFieldsSection9"
      )
    ),
  },
  {
    name: "DocFieldsSection10",
    value: defineAsyncComponent(() =>
      import(
        "../../RegistrationProcess/RegistrationDocumentFields/DocFieldsSection10"
      )
    ),
  },
  {
    name: "DocFieldsIzDocument10",
    value: defineAsyncComponent(() =>
        import(
            "../../RegistrationProcess/RegistrationDocumentFields/DocFieldsIzDocument10"
            )
    ),
  },
  {
    name: "DocFieldsSection12",
    value: defineAsyncComponent(() =>
      import(
        "../../RegistrationProcess/RegistrationDocumentFields/DocFieldsSection12"
      )
    ),
  },
  {
    name: "DocFieldsSection15",
    value: defineAsyncComponent(() =>
      import(
        "../../RegistrationProcess/RegistrationDocumentFields/DocFieldsSection15"
      )
    ),
  },
  {
    name: "DocFieldsIZRS",
    value: defineAsyncComponent(() =>
      import(
        "../../RegistrationProcess/RegistrationDocumentFields/DocFieldsIZRS"
      )
    ),
  },
  {
    name: "DocFieldsExpertise",
    value: defineAsyncComponent(() =>
      import(
        "../../RegistrationProcess/RegistrationDocumentFields/DocFieldsExpertise"
      )
    ),
  },
  {
    name: "DocFieldsZu",
    value: defineAsyncComponent(() =>
      import("../../RegistrationProcess/RegistrationDocumentFields/DocFieldsZu")
    ),
  },
  {
    name: "DocFieldsZuOther",
    value: defineAsyncComponent(() =>
      import(
        "../../RegistrationProcess/RegistrationDocumentFields/DocFieldsZuOther"
      )
    ),
  },
  {
    name: "DocFieldsZuEgrn",
    value: defineAsyncComponent(() =>
      import(
        "../../RegistrationProcess/RegistrationDocumentFields/DocFieldsZuEgrn"
      )
    ),
  },
  {
    name: "DocFieldsForestRegulation",
    value: defineAsyncComponent(() =>
      import(
        "../../RegistrationProcess/RegistrationDocumentFields/DocFieldsForestRegulation"
      )
    ),
  },
  {
    name: "DocFieldsGisogdForestProject",
    value: defineAsyncComponent(() =>
      import(
        "../../RegistrationProcess/RegistrationDocumentFields/DocFieldsGisogdForestProject"
      )
    ),
  },
  {
    name: "DocFieldsGisogdLesParcelPd",
    value: defineAsyncComponent(() =>
      import(
        "../../RegistrationProcess/RegistrationDocumentFields/DocFieldsGisogdLesParcelPd"
      )
    ),
  },
  {
    name: "DocFieldsWithSettlement",
    value: defineAsyncComponent(() =>
      import(
        "../../RegistrationProcess/RegistrationDocumentFields/DocFieldsWithSettlement"
      )
    ),
  },
  {
    name: "DocFieldsSection5",
    value: defineAsyncComponent(() =>
      import(
        "../../RegistrationProcess/RegistrationDocumentFields/DocFieldsSection5"
      )
    ),
  },
  {
    name: "DocFieldsSection14",
    value: defineAsyncComponent(() =>
      import(
        "../../RegistrationProcess/RegistrationDocumentFields/DocFieldsSection14"
      )
    ),
  },
  {
    name: "DocFieldsResolutionUseLand",
    value: defineAsyncComponent(() =>
      import(
        "../../RegistrationProcess/RegistrationDocumentFields/DocFieldsResolutionUseLand"
      )
    ),
  },
  {
    name: "DocFieldsBaseIz",
    value: defineAsyncComponent(() =>
      import(
        "../../RegistrationProcess/RegistrationDocumentFields/DocFieldsBaseIz"
      )
    ),
  },
  {
    name: "DocFieldsIzSTPRF",
    value: defineAsyncComponent(() =>
      import(
        "../../RegistrationProcess/RegistrationDocumentFields/DocFieldsIzSTPRF"
      )
    ),
  },
  {
    name: "DocFieldsZuPPM",
    value: defineAsyncComponent(() =>
      import(
        "../../RegistrationProcess/RegistrationDocumentFields/DocFieldsZuPPM"
      )
    ),
  },
];

export default components;
