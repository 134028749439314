<template>
  <div class="table-container">
    <table class="table">
      <tr class="">
        <th
            v-for="(column, idx) in columns"
            :key="idx"
            class="table__header-text"
        >
          {{ column }}
        </th>
      </tr>
        <tr
            v-for="item in data"
            :key="item.id"
            @click="onRowClick(item)"
            class="table__tr"
        >
          <td class="table__td">
            <a v-html="highlight(query, item.number)"> </a>
          </td>
          <td
              class="table__td"
              v-html="highlight(query, $filters.toDateString(item.requestDate))"
          ></td>
          <td
              class="table__td"
              v-html="highlight(query, item.nameOfSenderSystem)"
          ></td>
          <td class="table__td">
            {{ item.requestSender.displayName }}
          </td>
          <td class="table__td">
            {{ item.wayOfReceival.displayName }}
          </td>
          <td class="table__td">
            {{ item.dataFormat.displayName }}
          </td>
          <td class="table__td">
            <delete-button
                :registration-request-number="item.number"
                @registrationRequestDeleted="onDelete"
                class="table__row-toolbar"
            ></delete-button>
          </td>
        </tr>
    </table>
  </div>
</template>

<script>
import {useRouter} from "vue-router";
import RegistrationRequestDeleteButton from "./RegistrationRequestDeleteButton";
import useRegistrationRequest from "../../hooks/registrationRequest";
import useTextHighlight from "../../hooks/textHighlight";

export default {
  props: {
    data: Array,
    query: String,
  },
  components: {
    DeleteButton: RegistrationRequestDeleteButton,
  },
  setup() {
    const router = useRouter();
    const columns = [
      "Номер записи",
      "Дата поступления",
      "Наименование ИС, из которой передаются данные",
      "Лицо, направившее сведения",
      "Способ направления данных",
      "Форма данных",
      "",
    ];

    const {remove} = useRegistrationRequest();

    function onRowClick(item) {
      router.push({
        name: "registrationRequest",
        params: {number: item.number},
      });
    }

    async function onDelete(number) {
      await remove(number);
      router.go();
    }

    const {highlight} = useTextHighlight();
    return {columns, onRowClick, onDelete, highlight};
  },
};
</script>
<style>
.table__tr:hover .table__row-toolbar {
  opacity: 1;
}

.table__row-toolbar {
  opacity: 0;
}
</style>