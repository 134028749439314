import instance from "../../../api.js";
import SectionDocument from "../models/sectionDocument.js";
import EditableDocument from "../models/editableDocument.js";
import { StorageFileDto } from "../../../dtos/storageFileDto";
import {
  RegistrationProcessItemDto,
  SignRegistrationProcessItemDto,
} from "../../../dtos/editableDocumentDto.js";

class SectionDocumentsProxy {
  static async getSectionDocument(documentNumber) {
    const documentDto = await instance.get(`documents/${documentNumber}`);

    const inboxDataNumber = await instance.get(
      `gisogd-documents/${documentNumber}/inboxdata`
    );

    return SectionDocument.fromDto(documentDto, inboxDataNumber);
  }

  static async getEditableDocument(documentNumber) {
    const editableDocumentDto = await instance.get(
      `documents/${documentNumber}/edit`
    );

    return EditableDocument.fromDto(editableDocumentDto);
  }

  static async updateEditableDocument(
    registrationNumber,
    updatingDate,
    editableDocument,
    rawJson,
    signResult,
    certificateFN
  ) {
    const files = editableDocument.files
      .filter((x) => !x.isTerritorialPlanningRegulation)
      .map(
        (f) =>
          new StorageFileDto(f.id, f.name, f.fileUrl, f.contentType, f.size)
      );
    const territorialPlanningRegulationFiles = editableDocument.files
      .filter((x) => x.isTerritorialPlanningRegulation)
      .map(
        (f) =>
          new StorageFileDto(f.id, f.name, f.fileUrl, f.contentType, f.size)
      );
    const geometrySource = {
      geometry:
        editableDocument.geometrySource.geometry !== ""
          ? JSON.stringify(editableDocument.geometrySource.geometry)
          : null,
      geometrySourceInfo: JSON.stringify(
        editableDocument.geometrySource.geometrySourceInfo
      ),
    };

    const dto = new SignRegistrationProcessItemDto(
      editableDocument.documentType.id,
      files,
      territorialPlanningRegulationFiles,
      JSON.stringify(editableDocument.stateSnapshot),
      geometrySource.geometrySourceInfo,
      geometrySource.geometry,
      updatingDate,
      rawJson,
      signResult,
      certificateFN
    );

    await instance.put(`gisogd-documents/${registrationNumber}`, dto);
  }

  static async getSignData(editableDocument) {
    const files = editableDocument.files
      .filter((x) => !x.isTerritorialPlanningRegulation)
      .map(
        (f) =>
          new StorageFileDto(f.id, f.name, f.fileUrl, f.contentType, f.size)
      );
    const territorialPlanningRegulationFiles = editableDocument.files
      .filter((x) => x.isTerritorialPlanningRegulation)
      .map(
        (f) =>
          new StorageFileDto(f.id, f.name, f.fileUrl, f.contentType, f.size)
      );
    const geometrySource = {
      geometry:
        editableDocument.geometrySource.geometry !== ""
          ? JSON.stringify(editableDocument.geometrySource.geometry)
          : null,
      geometrySourceInfo: JSON.stringify(
        editableDocument.geometrySource.geometrySourceInfo
      ),
    };

    const editableDocumentDto = new RegistrationProcessItemDto(
      editableDocument.documentType.id,
      files,
      territorialPlanningRegulationFiles,
      JSON.stringify(editableDocument.stateSnapshot),
      geometrySource.geometrySourceInfo,
      geometrySource.geometry
    );

    return await instance.put(
      `gisogd-documents/signdata/${editableDocument.registrationNumber}`,
      editableDocumentDto
    );
  }
}

export default SectionDocumentsProxy;
