<template>
  <base-file :file="file">
    <template #ext-buttons>
      <territorial-planning-regulation-button
        class="group__item"
        :modelValue="file?.isTerritorialPlanningRegulation"
        @update:modelValue="update(file, $event)"
        :disabled="disabled"
      />
    </template>
  </base-file>
</template>
<script>

import BaseFile from './BaseFile';
import TerritorialPlanningRegulationButton from './TerritorialPlanningRegulationButton';

export default {
  props: {
    file: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: () => false,
    },
  },
  components: { BaseFile, TerritorialPlanningRegulationButton },
  methods: {
    update(file, value)
    {
      file.isTerritorialPlanningRegulation = value;
      this.$emit('update:file', this.file);
    },
  }
};
</script>

<style scoped>
</style>
