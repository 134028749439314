import RegistrationRequestPage from '../components/RegistrationRequest/RegistrationRequestPage.vue';
import RegistrationRequestCreatePage from '../components/RegistrationRequest/RegistrationRequestCreatePage.vue';

const routes = [
  {
    path: 'registration-request/:number(\\d+)',
    name: 'registrationRequest',
    component: RegistrationRequestPage,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: 'registration-request/create',
    name: 'registrationRequestCreate',
    component: RegistrationRequestCreatePage,
    meta: {
      requiresAuth: true,
    },
  },
];
export default routes;
