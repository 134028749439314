<template>
  <div>
    <FileUploadRenderless
      v-slot="{ onChange, onDrop }"
      @startUpload="$emit('startUpload', $event)"
      @endUpload="$emit('endUpload', $event)"
      @failUpload="$emit('failUpload', $event)"
    >
      <div
        @drop="onDrop"
        @dragenter="preventDefault"
        @dragover="preventDefault"
        @dragleave="preventDefault"
      >
        <div class="files-loading files-loading_blocked" v-if="isBlocked">
          <p class="files-loading__text">{{ headerBlockedMessage }}</p>
        </div>
        <div class="files-loading" v-else>
          <p class="files-loading__text">{{ headerMessage }}</p>
          <div class="buttons is-marginless">
            <button
              @click="onUploadClick"
              class="button button--secondary"
              v-if="!isBlocked"
            >
              Загрузить
            </button>
          </div>
          <input
            class="files-loading__input"
            ref="uploadInput"
            type="file"
            id="items"
            multiple
            @change="onChange"
            data-qa="uploadFileInput"
          />
        </div>
      </div>
    </FileUploadRenderless>
  </div>
</template>

<script>
import FileUploadRenderless from "./FileUploadRenderless";

export default {
  props: {
    headerMessage: {
      type: String,
      default: "Загрузите файлы или перетащите в область",
    },
    headerBlockedMessage: {
      type: String,
      default: "Перейдите к редактированию, чтобы загрузить файлы",
    },
    isBlocked: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    FileUploadRenderless,
  },
  emits: ["startUpload", "endUpload", "failUpload"],
  methods:
  {
    preventDefault(event) {
      event.preventDefault();
      event.stopPropagation();
    },
    onUploadClick() {
      this.$refs.uploadInput.value=null;
      this.$refs.uploadInput.click();
    }
  },
};
</script>
<style scoped>
.files-loading {
  position: relative;
  border: 1px dashed var(--basic-grey-dark);
  border-radius: 6px;
  padding: 32px 20px;
  text-align: center;
  background-color: var(--basic-grey-lighter);

}

.files-loading_blocked {
  background-color: var(--basic-grey-slightly-lighter);
  border: 0;
  margin-top: 24px;
}

.files-loading::after {
  content: "";
  position: absolute;
  width: 44px;
  height: 44px;
  background-image: url("../../../../assets/svg/file.svg");
  background-repeat: no-repeat;
  background-position: 0 0;
  left: calc(50% - 22px);
  top: 32px;
}

.files-loading__text {
  margin-top: 60px;
  font-size: 16px;
  line-height: 20px;
  color: var(--secondary-blue-darker);
}

.files-loading__input {
  position: absolute;
  width: 0;
  height: 0;
}
</style>
