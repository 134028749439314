import DocBase from './docBase';
export default class DocSection15 extends DocBase {
  constructor(
    id,
    docStatus,
    docClass,
    docName,
    docNum,
    docDate,
    orgName,
    canceledDocuments=[],
    note,
    oopts,
    affectedMunicipalities = [],
    guid
  ) {
    super(
      id,
      docStatus,
      docClass,
      docName,
      docNum,
      docDate,
      orgName,
      canceledDocuments,
      note,
      affectedMunicipalities,
      guid
    );
    this.oopts = oopts || [];
  }
}
