<template>
  <router-view :key="projectAlias" />
</template>

<script>
import useProjectAlias from "../hooks/projectAlias";
import { ref, watch } from "vue";
import { useRoute } from "vue-router";
export default {
  setup() {
    const route = useRoute();
    const projectAlias = ref(route.params.projectAlias);

    watch(
      () => route.params,
      (toParams) => {
        projectAlias.value = toParams.projectAlias;
      }
    );
    return { projectAlias };
  },
  async beforeRouteEnter(to) {
    const { setProjectAlias } = useProjectAlias();
    setProjectAlias(to.params.projectAlias);
  },
};
</script>