<template>
  <div class="form-control">
    <label class="label"><slot></slot></label>
    <input
      class="field date-field"
      type="date"
      :value="localValue"
      :class="fieldClass"
      @blur="update($event.target.value)"
      @keydown.enter.prevent="$event.target.blur()"
      :min="min"
      :max="max"
      :readonly="isReadonly"
    />
    <div v-for="(error, index) of errors" :key="index">
      <div class="field-message--error">{{ error.$message }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: Date,
    },
    min: {
      default: "1970-01-01",
    },
    max: {
      default: "2121-12-31",
    },
    isReadonly: {
      type: Boolean,
      default: false,
    },
    errors: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    fieldClass() {
      let cls = '';
      if (this.errors.some(() => true))
        cls += 'field--error'
      if (this.modelValue === null || this.modelValue === undefined)
        cls += ' date-field--hide-mask'
      return cls;
    },
    localValue() {
      if(this.modelValue)
        return `${this.modelValue.getFullYear()}-${(this.modelValue.getMonth() + 1).toString().padStart(2, '0')}-${this.modelValue.getDate().toString().padStart(2, '0')}`

      return null;
    },
  },
  methods: {
    update(value) {
      if (value === "") this.$emit("update:modelValue", null);
      else this.$emit("update:modelValue", new Date(value));
    },
  },
};
</script>

<style lang="scss" scoped>
.field.date-field {
  width: 200px;
  background-image: url("../../../assets/svg/calendar.svg");
  background-repeat: no-repeat;
  background-size: 20px 20px;
  background-position: calc(100% - 12px) 13px;
}

.date-field::-webkit-calendar-picker-indicator {
  background: transparent;
  outline: none;
}

.date-field--hide-mask::-webkit-datetime-edit-fields-wrapper {
  color: var(--basic-grey-dark)
}

//-webkit-datetime-edit-day-field
//-webkit-datetime-edit-month-field
//-webkit-datetime-edit-year-field
</style>