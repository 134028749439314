<template>
  <div class="page" data-qa="registrationDocumentOrChangePage">
    <page-header class="container">
      <template #title>{{ currentStep.pageTitle }}</template>
      <template #subtitle>
        <router-link
          :to="{
            name: 'registrationProcess',
            params: { number: inboxDataNumber },
          }"
          class="link link--back"
          data-qa="dataPlacement"
          >Размещение данных</router-link
        >
      </template>
      <template #buttons v-if="currentStep.next && haveEditRight">
        <SaveButton @click="save"></SaveButton>
        <button
          class="button button--outline button--left-icon button-back"
          v-if="currentStep.back"
          @click="moveBackStep"
          data-qa="back"
        >
          Назад
        </button>
        <button
          class="button button--primary button--right-icon button-next"
          @click="moveNextStep"
          data-qa="next"
        >
          Далее
        </button>
      </template>
    </page-header>

    <Wizard class="container">
      <Step
        :name="pageSteps.step1.tag"
        :is-active="currentStep == pageSteps.step1"
        description="Информация о документе"
        :done="pageSteps.step1.done"
      >
        <div class="box container">
          <form class="form form--fullwidth grid3on2" @submit.prevent="">
            <div>
              <fieldset class="form__fieldset">
                <legend class="form__legend">Основные свойства</legend>
                <FilterOptions
                  v-slot="{ filter, filtredOptions }"
                  :modelValue="documentTypeRefs"
                >
                  <UniversalSelectField
                    :modelValue="state.registrationProcessItem.documentType"
                    @update:modelValue="changeDocumentType"
                    @filter="filter"
                    :canFind="true"
                    :options="filtredOptions.value"
                    data-qa="type"
                  >
                    <label class="label label--required">{{ classificatorSelectTitle }}</label>
                  </UniversalSelectField>
                </FilterOptions>
              </fieldset>

              <component
                :key="state.registrationProcessItem.documentType.id"
                v-if="state.registrationProcessItem.documentType"
                class="form__fieldset"
                :is="documentType"
                v-model="state.registrationProcessItem.stateSnapshot"
                @update:vector="updateVector"
                :document-type-id="
                  state.registrationProcessItem.documentType.id
                "
              ></component>
            </div>
            <fieldset class="form__fieldset">
              <legend class="form__legend">Файлы</legend>
              <checked-files-field
                v-model="state.registrationProcessItem.files"
                :files="avaliableFiles"
                :errors="
                  v$.$error ? v$.registrationProcessItem.files.$errors : []
                "
              >
                <template #file="{file}">
                  <territorial-planning-regulation-file
                    v-if="isTerritorialPlanningRegulationDocument"
                    :file="file"
                    :disabled="getFileDisableState(file)"
                    @update:file="updateFile"
                  />
                  <base-file v-else :file="file" />
                </template>
                Выберите файлы, относящиеся к документу
              </checked-files-field>
            </fieldset>
          </form>
        </div>
      </Step>
      <Step
        :name="pageSteps.step2.tag"
        :is-active="currentStep == pageSteps.step2"
        description="Выбор территории действия"
        :done="pageSteps.step2.done"
        ><div class="box container">
          <GeometrySource
            v-model:geometrySource="
              state.registrationProcessItem.geometrySource
            "
            :stateSnapshot="state.registrationProcessItem.stateSnapshot"
            :files="avaliableFiles"
          />
        </div>
      </Step>
      <Step
        :name="pageSteps.step3.tag"
        v-if="pageSteps.step3"
        :is-active="currentStep == pageSteps.step3"
        description="Выбор муниципальных образований"
        :done="pageSteps.step3.done"
      >
        <div class="box container">
          <form class="form form--fullwidth" @submit.prevent="">
            <legend class="title-20">Выбор муниципальных образований</legend>
            <div class="form-control">
              <label class="label"
                >Муниципальные образования, для которых разработан
                документ</label
              >
              <affected-municipalities
                v-model="state.registrationProcessItem.stateSnapshot"
              >
              </affected-municipalities>
            </div>
          </form>
        </div>
      </Step>
      <Step
        :name="pageSteps.step4.tag"
        :is-active="currentStep == pageSteps.step4"
        description="Размещение в ГИСОГД"
      >
        <TabSpinner v-if="isLoading" />
        <section class="wrapper" v-else>
          <div class="ready-placement__image"></div>
          <p class="ready-placement__text">
            Готово! Можно направлять документ на размещение в ГИСОГД.
          </p>
          <div class="buttons group">
            <button
              class="button button--light button--left-icon button-back group__item"
              @click="moveBackStep"
              data-qa="back"
            >
              Назад
            </button>
            <button
              class="button button--primary group__item"
              @click="completeRegistrationDocument"
              data-qa="placementInGISOGD"
            >
              Разместить в ГИСОГД
            </button>
          </div>
        </section>
        <SignModal
          :isModalWindowlVisible="isSignModalVisible"
          :dataForSign="dataToSign.byteBuffer"
          @completeSign="registerDocumentWithSign"
          @closeModal="closeSignModal"
        ></SignModal>
      </Step>
    </Wizard>
  </div>
</template>
<script>
import eventBus from "../../eventBus";
import { useRoute, useRouter } from "vue-router";
import { ref, computed, reactive } from "vue";
import useVuelidate from "@vuelidate/core";
import { helpers, required } from "@vuelidate/validators";
import { useRegistrationProcessRefs } from "../../hooks/registrationProcessRefs";
import useRegistrationProcessItem from "../../hooks/registrationProcessItem";
import AffectedMunicipalities from "./AffectedMunicipalities";
import {
  GeometrySource as GeometrySourceClass,
  GeometrySourceInfoFromDocument,
} from "../../models/geometrySource";
import { RegistrationDocument } from "../../models/registrationDocument";
import useAuthorization from "../../authorization";

import CheckedFilesField from "../basic/form/files/CheckedFilesField";
import SaveButton from "../basic/form/SaveButton";
import GeometrySource from "../RegistrationProcess/RegistrationDocumentTerritory/GeometrySource";
import Wizard from "../basic/wizard/BaseWizard";
import Step from "../basic/wizard/BaseStep";
import DocumentFactory from "../../models/documents/factory";
import FilterOptions from "../basic/renderless/FilterOptions.vue";
import TabSpinner from "../basic/tabs/TabSpinner";
import BaseFile from "../basic/files/BaseFile";
import TerritorialPlanningRegulationFile from "../basic/files/TerritorialPlanningRegulationFile";
import CheckBoxField from "../basic/form/CheckBoxField.vue";
import useProjectAlias from "@/hooks/projectAlias";
// import GisogdDocumentsProxy from "./proxies/gisogdDocumentsProxy";
// import GeometryProxy from "../../proxies/geometryProxy";
// import { getGeometrySourceForDocIzRs } from "../../models/geometrySource";
import SignModal from "../sign/SignModal.vue";
import SignProxy from "../../proxies/signProxy.js";
import UniversalSelectField from "@/components/basic/form/select/UniversalSelectField.vue";

export default {
  components: {
    UniversalSelectField,
    Wizard,
    Step,
    SaveButton,
    GeometrySource,
    CheckedFilesField,
    FilterOptions,
    TabSpinner,
    BaseFile,
    TerritorialPlanningRegulationFile,
    CheckBoxField,
    AffectedMunicipalities,
    SignModal,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const { currentProject } = useProjectAlias();
    const pageSteps = reactive({
      step1: {
        tag: "1",
        pageTitle: route.meta.isDocChange
          ? "Информация об изменении документа"
          : "Информация о документе",
        pageName: "objectFields",
        back: null,
        next: "step2",
        done: false,
        isValid() {
          v$.value.$touch();
          if (v$.value.$error) return false;
          else return true;
        },
      },
      step2: {
        tag: "2",
        pageTitle: "Выбор территории действия",
        pageName: "territory",
        back: "step1",
        next: "step3",
        done: false,
        isValid() {
          if (!state.value.registrationProcessItem.geometrySource.geometry)
            return false;

          if (state.value.registrationProcessItem.geometrySource.geometry.original.geometry.includes("Line"))
            return false;

          if (
            state.value.registrationProcessItem.geometrySource.geometry.original
              .srid === 0
          )
            return false;

          return true;
        },
      },
      step3: {
        tag: "3",
        pageTitle: "Выбор муниципального образования действия",
        pageName: "affectedMunicipalities",
        back: "step2",
        next: "step4",
        isValid() {
          return true;
        },
      },
      step4: {
        tag: "4",
        pageTitle: "Размещение в ГИСОГД",
        pageName: "registration",
        back: "step3",
        next: null,
      },
    });

    if (!currentProject.value.isRegion) {
      delete pageSteps.step3;
      pageSteps.step2.next = "step4";
      pageSteps.step4.back = "step2";
      pageSteps.step4.tag = "3";
    }

    const { canEdit } = useAuthorization();
    const haveEditRight = canEdit();

    let currentStep = ref(pageSteps.step1);
    const inboxDataNumber = ref(route.params.number);
    const itemId = ref(route.params.id);
    const { documentTypeRefs } = useRegistrationProcessRefs(
      route.meta.isDocChange
    );
    const {
      registrationProcessItem,
      avaliableFiles,
      addItem,
      updateItem,
      registerItem,
      getSignData,
    } = useRegistrationProcessItem(inboxDataNumber, itemId);
    const isSignDisabled = ref(true);

    SignProxy.GetSettings().then((result) => {
      isSignDisabled.value = result;
    });

    const state = ref({
      number: inboxDataNumber,
      registrationProcessItem: registrationProcessItem,
    });

    const isSignModalVisible = ref(false);
    const dataToSign = ref([]);

    const rules = {
      registrationProcessItem: {
        documentType: {
          required: helpers.withMessage("Заполните поле", required),
        },
        files: {
          required: helpers.withMessage(
            "Для размещения документа в ГИСОГД отметьте файлы, содержащие положение о территориальном планировании",
            (value) => {
              if (isTerritorialPlanningRegulationDocumentIz.value) return true;
              if (!isTerritorialPlanningRegulationDocument.value) return true;
              return value.some(
                (x) =>
                  x !== null && x["isTerritorialPlanningRegulation"] == true
              );
            }
          ),
        },
      },
    };
    const v$ = useVuelidate(rules, state);

    const editmode = computed(() => {
      return registrationProcessItem.value.id !== undefined;
    });

    const documentType = computed(() => {
      return DocumentFactory.getDocTypeName(
        state.value.registrationProcessItem.documentType
      );
    });

    const isTerritorialPlanningRegulationDocument = computed(() => {
      return (
        state.value.registrationProcessItem.documentType &&
        (state.value.registrationProcessItem.documentType.id < 400 ||
          (state.value.registrationProcessItem.documentType.id > 20100 &&
            state.value.registrationProcessItem.documentType.id < 20401))
      ); // docIz 1-3 раздела
    });

    const isTerritorialPlanningRegulationDocumentIz = computed(() => {
      return (
        state.value.registrationProcessItem.documentType &&
        state.value.registrationProcessItem.documentType.id > 20100 &&
        state.value.registrationProcessItem.documentType.id < 20401
      );
    });

    const classificatorSelectTitle = computed(() => {
      return route.meta.isDocChange
        ? "Вид документа изменения для размещения"
        : "Вид документа для размещения";
    });

    const isLoading = ref(false);
    function changeDocumentType(type) {
      state.value.registrationProcessItem.documentType = type;
      state.value.registrationProcessItem = new RegistrationDocument(
        state.value.registrationProcessItem.id,
        state.value.registrationProcessItem.name,
        state.value.registrationProcessItem.documentType,
        [],
        state.value.registrationProcessItem.status,
        DocumentFactory.create(
          state.value.registrationProcessItem.documentType,
          {}
        ),
        new GeometrySourceClass()
      );
    }

    async function moveNextStep() {
      if (currentStep.value.next && currentStep.value.isValid()) {
        // var vectors = [];

        // if (
        //   currentStep.value === pageSteps.step1 &&
        //   state.value.registrationProcessItem.documentType.id === 1306
        // ) {
        //   vectors = await GisogdDocumentsProxy.GetVectorsFromDocIzRsOriginRegistration(
        //     state.value.registrationProcessItem.stateSnapshot.documentsOriginRegistration.map(
        //       function(item) {
        //         return item.registrationNumber;
        //       }
        //     )
        //   );
        //   const geometry = await GeometryProxy.getFromVector(
        //     "LandPlot",
        //     vectors
        //   );
        //   state.value.registrationProcessItem.geometrySource = getGeometrySourceForDocIzRs(
        //     geometry
        //   );
        //   state.value.registrationProcessItem.geometrySource = getGeometrySourceForDocIzRs(
        //     geometry
        //   );
        // }

        await save();

        // if (
        //   currentStep.value === pageSteps.step1 &&
        //   state.value.registrationProcessItem.documentType.id === 1306
        // )
        //   state.value.registrationProcessItem.stateSnapshot.zu = vectors;

        currentStep.value.done = true;
        currentStep.value = pageSteps[currentStep.value.next];
      } else {
        currentStep.value.done = false;
        if (currentStep.value === pageSteps.step2) {
          if (!state.value.registrationProcessItem.geometrySource.geometry) {
            eventBus.emit("warning", {
              message:
                "Чтобы перейти к шагу 'Размещение в ГИСОГД', необходимо применить территорию действия к документу.",
            });

            return;
          }

          if (
            state.value.registrationProcessItem.geometrySource.geometry.original
              .srid === 0
          ) {
            eventBus.emit("warning", {
              message:
                "Чтобы перейти к шагу 'Размещение в ГИСОГД', необходимо указать систему координат",
            });
          }

          if (state.value.registrationProcessItem.geometrySource.geometry.original.geometry.includes("Line")) 
          {
            eventBus.emit("warning", {
              message:
                "Тип геометрии территории действия документа должен быть полигон. Выберите другую геометрию или измените текущую.",
            });
          }
        }
      }
    }

    function moveBackStep() {
      if (currentStep.value.back) {
        currentStep.value = pageSteps[currentStep.value.back];
      }
    }

    async function save() {
      v$.value.$touch();
      if (v$.value.$error) return;
      if (editmode.value) {
        await updateItem(
          inboxDataNumber.value,
          state.value.registrationProcessItem
        );
      } else {
        let item = await addItem(
          inboxDataNumber.value,
          state.value.registrationProcessItem
        );
        router.push({
          name: "registrationDocumentEdit",
          params: { number: inboxDataNumber.value, id: item.value.id },
        });
      }
    }

    async function completeRegistrationDocument() {
      isLoading.value = true;

      if (!isSignDisabled.value) {
        dataToSign.value = await getSignData(
          state.value.registrationProcessItem
        );
        isSignModalVisible.value = true;
        return;
      }

      await registerDocument(null, null, null, null, null);
    }

    async function registerDocumentWithSign(signedData) {
      await registerDocument(
        dataToSign.value.registrationNumber,
        dataToSign.value.registrationDate,
        dataToSign.value.rawJson,
        signedData.signResult,
        signedData.certificateFN
      );
    }

    async function registerDocument(
      registrationNumber,
      registrationDate,
      stateSnapshot,
      signResult,
      certificateFN
    ) {
      await registerItem(
        inboxDataNumber.value,
        state.value.registrationProcessItem,
        registrationNumber,
        registrationDate,
        stateSnapshot,
        signResult,
        certificateFN
      )
      router.push({
        name: "registrationProcess",
        params: { number: inboxDataNumber.value },
      });
      isLoading.value = false;
    }

    function closeSignModal(isSuccess) {
      if (!isSuccess) isLoading.value = false;

      isSignModalVisible.value = false;
    }

    function updateVector(newVector) {
      if (newVector) {
        registrationProcessItem.value.geometrySource = new GeometrySourceClass(
          new GeometrySourceInfoFromDocument()
        );
      } else {
        registrationProcessItem.value.geometrySource = new GeometrySourceClass();
      }
    }

    function updateFile(newFile) {
      if (newFile) {
        var index = registrationProcessItem.value.files.findIndex(
          (x) => x.fileUrl === newFile.fileUrl
        );

        if (index !== -1)
          registrationProcessItem.value.files[
            index
          ].isTerritorialPlanningRegulation =
            newFile.isTerritorialPlanningRegulation;
      }
    }

    function getFileDisableState(file) {
      return !state.value.registrationProcessItem.files.some(function(item) {
        return item.fileUrl === file.fileUrl;
      });
    }

    return {
      inboxDataNumber,
      pageSteps,
      currentStep,
      state,
      moveBackStep,
      moveNextStep,
      changeDocumentType,
      documentTypeRefs,
      avaliableFiles,
      documentType,
      save,
      completeRegistrationDocument,
      v$,
      isLoading,
      haveEditRight,
      classificatorSelectTitle,
      updateVector,
      isTerritorialPlanningRegulationDocument,
      getFileDisableState,
      updateFile,
      registerDocumentWithSign,
      isSignModalVisible,
      closeSignModal,
      dataToSign,
    };
  },

};
</script>
<style scoped lang="scss">
.button:not(:first-child) {
  margin-left: 12px;
}
.button-back:before {
  background-image: url("~@/assets/svg/chevron-left.svg");
}

.button-next::after {
  background-image: url("~@/assets/svg/chevron-right.svg");
}

//todo временно тут
.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;

  & .buttons {
    display: inline-block;
  }
}

.ready-placement__image {
  margin-top: 78px;
  margin-bottom: 48px;
  width: 300px;
  height: 200px;
  background-image: url("../../assets/svg/hand-thumbs-up.svg");
}

.ready-placement__text {
  margin-bottom: 24px;
  font-size: 16px;
}
</style>
