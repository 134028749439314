import { ref } from 'vue';
import SubjectProxy from '../proxies/subjectProxy';
import Person from '../models/person';
import Organization from '../models/organization'
import Entrepreneur from '../models/enterprenuer';

export default function useSubject() {
    const personModel = ref(new Person());
    const organizationModel = ref(new Organization());
    const enterpreneurModel = ref(new Entrepreneur());

    async function createPerson() {
        return await SubjectProxy.createPerson(personModel.value);
    }

    async function createOrganization() {
        return await SubjectProxy.createOrganization(organizationModel.value);
    }

    async function createEnterpreneur() {
        return await SubjectProxy.createEntrepreneur(enterpreneurModel.value);
    }

    async function fetchPerson(id) {
        personModel.value = await SubjectProxy.fetchPerson(id);
    }

    async function fetchOrganization(id) {
        organizationModel.value = await SubjectProxy.fetchOrganization(id);
    }

    function clearModels() {
        personModel.value = new Person();
        organizationModel.value = new Organization();
        enterpreneurModel.value = new Entrepreneur();
    }

    async function makePersonsInactive(activePersonsExternalId) {
        return await SubjectProxy.makePersonsInactive(activePersonsExternalId);
    }

    async function makeOrganizationsInactive(activeOrganizationsExternalId) {
        return await SubjectProxy.makeOrganizationsInactive(activeOrganizationsExternalId);
    }

    async function getSubjectStatusDictionary() {
        return await SubjectProxy.getSubjectStatus();
    }

    return {
        personModel,
        organizationModel,
        enterpreneurModel,
        createPerson,
        createOrganization,
        fetchPerson,
        fetchOrganization,
        clearModels,
        makePersonsInactive,
        makeOrganizationsInactive,
        getSubjectStatusDictionary,
        createEnterpreneur,

    }
}