<template>
  <a @click="showModal" v-bind="$attrs"> <slot>Отказано в размещении</slot> </a>
  <modal-window :visible="visibleModal" @close="closeModal">
    <template #title>Отказать в размещении</template>
    <form class="form position-absolute" @submit.prevent="">
      <UniversalSelectField
          @update:modelValue="updateRejectionReason($event)"
          :modelValue="rejectionReason"
          :displayProp="'displayName'"
          :options="rejectionReasonRefs"
          :key-prop="'id'"
      >
        <label class="label label--required">Причина отказа</label>
      </UniversalSelectField>
    </form>
    <template #footer>
      <button class="button button--light" @click="closeModal">Отмена</button>
      <button class="button button--primary" @click="confirmReject">
        Отказать
      </button>
    </template>
  </modal-window>
</template>
<script>
import { ref } from "vue";
import UniversalSelectField from "@/components/basic/form/select/UniversalSelectField.vue";
export default {
  emits: ["confirmReject"],
  props: {
    rejectionReasonRefs: {
      type: Array,
    },
  },
  components: { UniversalSelectField },
  setup(props, { emit }) {
    const visibleModal = ref(false);

    function showModal() {
      visibleModal.value = true;
    }

    function closeModal() {
      visibleModal.value = false;
    }

    const rejectionReason = ref(props.rejectionReasonRefs[0]);

    function updateRejectionReason(value)
    {
      rejectionReason.value = value;
    }

    function confirmReject() {
      emit("confirmReject", rejectionReason.value.id);
      closeModal();
    }

    return {
      rejectionReason,
      visibleModal,
      showModal,
      closeModal,
      confirmReject,
      updateRejectionReason
    };
  },
};
</script>
<style scoped>
  .form.position-absolute{
    position: absolute;
    width: 556px;
  }
</style>