<template>
  <div></div>
</template>
<script>
import {watch, toRefs, onMounted} from "vue";

export default {
  props: {
    leaflet: {
      type: Object,
      required: true,
    },
    map: {
      type: Object,
      required: true,
    },
    backgroundLayerUrl: {
      type: String,
      required: true,
    },
    backgroundLayerAttribution: {
      type: String
    }
  },

  setup(props) {
    const {backgroundLayerUrl, backgroundLayerAttribution, map, leaflet} = toRefs(props);
    let currentLayer = null;

    function addLayer() {
      if (currentLayer) currentLayer.remove();

      //if (!backgroundLayer || !backgroundLayer.value.url) return;

      let attribution = backgroundLayerAttribution.value && backgroundLayerAttribution.value.length 
          ? backgroundLayerAttribution.value 
          : '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors';

      let url =  backgroundLayerUrl.value && backgroundLayerUrl.value.length
          ?  backgroundLayerUrl.value
          : 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';
      
      //если в адресе только http/https+DNS-name+возможный /, то добавляем в хвост {z}/{x}/{y}.png 
      if(url.match(/(https|http):\/\/[^/]+\.(\w{2,6})\/?(?!.+)/ig))
        url = url + (!url.endsWith('/') ? '/' : '') + '{z}/{x}/{y}.png'; 

      const options = {
        updateWhenZooming: false,
        attribution: attribution,
        zIndex: 0,
        maxZoom: map.value.options.maxZoom,
        subdomains: "abc",
      };

      currentLayer = leaflet.value.tileLayer(
         url,
          options
      );
      currentLayer.addTo(map.value);
    }

    onMounted(() => {
      addLayer();
    });

    watch(backgroundLayerUrl, () => {
      addLayer();
    });
    return () => {
    };
  },
};
</script>
