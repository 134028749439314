<template>
  <div>
    <FileUploadRenderless
      v-slot="{ onChange, onDrop }"
      @startUpload="$emit('startUpload', $event)"
      @endUpload="$emit('endUpload', $event)"
      @failUpload="$emit('failUpload', $event)"
      :isGeometryFiles = true
    >
      <div
        @drop="onDrop"
        @dragenter="preventDefault"
        @dragover="preventDefault"
        @dragleave="preventDefault"
        class="width_full"
      >
        <div class="files-loading">
          <p class="files-loading__text">{{ headerMessage }} <a href="#"
            @click="onUploadClick">{{ linkMessage }}</a></p>
          <input
            class="files-loading__input"
            ref="uploadInput"
            type="file"
            id="items"
            multiple
            @change="onChange"
            :accept="acceptingFilesTypes"
            data-qa="fileUploadInput"
          />
        </div>
      </div>
    </FileUploadRenderless>
  </div>
</template>

<script>
import FileUploadRenderless from "./FileUploadRenderless";

export default {
  props: {
    headerMessage: {
      type: String,
      default: "Перетащите файлы в область или ",
    },
    linkMessage: {
      type: String,
      default: "выберите из папки",
    },
    acceptingFilesTypes: {
      type: String,
      default: "*"
    }
  },
  components: {
    FileUploadRenderless,
  },
  emits: ["startUpload", "endUpload", "failUpload"],
  methods:
  {
    preventDefault(event) {
      event.preventDefault();
      event.stopPropagation();
    },
    onUploadClick() {
      this.$refs.uploadInput.value=null;
      this.$refs.uploadInput.click();
    }
  },
};
</script>
<style scoped>
.width_full {
  width: 100%;
}

.files-loading {
  position: relative;
  border: 1px dashed var(--basic-grey-dark);
  border-radius: 6px;
  padding: 12px 44px;
  text-align: center;
  background-color: var(--basic-grey-lighter);
}

.files-loading_blocked {
  background-color: var(--basic-grey-slightly-lighter);
  border: 0px;
}

.files-loading::after {
  content: "";
  position: absolute;
  width: 30px;
  height: 30px;
  background-image: url("../../../../assets/svg/file-min.svg");
  background-repeat: no-repeat;
  background-position: 0 0;
  left: calc(15% - 5px);
  top: 22px;
}

.files-loading__text {
  font-size: 16px;
  line-height: 20px;
  color: var(--secondary-blue-darker);
}

.files-loading__input {
  position: absolute;
  width: 0;
  height: 0;
}
</style>
