import {helpers} from "@vuelidate/validators";

function isPerson(subject) {
	return subject.type && subject.type.toLowerCase() == 'person';
}


function isOrganization(subject) {
	return subject.type && subject.type.toLowerCase() == 'organization';
}

function isEnterpreneur(subject) {
	return subject.type && subject.type.toLowerCase() == 'enterpreneur';
}

function stringIsNotEmptyOrWhitespace(value){
	return !!value && value.trim().length > 0
}

export default {
	hasName: helpers.withMessage(
		"У выбранной организации не заполнено поле [Наименование организации]",
		(value) => {
			//возвращаем true ибо это не проверка required. данная лишь дополняет её. и если субъекта нет - это не ошибка т.к. он может быть необязателен
			if (!value)
				return true;
			if (!isOrganization(value))
				return true;

			return stringIsNotEmptyOrWhitespace(value.name);
		}
	),
	hasInnOrg: helpers.withMessage(
		"У выбранной организации не заполнено поле [ИНН]",
		(value) => {
			//возвращаем true ибо это не проверка required. данная лишь дополняет её. и если субъекта нет - это не ошибка т.к. он может быть необязателен
			if (!value)
				return true;
			if (!isOrganization(value))
				return true;

			return stringIsNotEmptyOrWhitespace(value.inn);
		}
	),
	hasOgrn: helpers.withMessage(
		"У выбранной организации не заполнено поле [ОГРН]",
		(value) => {
			//возвращаем true ибо это не проверка required. данная лишь дополняет её. и если субъекта нет - это не ошибка т.к. он может быть необязателен
			if (!value)
				return true;
			if (!isOrganization(value))
				return true;

			return stringIsNotEmptyOrWhitespace(value.ogrn);
		}
	),

	hasFirstName: helpers.withMessage(
		"У выбранного физ.лица не заполнено поле [Имя]",
		(value) => {
			//возвращаем true ибо это не проверка required. данная лишь дополняет её. и если субъекта нет - это не ошибка т.к. он может быть необязателен
			if (!value)
				return true;
			if (!isPerson(value))
				return true;

			return stringIsNotEmptyOrWhitespace(value.firstName);
		}
	),

	hasLastName: helpers.withMessage(
		"У выбранного физ.лица не заполнено поле [Фамилия]",
		(value) => {
			//возвращаем true ибо это не проверка required. данная лишь дополняет её. и если субъекта нет - это не ошибка т.к. он может быть необязателен
			if (!value)
				return true;
			if (!isPerson(value))
				return true;

			return stringIsNotEmptyOrWhitespace(value.lastName);
		}
	),

	hasEnterpreneurFullName: helpers.withMessage(
		"У выбранного индивидуального предпринимателя не заполнено поле [Полное наименование ИП]",
		(value) => {
			//возвращаем true ибо это не проверка required. данная лишь дополняет её. и если субъекта нет - это не ошибка т.к. он может быть необязателен
			if (!value)
				return true;
			if (!isEnterpreneur(value))
				return true;

			return stringIsNotEmptyOrWhitespace(value.enterpreneurFullName);
		}
	),
	
	hasOgrnIp: helpers.withMessage(
		"У выбранного индивидуального предпринимателя не заполнено поле [ОГРНИП]",
		(value) => {
			//возвращаем true ибо это не проверка required. данная лишь дополняет её. и если субъекта нет - это не ошибка т.к. он может быть необязателен
			if (!value)
				return true;
			if (!isEnterpreneur(value))
				return true;

			return stringIsNotEmptyOrWhitespace(value.ogrnip);
		}
	),

	hasEnterpreneurFirstName: helpers.withMessage(
		"У выбранного индивидуального предпринимателя не заполнено поле [Имя]",
		(value) => {
			//возвращаем true ибо это не проверка required. данная лишь дополняет её. и если субъекта нет - это не ошибка т.к. он может быть необязателен
			if (!value)
				return true;
			if (!isEnterpreneur(value))
				return isEnterpreneur;

			return stringIsNotEmptyOrWhitespace(value.firstName);
		}
	),

	hasEnterpreneurLastName: helpers.withMessage(
		"У выбранного индивидуального предпринимателя не заполнено поле [Фамилия]",
		(value) => {
			//возвращаем true ибо это не проверка required. данная лишь дополняет её. и если субъекта нет - это не ошибка т.к. он может быть необязателен
			if (!value)
				return true;
			if (!isEnterpreneur(value))
				return true;

			return stringIsNotEmptyOrWhitespace(value.lastName);
		}
	),
}
