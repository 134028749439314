<template>
  <div class="page" v-if="!isLoading" data-qa="inboxDataPage">
    <page-header class="container">
      <template #title>
        Запись реестра учета сведений №{{ inboxData.number }}
      </template>
      <template #subtitle>
        <ToReestersLink>Реестр учета сведений</ToReestersLink>
      </template>
      <template #buttons>
        <InboxdataCompleteButton
          v-if="!isFinalStatus && haveEditRight"
          :inboxdata="inboxData"
          @finalized="onFinalized"
        ></InboxdataCompleteButton>
        <InboxdataProcessButton
          v-if="!isFinalStatus && haveEditRight"
          :number="inboxData.number"
          data-qa="goToPlacement"
          >{{ textProccessButton }}
        </InboxdataProcessButton>
      </template>
    </page-header>
    <div class="box container">
      <form class="form form--fullwidth grid3on2" @submit.prevent>
        <div>
          <Status
            :status="inboxData.status.displayName"
            :process-status="inboxData.processStatus"
            :mini="false"
            class="inboxdata__status"
          ></Status>
          <fieldset class="inboxdata__group">
            <legend class="title-20">Основные свойства</legend>
            <div class="uneditable-field">
              <label class="uneditable-field__label label"
                >Дата поступления на размещение</label
              >
              <span class="uneditable-field__value">{{
                $filters.toDateString(inboxData.requestDate)
              }}</span>
            </div>
            <div class="uneditable-field">
              <label class="uneditable-field__label label"
                >Исходящий номер сопроводительного письма</label
              >
              <span class="uneditable-field__value">{{
                $filters.empty(inboxData.outgoingNumber)
              }}</span>
            </div>
            <div class="uneditable-field">
              <label class="uneditable-field__label label"
                >Исходящая дата сопроводительного письма</label
              >
              <span class="uneditable-field__value">
                {{
                  $filters.empty($filters.toDateString(inboxData.outgoingDate))
                }}
              </span>
            </div>
            <div class="uneditable-field">
              <label class="uneditable-field__label label"
                >Способ направления данных</label
              >
              <span class="uneditable-field__value">{{
                inboxData.wayOfReceival?.displayName
              }}</span>
            </div>
            <div class="uneditable-field">
              <label class="uneditable-field__label label"
                >Наименование ИС, из которой переданы данные</label
              >
              <span class="uneditable-field__value">{{
                $filters.empty(inboxData.nameOfSenderSystem)
              }}</span>
            </div>
            <div class="uneditable-field">
              <label class="uneditable-field__label label">Форма данных</label>
              <span class="uneditable-field__value">{{
                inboxData.dataFormat?.displayName
              }}</span>
            </div>
            <div class="uneditable-field">
              <SubjectTextField :modelValue="inboxData.requestSender">
                <template #label>
                  <label
                    class="label"
                    for="registration-request-person-who-sent"
                  >
                    Лицо, направившее сведения
                  </label>
                </template>
              </SubjectTextField>
            </div>
            <div class="uneditable-field">
              <label class="uneditable-field__label label"
                >Лицо, обработавшее запрос</label
              >
              <span class="uneditable-field__value">
                {{ $filters.empty(inboxData.userCreatorName) }}</span
              >
            </div>
            <div class="uneditable-field" v-if="inboxData.rejectionReason">
              <label class="uneditable-field__label label"
                >Причина отказа</label
              >
              <span class="uneditable-field__value">{{
                $filters.empty(inboxData.rejectionReason)
              }}</span>
            </div>
          </fieldset>
          <fieldset
            class="inboxdata__group"
            v-if="inboxData.registeredDocuments?.length > 0"
          >
            <AttachedDocumentList :documents="inboxData.registeredDocuments">
            </AttachedDocumentList>
          </fieldset>
        </div>
        <fieldset class="form__fieldset">
          <legend class="title-20">Прикрепленные файлы</legend>
          <FilesField
            :files="inboxData.files"
            :readonly="true"
            class="form-control"
            v-if="inboxData.files.length!==0"
          ></FilesField>
          <FileUpload v-else :header-blocked-message="'Файлы не прикреплены'" :is-blocked="true"/>
        </fieldset>
      </form>
    </div>
  </div>
</template>
<script>
import useInboxData from "../../hooks/inboxData";
import { useRoute } from "vue-router";
import { computed, ref } from "vue";
import ToReestersLink from "../basic/ToReestersLink";
import InboxdataProcessButton from "./InboxDataProcessButton1";
import InboxdataCompleteButton from "./InboxDataCompleteButton1";
import AttachedDocumentList from "../basic/AttachedDocumentList.vue";
import FilesField from "../basic/form/files/FilesField";
import Status from "./statuses/InboxDataStatus";
import SubjectTextField from "../basic/form/SubjectTextField.vue";
import useAuthorization from "../../authorization";
import FileUpload from "@/components/basic/files/fileUpload/FileUpload.vue";

export default {
  components: {
    FileUpload,
    ToReestersLink,
    InboxdataProcessButton,
    InboxdataCompleteButton,
    AttachedDocumentList,
    FilesField,
    Status,
    SubjectTextField,
  },
  setup() {
    const route = useRoute();
    const inboxdataNumber = ref(route.params.number);
    const { isLoading, inboxData, fetch } = useInboxData(inboxdataNumber);

    const { canEdit } = useAuthorization();
    const haveEditRight = canEdit();

    function onFinalized() {
      fetch(inboxData.value.number);
    }

    const isFinalStatus = computed(() => {
      return inboxData.value.status?.id !== 1;
    });

    const textProccessButton = computed(() => {
      return isFinalStatus.value ? "Подробнее" : "К процессу размещения";
    });

    return {
      isLoading,
      inboxData,
      onFinalized,
      isFinalStatus,
      textProccessButton,
      haveEditRight,
    };
  },
};
</script>
<style scoped>
.inboxdata__group {
  border: none;
  margin: 0;
  padding: 0;
}

.inboxdata__status {
  margin-bottom: 36px;
}
</style>
