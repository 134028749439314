<template>
  <TabSpinner v-if="!document || !document.fields"/>
  <form class="form form--fullwidth grid3on2" v-if="document && document.fields" @submit.prevent>
    <fieldset class="section-document__group">
      <legend class="title-20">Основные свойства</legend>
      <div
          v-for="(field, idx) in document.fields"
          class="uneditable-field"
          :key="idx"
      >
        <template v-if="field.type == '1' && field.value">
          <label class="uneditable-field__label label">{{
              field.labelName
            }}</label>

          <span v-if="!field.value.length" class="uneditable-field__value">{{ $filters.empty(null) }}</span>
          <div v-else v-for="(value, index) in field.value" :key="index">
            <span class="uneditable-field__value">{{
                value
              }}</span>
          </div>
        </template>
        <template v-else-if="field.type == '2' && field.value">
          <label class="uneditable-field__label label">{{
              field.labelName
            }}</label>

          <span v-if="!field.value.length" class="uneditable-field__value">{{ $filters.empty(null) }}</span>
          <div v-else v-for="(value, index) in field.value" :key="index">
            <router-link
                :to="{
                name: `sectionDocument`,
                params: { number: value.registrationNumber },
              }"
            >{{ value.name }} 
            </router-link
            >
          </div>
        </template>
        <template v-else-if="field.type == '3' && field.value">
          <label class="uneditable-field__label label">
            {{ field.labelName }}
          </label>
          <SubjectTextField :modelValue="field.value">
          </SubjectTextField>
        </template>


        <template v-else-if="field.type == '4' && field.value">
          <label class="uneditable-field__label label">
            {{ field.labelName }}
          </label>
          <span v-if="!field.value.length" class="uneditable-field__value">{{ $filters.empty(null) }}</span>
          <div v-else v-for="(value, index) in field.value" :key="index">
            <SubjectTextField :modelValue="value">
          </SubjectTextField>
          </div>
        </template>

        
        <template v-else disabled>
          <label class="uneditable-field__label label">{{
              field.labelName
            }}</label>
          <span class="uneditable-field__value">{{
              $filters.empty(field.value)
            }}</span>
        </template>
      </div>
      <div class="uneditable-field">
        <template v-if="document.inboxDataNumber">
          <label class="uneditable-field__label label">
            Запись реестра учета
          </label>
          <a href="#" @click="moveToInboxData">{{ $filters.empty(document.inboxDataNumber.name) }}</a>
        </template>
      </div>
      <div class="uneditable-field">
        <label class="uneditable-field__label label">
          Территория действия
        </label>
        <Map
            v-if="document.geometry"
            class="map"
            :geo-json="document.geometry"
        ></Map>
      </div>
    </fieldset>
    <section class="section-with-files">
      <fieldset
          class="form__fieldset"
          v-if="document.territorialPlanningRegulationFiles? document.territorialPlanningRegulationFiles.length!==0 : null"
      >
        <legend class="title-20">
          Положение о территориальном планировании
        </legend>
        <FilesField
            :files="document.territorialPlanningRegulationFiles"
            class="form-control"
            :readonly="true"

        ></FilesField>
      </fieldset>
      <fieldset class="form__fieldset">
        <legend class="title-20">Прикрепленные файлы</legend>
        <FilesField
            :files="document.files"
            class="form-control"
            :readonly="true"
            v-if="document.files.length!==0"
        ></FilesField>
        <FileUpload v-else :header-blocked-message="'Файлы не прикреплены'" :is-blocked="true"/>
      </fieldset>
    </section>
  </form>
</template>
<script>
import FilesField from '../basic/form/files/FilesField';
import SubjectTextField from '../basic/form/SubjectTextField.vue';
import Map from "../map/TheMap.vue";
import TabSpinner from "../basic/tabs/TabSpinner";
import FileUpload from "@/components/basic/files/fileUpload/FileUpload.vue";
import useProjectAlias from "@/hooks/projectAlias";
import { useRouter } from "vue-router";

export default {
  props: {
    document: {
      type: Object,
      requared: true,
    },
  },
  components: {
    FileUpload,
    FilesField,
    SubjectTextField,
    Map,
    TabSpinner
  },
  setup(props) {
    const { setProjectByDocument } = useProjectAlias();
    const router = useRouter();

    function moveToInboxData() {
      const regNum = props.document.fields.filter(x => x.labelName == "Регистрационный номер документа")[0].value;

      setProjectByDocument(regNum).then(() => {
        router.push({
        name: "inboxData",
        params: { number: props.document.inboxDataNumber.number },
        });
      })
    }

    return {
      moveToInboxData
    }
  }
};

</script>
<style scoped>
.section-document__group {
  border: none;
  margin: 0;
  padding: 0;
}

.section-with-files {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
</style>
