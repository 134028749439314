<template>
  <div class="duplicate__card">
    <div class="">
      <div class="duplicate__general-info">
        <div class="duplicate__display-name">
          {{ chosenSubject.displayName }}
        </div>
        <button
          @click="showSubjectDuplicateInfo()"
          class="button button-icon duplicate-button"
        >
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx="10"
              cy="10"
              r="7.5"
              stroke="#90A0B7"
              stroke-width="1.4"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M10 10V13.3333"
              stroke="#90A0B7"
              stroke-width="1.4"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M10 6.66675H10.0083"
              stroke="#90A0B7"
              stroke-width="1.4"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </button>
      </div>
      <div class="duplicate__description">
        {{ formatSubjectInfo(chosenSubject) }}
      </div>
    </div>

    <OnClickOutside :do="closeSubjectDuplicateInfo">
      <div class="tooltip">
        <div
          class="subject__info-tooltip"
          v-if="isSubjectInfoTooltipActive"
          id="subject-info"
        >
          <div class="subject__info-block" v-if="chosenSubjectIsPerson">
            <div class="subjet__info-item">
              <div class="subject__info-item_name">Телефон:</div>
              <div class="subject__info-item_value">
                {{ chosenSubject.phone }}
              </div>
            </div>
            <div class="subjet__info-item">
              <div class="subject__info-item_name">Эл.почта:</div>
              <div class="subject__info-item_value">
                {{ chosenSubject.email }}
              </div>
            </div>
            <div class="subjet__info-item">
              <div class="subject__info-item_name">Паспорт:</div>
              <div class="subject__info-item_value">
                {{
                  chosenSubject.passportSeries +
                  " " +
                  chosenSubject.passportNumber
                }}
              </div>
            </div>
            <div class="subjet__info-item">
              <div class="subject__info-item_name">ИНН:</div>
              <div class="subject__info-item_value">
                {{ chosenSubject.inn }}
              </div>
            </div>
            <div class="subjet__info-item">
              <div class="subject__info-item_name">ОГРНИП:</div>
              <div class="subject__info-item_value">
                {{ chosenSubject.ogrnip }}
              </div>
            </div>
            <div class="subjet__info-item">
              <div class="subject__info-item_name">СНИЛС:</div>
              <div class="subject__info-item_value">
                {{ chosenSubject.snils }}
              </div>
            </div>
          </div>
          <div class="subject__info-block" v-else>
            <div class="subjet__info-item">
              <div class="subject__info-item_name">Форма организации:</div>
              <div class="subject__info-item_value">
                {{ chosenSubject.organizationalForm }}
              </div>
            </div>
            <div class="subjet__info-item">
              <div class="subject__info-item_name">Телефон:</div>
              <div class="subject__info-item_value">
                {{ chosenSubject.phone }}
              </div>
            </div>
            <div class="subjet__info-item">
              <div class="subject__info-item_name">Эл.почта:</div>
              <div class="subject__info-item_value">
                {{ chosenSubject.email }}
              </div>
            </div>
            <div class="subjet__info-item">
              <div class="subject__info-item_name">ИНН:</div>
              <div class="subject__info-item_value">
                {{ chosenSubject.inn }}
              </div>
            </div>
            <div class="subjet__info-item">
              <div class="subject__info-item_name">ОГРН:</div>
              <div class="subject__info-item_value">
                {{ chosenSubject.ogrn }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </OnClickOutside>
  </div>
</template>

<script>
import OnClickOutside from "../../basic/OnClickOutside.vue";
import { ref } from "vue";

export default {
  components: {
    OnClickOutside,
  },
  props: {
    chosenSubject: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const isSubjectInfoTooltipActive = ref(false);
    const chosenSubjectIsPerson = ref(true);

    function showSubjectDuplicateInfo() {
      if (props.chosenSubject.type === "Person")
        chosenSubjectIsPerson.value = true;
      else chosenSubjectIsPerson.value = false;

      setTimeout(() => {
        isSubjectInfoTooltipActive.value = true;
      }, 20);
    }

    function formatSubjectInfo(subject) {
      let result = [];

      if (subject.type === "Person") {
        if (subject.passportSeries && subject.passportNumber)
          result.push(
            "Паспорт: " + subject.passportSeries + " " + subject.passportNumber
          );

        if (subject.inn) result.push("ИНН: " + subject.inn);

        if (subject.ogrnip) result.push("ОГРНИП: " + subject.ogrnip);
      } else {
        if (subject.inn) result.push("ИНН: " + subject.inn);

        if (subject.ogrn) result.push("ОГРН: " + subject.ogrn);
      }

      return result.join(", ");
    }

    function closeSubjectDuplicateInfo() {
      isSubjectInfoTooltipActive.value = false;
    }

    return {
      chosenSubjectIsPerson,
      isSubjectInfoTooltipActive,
      showSubjectDuplicateInfo,
      formatSubjectInfo,
      closeSubjectDuplicateInfo,
    };
  },
};
</script>

<style scoped>
.tooltip {
    margin-left: 50px;
}

.duplicate__card {
  display: flex;
  align-content: space-between;
}

.duplicate__display-name {
  font-size: 16px;
  font-weight: 400;
  font-family: "Source Sans Pro";
  color: var(--basic-black);
}

.duplicate__description {
  font-size: 14px;
  font-weight: 400;
  font-family: "Source Sans Pro";
  color: #90a0b7;
}

.duplicate-button {
  padding: 0;
  height: 0;
  margin-left: 15px;
  padding-top: 20px;
}

.duplicate__general-info {
  display: flex;
}

.duplicates__info {
  width: 550px;
  font-family: "Source Sans Pro";
  font-weight: 400;
  font-size: 16px;
  color: var(--basic-black);
}

.subject__info-tooltip {
  position: absolute;
  width: 350px;
  height: 240px;
  background-color: white;
  box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.14);
  border-radius: 6px;
  z-index: 20;
  min-width: 250px;
  max-width: 350px;
  margin-top: -25px;
}

.subject__info-block {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  padding: 20px;
}

.subjet__info-item {
  margin-bottom: 12px;
  display: flex;
}

.subject__info-item_name {
  margin-right: 12px;
  width: 65px;
}
</style>