import instance from '../api.js';

class GeometaProjectProxy {
  static async fetch() {
    return instance.get(`projects`, { noProjectContext: true, noAuth: false });
  }

  static async getDocumentProject(regNum) {
    return instance.get(`projects/` + regNum, { noProjectContext: true, noAuth: false });
  }
}

export default GeometaProjectProxy;
