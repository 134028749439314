import instance from "../api.js";
import RegistrationRequest from "../models/registrationRequest";
import { CreateRegistrationRequestDto, UpdateRegistrationRequestDto } from "../dtos/registrationRequestDto";
import { StorageFileDto } from "../dtos/storageFileDto";

class RegistrationRequestProxy {
  static async create(payload) {
    const files = payload.files.map(
      (f) => new StorageFileDto(f.id, f.name, f.fileUrl, f.contentType, f.size)
    );
    const dto = new CreateRegistrationRequestDto(
      payload.number,
      payload.requestDate,
      payload.outgoingNumber,
      payload.outgoingDate,
      payload.nameOfSenderSystem,
      payload.requestSender,
      payload.wayOfReceival,
      payload.dataFormat,
      files
    );
    const rrDto= await instance.put(`registration-request`, dto);
    return RegistrationRequest.fromDto(rrDto);
  }
  //use
  static update(payload) {
    const files = payload.files.map(
      (f) => new StorageFileDto(f.id, f.name, f.fileUrl, f.contentType, f.size)
    );
    const dto = new UpdateRegistrationRequestDto(
      payload.id,
      payload.number,
      payload.requestDate,
      payload.outgoingNumber,
      payload.outgoingDate,
      payload.nameOfSenderSystem,
      payload.requestSender,
      payload.wayOfReceival,
      payload.dataFormat,
      files
    );
    return instance.patch(`registration-request`, dto);
  }
  //use
  static fetch(number) {
    return instance
    .get(`registration-request/${number}`)
    .then((dto) => {
      let r = RegistrationRequest.fromDto(dto);
      return r;
    })
  }

  //use
  static delete(number) {
    return instance.delete(`registration-request/${number}`);
  }
}

export default RegistrationRequestProxy;
