<template>
  <div class="page" v-if="state.document && state.document.sectionDocument">
    <page-header class="container">
      <template #title>
        <div class="title">{{ state.document.sectionDocument.fullname }}</div>
        <signature-info
          class="signatureInfo"
          v-if="state.document.sectionDocument.fullname && state.signatureInfo"
          v-model="state.signatureInfo"
        ></signature-info>
      </template>
      <template #subtitle>
        <div class="correction-alert">
          <div class="correction-alert__icon"></div>
          <div class="correction-alert__text">
            данная редакция актуальна на
            {{ formatDate(state.document.correctionDate) }}
          </div>
        </div>
      </template>
    </page-header>
    <div class="box container">
      <DocumentProperties
        :document="state.document.sectionDocument"
      ></DocumentProperties>
    </div>
  </div>
</template>
<script>
import DocumentProperties from "./DocumentProperties.vue";
import { ref } from "vue";
import { useRoute, onBeforeRouteUpdate } from "vue-router";
import useCorrectionHistory from "./hooks/correctionHistory";
import SignatureInfo from "./SignatureInfo.vue";
import useDocumentSignatureInfo from "./hooks/documentSignature";

export default {
  components: {
    DocumentProperties,
    SignatureInfo,
  },
  setup() {
    const route = useRoute();
    const documentNumber = route.params.number;
    const sectionNumber = GetSectionFromRegNumber(documentNumber);
    const correctionId = route.params.correctionId;
    const state = ref({ document: null, signatureInfo: null });
    const { getDocumentWithCorrection } = useCorrectionHistory(
      documentNumber,
      sectionNumber
    );
    const {
      GetSignatureInfoByTechnicalCorrectionId,
    } = useDocumentSignatureInfo(documentNumber);
    getDocumentWithCorrection(sectionNumber, documentNumber, correctionId).then(
      (result) => {
        state.value.document = result;
      }
    );
    GetSignatureInfoByTechnicalCorrectionId(sectionNumber, correctionId).then(
      (result) => {
        state.value.signatureInfo = result;
      }
    );

    onBeforeRouteUpdate(async (to, from) => {
      if (to.params.number !== from.params.number) {
        getDocumentWithCorrection(
          sectionNumber,
          documentNumber,
          correctionId
        ).then((result) => {
          state.value.document = result;
        });
      }
    });

    function GetSectionFromRegNumber(number) {
      return new RegExp(/([^-]*)-([^-]*)-([^-]*)-([^-]*)/).exec(number)[2];
    }

    function formatDate(date) {
      if (!date) return "";

      return `${date
        .getDate()
        .toString()
        .padStart(2, "0")}.${(date.getMonth() + 1)
        .toString()
        .padStart(2, "0")}.${date.getFullYear()}`;
    }

    return {
      state,
      correctionId,
      formatDate,
    };
  },
};
</script>
<style scoped>
.correction-alert {
  display: flex;
}

.correction-alert__icon {
  background-image: url("../../assets/svg/triangle-alert.svg");
  width: 23px;
  height: 23px;
  background-repeat: no-repeat;
  background-position: center;
  margin-right: 12px;
}

.correction-alert__text {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 23px;
  letter-spacing: 0.02em;
  color: #ffaa1f;
}

.title,
.signatureInfo {
  display: inline;
  top: 5px;
}
</style>
