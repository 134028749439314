<template>
  <div class="navbar">
    <TheProjectSelect></TheProjectSelect>
    <div class="navbar__menu">
      <!-- <a
        class="navbar__menu-item navbar__menu-item--to-the-main-application button button--secondary button--left-icon"
        href=""
        >В основное приложение</a
      > -->
      <a
          class="navbar__menu-item navbar__menu-item--help menu-button"
          :href="$consts.userManualUrl"
          aria-label="Помощь"
          :title="'Справка'"
          target="_blank"
          data-qa="help"
      >
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
        >
          <use xlink:href="#icon-question-mark"/>
        </svg>
      </a>
      <!-- <button
        @click="logout"
        class="navbar__menu-item navbar__menu-item--user menu-button"
        aria-label="Личный кабинет"
        title="Личный кабинет"
      >
        <svg
          width="36"
          height="36"
          viewBox="0 0 36 36"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <use xlink:href="#icon-account" />
        </svg>
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <use xlink:href="#icon-chevron-down" />
        </svg>
      </button> -->
      <LogoutMenu></LogoutMenu>
      <the-navbar-svg-sprite/>
    </div>
  </div>
</template>

<script>
import TheNavbarSvgSprite from './TheNavbarSvgSprite';
import TheProjectSelect from './TheProjectSelect';
import LogoutMenu from './LogoutMenu';
import useAuthorization from '../../authorization';

export default {
  components: {TheNavbarSvgSprite, TheProjectSelect, LogoutMenu},
  setup() {
    const {signOut} = useAuthorization();

    async function logout() {
      await signOut();
    }
    return {logout};
  }
};
</script>

<style scoped>
.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 40px;
  padding-right: 40px;
}

.navbar__menu {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.navbar__menu-item {
  margin-left: 10px;
}

.navbar__menu-item--to-the-main-application {
  position: relative;
}

.navbar__menu-item--to-the-main-application::before {
  content: '';
  position: absolute;
  width: 20px;
  height: 20px;
  background-image: url('../../assets/svg/arrow-go-back.svg');
  background-repeat: no-repeat;
  background-position: 0 0;
  left: 20px;
  top: calc(50% - 10px);
}

.menu-button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 44px;
  cursor: pointer;
}

.navbar__menu-item--help {
  width: 44px;
}

.navbar__menu-item--user {
  width: 56px;
  background-color: transparent;
  border: none;
  outline: none;
}

.menu-button svg {
  stroke: var(--basic-black);
}

.menu-button:hover svg {
  stroke: var(--secondary-blue-darker);
}
</style>
