import RegistrationProcessPage from '../components/RegistrationProcess/RegistrationProcessPage';
import RegistrationDocumentPage from '../components/RegistrationProcess/RegistrationDocumentPage';

const routes = [
  {
    path: 'registration-process/:number(\\w+)',
    name: 'registrationProcess',
    component: RegistrationProcessPage,
    meta: {
      requiresAuth: true,
      name: 'к процессу регистрации записи реестра № ',
    },
  },
  {
    path: 'registration-process/:number(\\w+)/document/create',
    name: 'registrationDocumentCreate',
    component: RegistrationDocumentPage,
    meta: {
      requiresAuth: true,
      isDocChange: false
    },
  },
  {
    path: 'registration-process/:number(\\w+)/document/createChange',
    name: 'registrationDocumentCreateChange',
    component: RegistrationDocumentPage,
    meta: {
      requiresAuth: true,
      isDocChange: true
    },
  },
  {
    path: 'registration-object/:number(\\w+)/document/:id(\\d+)',
    name: 'registrationDocumentEdit',
    component: RegistrationDocumentPage,
    meta: {
      requiresAuth: true,
    },
  },
];

export default routes;
