<template>
  <div class="geometry-buttons group">
    <button class="button button--primary group__item" @click="applyGeometryFn" data-qa="applyTerritory">
      Применить территорию
    </button>
    <button class="button button--light group__item" @click="removeGeometryFn" data-qa="resetTerritory">
      Сбросить территорию
    </button>
  </div>
</template>

<script>
export default {
  props: {
    applyGeometryFn: {
      type: Function,
      required: true,
    },
    removeGeometryFn: {
      type: Function,
      required: true,
    },
  },
};
</script>