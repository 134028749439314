<template>
  <svg style="display: none">
    <symbol viewBox="0 0 20 20" id="icon-question-mark">
        <path d="M8 7.5C8 7.10218 8.18426 6.72064 8.51225 6.43934C8.84025 6.15804 9.2851 6 9.74895 6H10.2486C10.7125 6 11.1573 6.15804 11.4853 6.43934C11.8133 6.72064 11.9976 7.10218 11.9976 7.5C12.016 7.82463 11.9285 8.14645 11.7482 8.41699C11.568 8.68753 11.3047 8.89214 10.9982 9C10.6916 9.14382 10.4284 9.41663 10.2482 9.77735C10.0679 10.1381 9.9804 10.5672 9.9988 11" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M10 14V14.0083" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
        <circle cx="10" cy="10" r="7.5" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
    </symbol>
    <symbol viewBox="0 0 36 36" id="icon-account">
      <circle cx="18" cy="17.25" r="5.25" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M31.5 18C31.5 21.6585 30.0447 24.9771 27.6814 27.4085C25.2285 29.9322 21.7973 31.5 18 31.5C14.2027 31.5 10.7715 29.9322 8.31856 27.4085C5.95529 24.9771 4.5 21.6585 4.5 18C4.5 10.5442 10.5442 4.5 18 4.5C25.4558 4.5 31.5 10.5442 31.5 18Z" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M8.3186 27.4085C10.5024 24.4322 14.0256 22.5 18 22.5C21.9745 22.5 25.4977 24.4322 27.6815 27.4085" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
    </symbol>
    <symbol viewBox="0 0 16 16" id="icon-logout">
      <path d="M3.33334 3.33333V2H12.6667V14H3.33334V12.6667" stroke="#90A0B7" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M3.33334 6L1.33334 8L3.33334 10" stroke="#90A0B7" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M8.00001 8H1.33334" stroke="#90A0B7" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
    </symbol>
    <symbol viewBox="0 0 16 16" id="icon-chevron-down">
      <g id="search">
       <path d="M4 6L8 10L12 6" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
      </g>
    </symbol>
  </svg>
</template>