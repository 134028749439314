export class StorageFileDto {
  constructor(id, name, fileUrl, contentType, size) {
    this.id = id;
    this.fileName = name;
    this.fileStorageUrl = fileUrl;
    this.contentType = contentType;
    this.size=size;
  }
}

export class GeometryFileDto {
  constructor(name, fileUrl) {
    this.fileName = name;
    this.fileStorageUrl = fileUrl;
  }
}
