import auth from './auth';
import support from './support';
import registrationProcessRouters from './registrationProcess';
import registrationRequestRouters from './registrationRequest';
import fixErrorsRouters from './fixErrors';
import inboxDataRouters from './inboxData';
import gisogdDocumentsRouters from './gisogdDocuments';
import reesters from './reesters';

import NotFoundPage from '../components/NotFoundPage';
import HomePage from '../components/HomePage';
import ProjectPage from '../components/ProjectPage';
import useAuthorization from '../authorization';
import useProjectAlias from '../hooks/projectAlias';
import { createWebHistory, createRouter } from 'vue-router';

const routes = [
  ...auth,
  ...support,
  {
    path: '/',
    name: 'home',
    component: HomePage,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/:projectAlias',
    name: 'project',
    component: ProjectPage,
    meta: {
      requiresAuth: true,
    },
    children: [
      ...registrationRequestRouters,
      ...registrationProcessRouters,
      ...inboxDataRouters,
      ...gisogdDocumentsRouters,
      ...reesters,
      ...fixErrorsRouters
    ],
  },
  {
    path: '/404',
    component: NotFoundPage,
    meta: {
      requiresAuth: false,
    },
  },

  {
    path: '/:catchAll(.*)',
    component: NotFoundPage,
    meta: {
      requiresAuth: false,
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    document.getElementById('page-layout').scrollTo(0, 0);
  },
});

const { getUser } = useAuthorization();

// handle auth
router.beforeEach(async (to) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  if (requiresAuth) {
    const user = await getUser();
    if (user) return true;
    else return false;
  } else {
    return true;
  }
});

//handle projects
router.beforeEach(async (to) => {
  if (to.meta.requiresAuth){
    const { getGeometaProjects } = useProjectAlias();
    const projects = await getGeometaProjects();
    if (to.name === 'noprojects') {
      if (projects.value.length === 0) return true;
      else return { name: 'home' };
    }
    if (projects.length === 0) {
      return { name: 'noprojects' };
    }
  }
});


export default router;
