export class EditableDocumentDto {
  constructor(
    fullname,
    registrationNumber,
    registrationDate,
    createDate,
    stateSnapshot,
    geometrySource,
    documentType,
    files,
    territorialPlanningRegulationFiles
  ) {
    (this.fullname = fullname),
      (this.registrationNumber = registrationNumber),
      (this.registrationDate = registrationDate),
      (this.createDate = createDate),
      (this.stateSnapshot = stateSnapshot),
      (this.geometrySource = geometrySource),
      (this.documentType = documentType),
      (this.files = files),
      (this.territorialPlanningRegulationFiles = territorialPlanningRegulationFiles);
  }
}

export class RegistrationProcessItemDto {
  constructor(
    documentTypeId,
    files,
    territorialPlanningRegulationFiles,
    stateSnapshot,
    geometrySourceInfo,
    geometry
  ) {
    this.documentTypeId = documentTypeId;
    this.files = files;
    this.territorialPlanningRegulationFiles = territorialPlanningRegulationFiles;
    this.stateSnapshot = stateSnapshot;
    this.geometrySourceInfo = geometrySourceInfo;
    this.geometry = geometry;
  }
}

export class SignRegistrationProcessItemDto extends RegistrationProcessItemDto {
  constructor(
    documentTypeId,
    files,
    territorialPlanningRegulationFiles,
    stateSnapshot,
    geometrySourceInfo,
    geometry,
    date,
    documentJson,
    signatureValue,
    signatureFN
  ) {
    super(
      documentTypeId,
      files,
      territorialPlanningRegulationFiles,
      stateSnapshot,
      geometrySourceInfo,
      geometry
    );
    this.date = date;
    this.documentJson = documentJson;
    this.signatureValue = signatureValue;
    this.signatureFN = signatureFN;
  }
}
