<template>
  <teleport to="#modal-container">
    <dialog class="modal" open v-if="visible">
      <div class="modal__card" :class="wide ? 'wide' : sign ? 'sign' : ''">
        <header class="modal__header">
          <p class="modal__title title-20"><slot name="title"></slot></p>
          <button
            class="button button-icon button--light button--small modal__close"
            @click="$emit('close')"
            aria-label="Закрыть"
          >
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 15L15 1M15 15L1 1"
                stroke="#465362"
                stroke-width="1.4"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </button>
        </header>
        <section class="modal__content">
          <slot></slot>
        </section>
        <footer class="modal__footer">
          <slot name="footer"></slot>
        </footer>
      </div>
    </dialog>
  </teleport>
</template>

<script>
export default {
  emits: ["close"],
  props: {
    visible: Boolean,
    wide: {
      type: Boolean,
      default: false,
    },
    sign: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped lang="scss">
.modal {
  position: fixed;
  display: flex;
  z-index: 50;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: rgba(70, 83, 98, 0.4);
  border: none;
}

.modal__card {
  display: grid;
  grid-template-rows: min-content 1fr min-content;
  align-content: start;
  gap: 15px;
  max-height: 100%;
  min-height: 310px;
  width: 628px;
  margin: auto;
  padding: 36px;
  padding-right: 30px;
  border-radius: 6px;
  background-color: var(--basic-white);
}

.modal__header {
  position: relative;
  display: flex;
  flex-shrink: 0;
  justify-content: flex-start;
  align-items: center;
  padding-right: 6px;
}

.modal__title {
  flex-grow: 1;
  flex-shrink: 0;
}

.modal__content {
  overflow: auto;
  padding-right: 6px;
  margin-right: 0;
}

.modal__footer {
  position: relative;
  display: flex;
  justify-content: flex-end;
  padding-right: 6px;
  padding-top: 15px;
}

.wide {
  width: 50%;
  height: 50%;
}

.sign {
  width: 550px;
  min-height: 374px;
  max-height: 518px;
}
</style>
