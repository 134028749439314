import InboxDataPage from '../components/InboxData/InboxDataPage1.vue';
const routes = [
  {
    path: 'inboxdata/:number(\\d+)',
    name: 'inboxData',
    component: InboxDataPage,
    props: true,
    meta: {
      requiresAuth: true,
      name: 'к записи реестра № ',
    },
  },
];

export default routes;
