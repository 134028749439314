<template>
  <div class="page" v-if="!isLoading" data-qa="registrationProcessPage">
    <page-header class="container">
      <template #title>Размещение данных</template>
      <template #subtitle>
        <router-link class="link link--back" :to="{ name:'inboxData', params: { number: inboxdataNumber }}"
          >Запись реестра учета сведений № {{ inboxdataNumber }}
        </router-link>
      </template>
      <template #buttons v-if="!emptyRegistrationProcess">
        <DeleteButton
          v-if="!hasRegisteredDocuments && !isRegistered"
          @remove="onRemoveRegistrationProcess"
        />
        <AddRegisrationProcessItemButton :number="inboxdataNumber" v-if="!isRegistered"/>
      </template>
    </page-header>

    <EmptyRegisrationProcess
      v-if="emptyRegistrationProcess"
      :number="inboxdataNumber"
    />

    <div class="reg-object container grid3on2" v-else>
      <section class="reg-object__docs">
        <RegistrationDocumentCard
          v-for="doc in registrationProcess.documents"
          :key="doc.id"
          :doc="doc"
          :inboxdataNumber="inboxdataNumber"
          class="reg-object__doc"
          @remove-document="onRemoveRegistrationItem($event.inboxdataNumber, $event.registrationItemId)"
        ></RegistrationDocumentCard>
      </section>

      <section class="reg-object__files box">
        <fieldset class="form__fieldset">
          <legend class="form__legend">Файлы для размещения</legend>
          <FilesField
            v-if="
              registrationProcess.files && registrationProcess.files.length > 0
            "
            :files="registrationProcess.files"
            :readonly="true"
            @update:files="$emit('update:files', $event)"
            class="form-control"
          ></FilesField>
          <p v-else class="reg-object__no-file-msg">
            Все файлы распределены. Файлов больше нет.
          </p>
        </fieldset>
      </section>
    </div>
  </div>
</template>
<script>
import useRegistrationProcess from "../../hooks/registrationProcess";

import { useRoute, useRouter } from "vue-router";
import { ref, computed } from "vue";
import FilesField from "../basic/form/files/FilesField";
import RegistrationDocumentCard from "./RegistrationDocumentCard/RegistrationDocumentCard";
import DeleteButton from "./RegistrationProcessDeleteButton";
import AddRegisrationProcessItemButton from "./AddRegisrationProcessItemButton";
import EmptyRegisrationProcess from "./EmptyRegisrationProcess";

export default {
  components: {
    RegistrationDocumentCard,
    FilesField,
    DeleteButton,
    AddRegisrationProcessItemButton,
    EmptyRegisrationProcess,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const inboxdataNumber = ref(route.params.number);

    const { registrationProcess, removeItem, fetch, removeRegistrationProcess, isRegistered, isLoading } = useRegistrationProcess(inboxdataNumber);
    const emptyRegistrationProcess = computed(() => {
      if (!registrationProcess.value)
        return false;
      if (registrationProcess.value.inboxdataNumber === "")
        return false;
      if (registrationProcess.value.documents.length === 0)
        return true;
      return false;
    });

    const hasRegisteredDocuments = computed(() => {
      return (
        registrationProcess.value &&
        registrationProcess.value.hasRegisteredDocuments
      );
    });

    async function onRemoveRegistrationItem(idNumber, registrationItemId) {
      await removeItem(idNumber, registrationItemId);
      await fetch(idNumber);
    }

    async function onRemoveRegistrationProcess() {
      await removeRegistrationProcess(inboxdataNumber.value)
      router.push({
        name: "inboxData",
        params: { id: inboxdataNumber.value },
      });
    }

    return {
      isRegistered,
      isLoading,
      inboxdataNumber,
      registrationProcess,
      removeItem,
      hasRegisteredDocuments,
      emptyRegistrationProcess,
      onRemoveRegistrationItem,
      onRemoveRegistrationProcess
    };
  },
};
</script>
<style scoped>
.reg-object__doc {
  margin-bottom: 24px;
}

.reg-object__files {
  align-self: start;
}

.reg-object__files .form__fieldset:not(:last-child) {
  padding-bottom: 44px;
  border-bottom: 1px solid var(--basic-grey);
}

.reg-object__files .form__fieldset:not(:first-child) {
  margin-top: 44px;
}

.reg-object__no-file-msg {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: var(--secondary-blue-darker);
}
.reg-object__delimiter {
  border-top: 1px solid var(--basic-grey-dark);
}
.reg-object__footer {
  display: flex;
  justify-content: flex-end;
}
</style>