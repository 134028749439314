import useVuelidate from "@vuelidate/core";
import { helpers, required, numeric, maxLength } from "@vuelidate/validators";

const rules = {
  name: {
    required: helpers.withMessage("Заполните поле", required),
    maxLength: helpers.withMessage("Количество символов не должно превышать 1000", maxLength(1000)),
  },
  inn: {
    required: helpers.withMessage("Заполните поле", required),
    numeric: helpers.withMessage("Поле должно быть числовым", numeric),
    maxLength: helpers.withMessage("Количество символов не должно превышать 12", maxLength(12)),
  },
  ogrn: {
    required: helpers.withMessage("Заполните поле", required),
    numeric: helpers.withMessage("Поле должно быть числовым", numeric),
    maxLength: helpers.withMessage("Количество символов не должно превышать 20", maxLength(20)),
  },
  phone: {
    maxLength: helpers.withMessage("Количество символов не должно превышать 100", maxLength(100)),
  },
  email: {
    maxLength: helpers.withMessage("Количество символов не должно превышать 255", maxLength(255)),
  }  
};

function useValidator(data) {
  return useVuelidate(rules, data, { $stopPropagation: true });
}

export default useValidator;
