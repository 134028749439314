<template>
  <button 
    class="button button-icon button--outline"
    title="Сохранить"
    data-qa="save">
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M16.6668 6.24992V16.6666H3.3335V3.33325H13.7502L16.6668 6.24992Z" stroke="#518CFF" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M10.3335 5L10.3335 4" stroke="#518CFF" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M5.99984 4L5.99984 9L12.6665 9L12.6665 4" stroke="#518CFF" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  </button>
</template>