import instance from '../api.js';

const baseURL =
  process.env.NODE_ENV === 'production'
    ? window.location.origin + '/api/search/srses'
    : 'http://localhost:65070/api/search/srses';

class SrsesProxy {
  static async fetch() {
    return await instance.get(``, { baseURL, noProjectContext: true });
  }
}

export default SrsesProxy;
