import { ref } from "vue";
import RegistrationProcessDictionaryProxy from "../proxies/registrationProcessDictionaryProxy";
import DocFactory from "../models/documents/factory";

export function useRegistrationProcessRefs(isChangeDoc) {
  const documentTypeRefs = ref([]);

  async function fetchDocumentTypeRefs() {
    let types = await RegistrationProcessDictionaryProxy.getDocumentTypes();
    if (isChangeDoc)
      types = types.filter(DocFactory.isDocIz);
    else if (isChangeDoc === false) {
      types = types.filter(x => !DocFactory.isDocIz(x));
    }
    documentTypeRefs.value = types;
  }

  fetchDocumentTypeRefs();

  return {
    documentTypeRefs
  };
}


export function useRegistrationProcessStatusRefs() {
  const documentStatusRefs = ref([]);
  async function fetchDocumentStatusRefs() {
    documentStatusRefs.value = await RegistrationProcessDictionaryProxy.getDocumentStatuses();
  }

  fetchDocumentStatusRefs();

  return {
    documentStatusRefs,
  };
}

