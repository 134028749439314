<template>
  <article class="box">
    <card-header class="doc-header--placed">
      <template #header-text>
        {{ doc.documentType.displayName }} №{{ doc.stateSnapshot.docNum }} от {{ $filters.toDateString(doc.stateSnapshot.docDate) }}
      </template>
      <template #header-status>
        <doc-status
          :status="doc.status"
          class="doc-status--placed"
        ></doc-status>
      </template>
    </card-header>
    <div class="card-footer">
      <link-field
        label=""
        :to="{
          name: `sectionDocument`,
          params: { number: doc.gisogdRegistrationInfo.documentNumber },
        }"
      >
        Присвоен регистрационный номер {{ doc.gisogdRegistrationInfo.documentNumber }}
      </link-field>
    </div>
  </article>
</template>
<script>
import RegistrationDocumentStatus from "./RegistrationDocumentStatus";
import CardHeader from "./RegistrationDocumentCardHeader";
import LinkField from "../../basic/form/LinkField";

export default {
  props: {
    doc: {
      required: true,
      type: Object,
    },
    inboxdataNumber: String,
  },
  components: {
    CardHeader,
    DocStatus: RegistrationDocumentStatus,
    LinkField,
  },
};
</script>
