import { ref } from 'vue';
import _debounce from 'lodash.debounce';
import VectorProxy from '../proxies/vectorProxy.js';

function useVector(callback) {
  var debounceFunc = undefined;
  const debounceDelay = 1000;
  const vectors = ref([]);

  fetch();

  async function fetch(filter = '') {
    vectors.value = await callback(filter);
  }

  async function fetchWithDelay(filter = '') {
    if (debounceFunc != undefined) debounceFunc.cancel();
    debounceFunc = _debounce(async () => {
      await fetch(filter);
    }, debounceDelay);
    debounceFunc();
  }

  return {
    vectors,
    fetchWithDelay,
  };
}

export function useVectorLandPlot() {
  return useVector(VectorProxy.fetchLandPlot);
}

export function useVectorLandPlotOther() {
  return useVector(VectorProxy.fetchLandPlotOther);
}

export function useVectorLandPlotEgrn() {
  return useVector(VectorProxy.fetchLandPlotEgrn);
}

export function useVectorLandPlotPPM() {
  return useVector(VectorProxy.fetchLandPlotPPM);
}

export function useVectorLandPlotEgrnOther() {
  return useVector(VectorProxy.fetchLandPlotEgrnOther);
}

export function useVectorOks() {
  return useVector(VectorProxy.fetchOks);
}

export function useVectorEgrnOks() {
  return useVector(VectorProxy.fetchEgrnOks);
}

export function useProjectHB() {
  return useVector(VectorProxy.fetchProjectHB);
}

export function useFetchEasement() {
  return useVector(VectorProxy.fetchEasement);
}

export function useVectorArtLandPlot() {
  return useVector(VectorProxy.fetchArtificialLandPlot);
}

export function useVectorForestry() {
  return useVector(VectorProxy.fetchForestry);
}

export function useVectorForestLandPlot() {
  return useVector(VectorProxy.fetchForestLandPlot);
}

export function useVectorZouit() {
  return useVector(VectorProxy.fetchZouit);
}

export function useVectorSettlement() {
  return useVector(VectorProxy.fetchSettlement);
}

export function useVectorOopt() {
  return useVector(VectorProxy.fetchOopt);
}

export function useVectorLandBoundary() {
  return useVector(VectorProxy.fetchLandBoundary);
}
