import { ref } from 'vue';
import RegistrationRequestProxy from '../proxies/registrationRequestProxy';
import InboxDataProxy from '../proxies/inboxDataProxy';
import RegistrationRequest from '../models/registrationRequest';

export default function useRegistrationRequest(requestNumber) {
  const isLoading = ref(true);
  const registrationRequest = ref(new RegistrationRequest());

  if (requestNumber !== undefined) fetch(requestNumber);

  async function fetch(number) {
    registrationRequest.value = await RegistrationRequestProxy.fetch(number);
    isLoading.value = false;
  }

  async function remove(number) {
    await RegistrationRequestProxy.delete(number);
  }

  async function update() {
    return await RegistrationRequestProxy.update(registrationRequest.value);
  }

  async function create(newObj) {
    registrationRequest.value = await RegistrationRequestProxy.create(newObj);
    return registrationRequest;
  }

  async function createInboxdata() {
    await InboxDataProxy.createInboxData(registrationRequest.value);
  }

  return {
    registrationRequest,
    isLoading,
    remove,
    update,
    create,
    createInboxdata,
  };
}
