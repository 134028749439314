<template>
   <div class="sidebar__logo" aria-label="Логотип ГИСОГД">
      <svg class="sidebar__logo-img" width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M8.56092 9.15627C6.94777 9.15627 5.64005 10.465 5.64005 12.0794C5.64005 13.7917 7.363 15.2457 9.04967 14.9618C9.43366 14.8972 9.79729 15.1564 9.86186 15.5406C9.92643 15.9249 9.66749 16.2888 9.2835 16.3535C6.73976 16.7815 4.22998 14.6676 4.22998 12.0794C4.22998 9.68563 6.16901 7.74512 8.56092 7.74512C11.0694 7.74512 13.0824 9.94932 12.8771 12.4398C12.845 12.8281 12.5045 13.117 12.1164 13.0849C11.7284 13.0529 11.4397 12.7121 11.4718 12.3237C11.6096 10.6517 10.241 9.15627 8.56092 9.15627Z" fill="#FEFEFE"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M8.56115 5.1299C4.61404 5.1299 1.41007 8.34401 1.41007 12.314C1.41007 13.0886 1.71756 14.0383 2.2711 15.0961C2.81843 16.142 3.57316 17.2316 4.39099 18.2694C5.96722 20.2697 7.73108 22.0195 8.56115 22.8114C8.7637 22.6181 9.02281 22.367 9.3219 22.0683C9.37043 22.0198 9.42001 21.9701 9.47056 21.9192C9.51884 21.8705 9.56801 21.8208 9.61801 21.7701C9.68354 21.7035 9.75051 21.6351 9.81877 21.565C9.87994 21.5022 9.94214 21.438 10.0053 21.3724C10.2755 21.0919 10.7218 21.0837 11.0022 21.3542C11.2825 21.6246 11.2907 22.0713 11.0204 22.3518C10.9555 22.4192 10.8916 22.4852 10.8287 22.5498C10.7586 22.6219 10.6897 22.6921 10.6223 22.7606C10.571 22.8128 10.5204 22.8639 10.4708 22.9138C10.4188 22.9662 10.3678 23.0173 10.3179 23.0671C9.96532 23.4193 9.6661 23.7079 9.44555 23.917C8.95419 24.383 8.1833 24.3973 7.68051 23.9206C6.87333 23.1553 4.98025 21.2961 3.28378 19.1433C2.4358 18.0671 1.62435 16.9019 1.02196 15.7508C0.425785 14.6115 0 13.4221 0 12.314C0 7.56931 3.83063 3.71875 8.56115 3.71875C11.0309 3.71875 13.2568 4.7696 14.8181 6.44742C14.8572 6.48941 14.8959 6.5318 14.9341 6.57458C14.9689 6.61352 15.0034 6.65279 15.0375 6.69236C15.0974 6.7619 15.1563 6.83242 15.214 6.90388C15.2723 6.9761 15.3295 7.0493 15.3855 7.12344C15.4541 7.21419 15.5209 7.30636 15.5859 7.39988C15.8083 7.71976 15.7295 8.15948 15.4098 8.38203C15.0902 8.60458 14.6508 8.52568 14.4284 8.2058C14.3741 8.12758 14.3182 8.0505 14.2608 7.9746C14.214 7.9126 14.1661 7.85138 14.1174 7.79097C14.0691 7.73118 14.0198 7.6722 13.9697 7.61403C13.9412 7.58091 13.9123 7.54807 13.8832 7.5155C13.8512 7.47972 13.8189 7.44426 13.7862 7.40913C12.4799 6.00533 10.6226 5.1299 8.56115 5.1299Z" fill="#FEFEFE"/>
          <path d="M22.6621 16.074C22.6621 15.74 22.9327 15.4692 23.2664 15.4692C23.6002 15.4692 23.8707 15.74 23.8707 16.074V16.6788C23.8707 17.0128 23.6002 17.2836 23.2664 17.2836C22.9327 17.2836 22.6621 17.0128 22.6621 16.6788V16.074Z" fill="#FEFEFE"/>
          <path d="M22.6621 18.8963C22.6621 18.5623 22.9327 18.2915 23.2664 18.2915C23.6002 18.2915 23.8707 18.5623 23.8707 18.8963V19.5011C23.8707 19.8351 23.6002 20.1058 23.2664 20.1058C22.9327 20.1058 22.6621 19.8351 22.6621 19.5011V18.8963Z" fill="#FEFEFE"/>
          <path d="M24.4751 16.074C24.4751 15.74 24.7457 15.4692 25.0794 15.4692C25.4132 15.4692 25.6837 15.74 25.6837 16.074V16.6788C25.6837 17.0128 25.4132 17.2836 25.0794 17.2836C24.7457 17.2836 24.4751 17.0128 24.4751 16.6788V16.074Z" fill="#FEFEFE"/>
          <path d="M24.4751 18.8963C24.4751 18.5623 24.7457 18.2915 25.0794 18.2915C25.4132 18.2915 25.6837 18.5623 25.6837 18.8963V19.5011C25.6837 19.8351 25.4132 20.1058 25.0794 20.1058C24.7457 20.1058 24.4751 19.8351 24.4751 19.5011V18.8963Z" fill="#FEFEFE"/>
          <path d="M24.4751 21.7185C24.4751 21.3845 24.7457 21.1138 25.0794 21.1138C25.4132 21.1138 25.6837 21.3845 25.6837 21.7185V24.2385H24.4751V21.7185Z" fill="#FEFEFE"/>
          <path d="M22.6621 21.7185C22.6621 21.3845 22.9327 21.1138 23.2664 21.1138C23.6002 21.1138 23.8707 21.3845 23.8707 21.7185V24.2385H22.6621V21.7185Z" fill="#FEFEFE"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M23.1041 8.46853C23.2703 8.60247 23.367 8.80453 23.367 9.01808V12.8483H27.2949C27.6843 12.8483 27.9999 13.1642 27.9999 13.5538V24.2383H26.5899V14.2594H21.7553V24.2383H20.3453V13.5538C20.3453 13.1642 20.6609 12.8483 21.0503 12.8483H21.9569V9.89428L16.7195 11.0384V12.3444H18.6332C18.967 12.3444 19.2375 12.6152 19.2375 12.9492C19.2375 13.2832 18.967 13.554 18.6332 13.554H16.7195V14.7635H18.6332C18.967 14.7635 19.2375 15.0343 19.2375 15.3683C19.2375 15.7023 18.967 15.9731 18.6332 15.9731H16.7195V17.1826H18.6332C18.967 17.1826 19.2375 17.4534 19.2375 17.7874C19.2375 18.1214 18.967 18.3921 18.6332 18.3921H16.7195V19.6017H18.6332C18.967 19.6017 19.2375 19.8724 19.2375 20.2064C19.2375 20.5405 18.967 20.8112 18.6332 20.8112H16.7195V24.2384H15.3094V16.3761H13.3959V24.2382H11.9858V15.6705C11.9858 15.2808 12.3015 14.9649 12.6909 14.9649H15.3094V10.4702C15.3094 10.1385 15.5403 9.8516 15.8641 9.78086L22.5116 8.32874C22.72 8.2832 22.9379 8.3346 23.1041 8.46853Z" fill="#FEFEFE"/>
      </svg>
      <svg class="sidebar__logo-text" width="74" height="15" viewBox="0 0 74 15" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M8.04919 2.14481H2.03665L2.05335 12.1123H0.399902V0.730179H8.06589L8.04919 2.14481Z" fill="#FEFEFE"/>
          <path d="M20.4104 0.730179V12.1123H18.807V10.5025C18.0165 11.6624 16.7472 12.2424 14.9991 12.2424C13.6073 12.2424 12.5161 11.8304 11.7256 11.0066C10.9462 10.1828 10.5565 9.0066 10.5565 7.47815V0.730179H12.2266V7.42937C12.2266 8.52422 12.505 9.35891 13.0617 9.93343C13.6184 10.508 14.3811 10.7952 15.3498 10.7952C16.3965 10.7952 17.226 10.4917 17.8384 9.88465C18.4507 9.2776 18.7569 8.37788 18.7569 7.18546V0.730179H20.4104Z" fill="#FEFEFE"/>
          <path d="M29.8792 12.2424C28.7101 12.2424 27.6523 11.9931 26.7059 11.4944C25.7706 10.9849 25.0358 10.2912 24.5013 9.4131C23.9669 8.52422 23.6997 7.52693 23.6997 6.42123C23.6997 5.31554 23.9669 4.32367 24.5013 3.44563C25.0358 2.55674 25.7762 1.86297 26.7226 1.36433C27.669 0.85484 28.7268 0.600098 29.8959 0.600098C30.8089 0.600098 31.644 0.75186 32.4011 1.05538C33.1583 1.34807 33.8041 1.78709 34.3385 2.37245L33.2529 3.39685C32.3733 2.49712 31.2766 2.04725 29.9627 2.04725C29.0942 2.04725 28.3093 2.23695 27.6078 2.61636C26.9063 2.99576 26.3552 3.52151 25.9544 4.19359C25.5647 4.85484 25.3698 5.59739 25.3698 6.42123C25.3698 7.24508 25.5647 7.99305 25.9544 8.66514C26.3552 9.32638 26.9063 9.84671 27.6078 10.2261C28.3093 10.6055 29.0942 10.7952 29.9627 10.7952C31.2654 10.7952 32.3622 10.3399 33.2529 9.42937L34.3385 10.4538C33.8041 11.0391 33.1527 11.4836 32.3844 11.7871C31.6273 12.0906 30.7922 12.2424 29.8792 12.2424Z" fill="#FEFEFE"/>
          <path d="M42.3239 12.2424C41.1437 12.2424 40.0803 11.9931 39.1339 11.4944C38.1875 10.9849 37.4415 10.2857 36.8959 9.39685C36.3615 8.50796 36.0943 7.51609 36.0943 6.42123C36.0943 5.32638 36.3615 4.33451 36.8959 3.44563C37.4415 2.55674 38.1875 1.86297 39.1339 1.36433C40.0803 0.85484 41.1437 0.600098 42.3239 0.600098C43.493 0.600098 44.5508 0.85484 45.4972 1.36433C46.4436 1.86297 47.184 2.55674 47.7185 3.44563C48.2529 4.32367 48.5202 5.31554 48.5202 6.42123C48.5202 7.52693 48.2529 8.52422 47.7185 9.4131C47.184 10.2912 46.4436 10.9849 45.4972 11.4944C44.5508 11.9931 43.493 12.2424 42.3239 12.2424ZM42.3239 10.7952C43.1813 10.7952 43.9495 10.6055 44.6287 10.2261C45.319 9.84671 45.8591 9.32638 46.2488 8.66514C46.6496 7.99305 46.85 7.24508 46.85 6.42123C46.85 5.59739 46.6496 4.85484 46.2488 4.19359C45.8591 3.52151 45.319 2.99576 44.6287 2.61636C43.9495 2.23695 43.1813 2.04725 42.3239 2.04725C41.4666 2.04725 40.6872 2.23695 39.9857 2.61636C39.2954 2.99576 38.7498 3.52151 38.349 4.19359C37.9593 4.85484 37.7644 5.59739 37.7644 6.42123C37.7644 7.24508 37.9593 7.99305 38.349 8.66514C38.7498 9.32638 39.2954 9.84671 39.9857 10.2261C40.6872 10.6055 41.4666 10.7952 42.3239 10.7952Z" fill="#FEFEFE"/>
          <path d="M59.5374 2.14481H53.5249L53.5416 12.1123H51.8881V0.730179H59.5541L59.5374 2.14481Z" fill="#FEFEFE"/>
          <path d="M73.1999 10.6977V14.6001H71.6466V12.1123H61.8095L61.7928 14.6001H60.2562L60.2729 10.6977H61.7093V5.59197C61.7093 3.98763 62.1602 2.75728 63.0621 1.90091C63.964 1.0337 65.1832 0.600098 66.7197 0.600098C68.2674 0.600098 69.4922 1.0337 70.394 1.90091C71.3071 2.75728 71.7636 3.98763 71.7636 5.59197V10.6977H73.1999ZM70.0767 10.6977V5.42937C70.0767 4.32367 69.7817 3.48899 69.1915 2.9253C68.6125 2.35078 67.7886 2.06351 66.7197 2.06351C65.6619 2.06351 64.838 2.35078 64.2479 2.9253C63.6578 3.48899 63.3627 4.32367 63.3627 5.42937V10.6977H70.0767Z" fill="#FEFEFE"/>
      </svg>
  </div>
</template>

<script>
export default {   
}
</script>

<style>
.sidebar__logo-img {
        margin-right: 8px;
    }

    .sidebar--collapsed .sidebar__logo-img {
        margin-right: 0;
    }

    .sidebar--collapsed .sidebar__logo-text {
        display: none;
    }
</style>