<template>
    <div class="form-control">
    <label class="label"><slot></slot></label>
      <input
        class="field text"
        :class="{ 'field--error': errors && errors.length > 0, 'readonly-input': isReadonly }"
        type="text"
        :value="modelValue"
        @input="$emit('update:modelValue', $event.target.value)"
        :maxlength="maxLength"
        :readonly="isReadonly"
      />
      <div v-for="(error, index) of errors" v-bind:key="index">
        <div class="field-message--error">{{ error.$message }}</div>
      </div>
    </div>
</template>

<script>
export default {
  emits: ['update:modelValue'],
    props: {
        modelValue: {
          type: String
        },
        errors: {
          type: Array
        },
        maxLength: {
          type: Number
        },
        isReadonly: {
          type: Boolean,
          default: false
        }
    }
};
</script>

<style scoped>
.readonly-input {
  cursor: auto;
}
</style>