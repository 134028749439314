<template>
  <div ref="mapRef">
    <template v-if="Boolean(leaflet) && Boolean(map)">
      <slot :leaflet="leaflet" :map="map"></slot>
    </template>
  </div>
</template>
<script>
import * as L from "leaflet";
import "leaflet/dist/leaflet.css";
import { onMounted, ref } from "vue";
export default {
  setup() {
    const leaflet = L; //window["L"];
    const mapRef = ref(null);
    const map = ref(null);

    onMounted(() => {
      makeMap();
    });
    function makeMap() {
      map.value = leaflet.map(mapRef.value, {
        zoomAnimationThreshold: 1,
        zoomControl: false,
        maxZoom: 18,
        minZoom: 0,
      });
      leaflet.control
        .zoom({
          position: "bottomright",
          zoomInTitle: "Приблизить",
          zoomOutTitle: "Отдалить",
        })
        .addTo(map.value);

      map.value.setView([0, 0], 5);

      leaflet.DomUtil.removeClass(map.value._container, "leaflet-grab");
      map.value.attributionControl.setPrefix("Leaflet");
    }

    return {
      leaflet,
      map,
      mapRef,
    };
  },
};
</script>