import { ref } from "vue";
import RegistrationRequestDictionaryProxy from '../proxies/registrationRequestDictionaryProxy';

export default function useRegistrationRequestRefs() {
  const waysOfReceival = ref([]);
  const dataFormats = ref([]);

  async function fetchWaysOfReceivalRefs() {
    waysOfReceival.value = await RegistrationRequestDictionaryProxy.getWaysOfReceival();
  }
  async function fetchDataFormatsRefs() {
    dataFormats.value = await RegistrationRequestDictionaryProxy.getDataFormats();
  }

  fetchWaysOfReceivalRefs();
  fetchDataFormatsRefs();

  return {
    waysOfReceival,
    dataFormats,
  };
}
