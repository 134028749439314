<script>
import { reactive, toRefs, watch } from "vue";
import Api from "@/api";

let cancel;
export default {
  props: {
    url: {
      type: String,
      required: true,
    },
  },
  setup(props, context) {
    const { url } = toRefs(props);
    const state = reactive({
      json: null,
      loading: false,
    });

    function fetch() {
      state.loading = true;

      Api.get(url.value, {
        cancelToken: new Api.CancelToken(function executor(c) {
          cancel = c;
        }),
      })
        .then((response) => {
          state.json = response;
        })
        .finally(() => {
          state.loading = false;
        });
    }
    fetch();
    watch(url, () => {
      cancel();
      fetch();
    });

    return () => context.slots.default(state);
  },
};
</script>

