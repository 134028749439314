export class EntrepreneurDto {
    constructor(firstName, lastName, middleName, phone, email, ogrnip, passportSeries, passportNumber, snils, inn, externalId, displayName, type, subjectStatusKey, guid, entrepreneurShortName, entrepreneurFullName) {
        this.firstName = firstName;
        this.lastName = lastName;
        this.middleName = middleName;
        this.phone = phone;
        this.email = email;
        this.ogrnip = ogrnip;
        this.passportSeries = passportSeries;
        this.passportNumber = passportNumber;
        this.snils = snils;
        this.inn = inn;
        this.externalId = externalId;
        this.displayName = displayName;
        this.type = type;
        this.subjectStatusKey = subjectStatusKey;
        this.guid = guid;
        this.enterpreneurShortName = entrepreneurShortName;
        this.enterpreneurFullName = entrepreneurFullName;
    }
  }