<template>
  <div v-show="isActive">
    <slot></slot>
  </div>
</template>

<script>
import { ref, inject, watchEffect, onBeforeMount } from "vue";
export default {
  props: {
    name: { required: true },
  },
  setup() {
    const index = ref(0);
    const isActive = ref(false);
    const tabs = inject("Tabs");
    watchEffect(() => {
      isActive.value = tabs.selectedIndex == index.value;
    });

    onBeforeMount(() => {
      index.value = tabs.count;
      tabs.count++;
    });
    return { isActive };
  },
};
</script>