<template>
  <div>
    <label class="uneditable-field__label label">
      <slot name="label"></slot>
    </label>
    <div class="uneditable-field__box">
      <span class="uneditable-field__value">{{ modelValue.displayName }} </span>
      <button
          @click="onInfoSubjectClick"
          class="button button-icon button--small"
          title="Показать сведения о субъекте"
          v-if="isButtonVisible"
      >
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
          <circle
              cx="10"
              cy="10"
              r="7.5"
              stroke="#90A0B7"
              stroke-width="1.4"
              stroke-linecap="round"
              stroke-linejoin="round"
          />
          <path
              d="M10 10V13.3333"
              stroke="#90A0B7"
              stroke-width="1.4"
              stroke-linecap="round"
              stroke-linejoin="round"
          />
          <path
              d="M10 6.66675H10.0083"
              stroke="#90A0B7"
              stroke-width="1.4"
              stroke-linecap="round"
              stroke-linejoin="round"
          />
        </svg>
      </button>
    </div>
  </div>
  <modal-window :visible="personModel != null || organizationModel != null || enterpreneurModel != null" @close="closeModal">
    <template #title>Информация о субъекте</template>
    <PersonFields
        v-model="personModel"
        v-if="personModel"
        :validator="personValidator"
        :isReadonly="true"
    ></PersonFields>
    <OrganizationFields
        v-model="organizationModel"
        v-if="organizationModel"
        :validator="organizationValidator"
        :isReadonly="true"
    ></OrganizationFields>
    <EnterpreneurFields
        v-model="enterpreneurModel"
        v-if="enterpreneurModel"
        :validator="enterpreneurValidator"
        :isReadonly="true"
    ></EnterpreneurFields>
  </modal-window>
</template>

<script>
import {computed, ref} from 'vue';
import PersonFields from '../../subject/PersonFields';
import OrganizationFields from '../../subject/OrganizationFields';
import EnterpreneurFields from '../../subject/EnterpreneurFields.vue';

import usePersonValidator from '../../subject/personValidator';
import useOrganizationValidator from '../../subject/organizationValidator';
import useEnterpreneurValidator from '../../subject/enterpreneurValidator';

export default {
  props: {
    modelValue: {
      required: true,
    },
    displayProp: {
      default: 'displayName',
      type: String,
    },
  },
  components: {OrganizationFields, PersonFields, EnterpreneurFields},
  setup(props) {
    const personModel = ref(null);
    const organizationModel = ref(null);
    const enterpreneurModel = ref(null);

    const personValidator = usePersonValidator(personModel);
    const organizationValidator = useOrganizationValidator(organizationModel);
    const enterpreneurValidator = useEnterpreneurValidator(enterpreneurModel);

    async function onInfoSubjectClick() {
      switch (props.modelValue.type.toLowerCase()) {
        case 'person':
          personModel.value = props.modelValue;
          organizationModel.value = null;
          enterpreneurModel.value = null;
          break;
        case 'organization':
          organizationModel.value = props.modelValue;
          personModel.value = null;
          enterpreneurModel.value = null;
          break;
        case 'enterpreneur':
          enterpreneurModel.value = props.modelValue;
          personModel.value = null;
          organizationModel.value = null;
          break;
      }
    }

    //#region Modal
    const isButtonVisible = computed(() => {
      if (JSON.stringify(props.modelValue) === '{}')
        return false;
      else
        return true;
    })   

    function closeModal() {
      organizationModel.value =null;
      personModel.value = null;
      enterpreneurModel.value = null;
    }

    //#endregion

    return {      
      personModel,
      organizationModel,
      closeModal,
      personValidator,
      organizationValidator,
      onInfoSubjectClick,
      isButtonVisible,
      enterpreneurModel,
      enterpreneurValidator
    };
  },
};
</script>

<style lang="scss" scoped>
.uneditable-field__box {
  display: flex;
  gap: 8px;
  align-items: center;
}

.subject-select {
  padding-top: 15px;
  padding-bottom: 24px;
  display: inline-flex;
}

.subject-select__radio {
  display: none;
}

.subject-select__label {
  position: relative;
}

.subject-select__radio + label::before {
  content: '';
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: var(--basic-white);
  border: 1px solid var(--secondary-blue-darker);
  left: 0;
  top: 2px;
}

.subject-select__radio:checked + label::before {
  width: 15px;
  height: 15px;
  background-color: var(--primary-blue-lighter);
  border: 2px solid var(--basic-white);
  box-shadow: 0px 0px 0px 1px var(--primary-blue-lighter);
}

.subject-select__radio:checked:hover + label::before {
  background-color: var(--primary-blue);
  border: 2px solid var(--basic-white);
  box-shadow: 0px 0px 0px 1px var(--primary-blue);
}

.subject-select__radio:hover + label::before,
.subject-select__radio:focus + label::before {
  border-color: var(--primary-blue-lighter);
}

.subject-select__label {
  padding-left: 24px;
  font-size: 16px;
  line-height: 20px;
  color: var(--secondary-blue-darker);
}

.subject-select__radio-and-label:first-child {
  margin-right: 40px;
}

.add-subject__icon {
  margin-right: 5px;
}

.add-subject:hover .add-subject__icon path {
  stroke: var(--basic-black);
}

.add-subject {
  position: relative;
  padding-top: 4px;
  padding-bottom: 7px;
  box-sizing: border-box;
}

.add-subject::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 1px;
  background-color: var(--basic-grey-light);
  bottom: -6px;
}
</style>
