<template>
  <div class="status" :class="statusClass">
    <div class="status__icon" v-if="!mini"></div>
    <span v-html="highlight(query, status)"></span>
  </div>
</template>

<script>
import {computed} from "vue";
import useTextHighlight from "../../../hooks/textHighlight";

export default {
  props: {
    processStatus: Number,
    status: {
      type: Object,
      required: true,
    },
    mini: {
      type: Boolean,
      default: true,
    },
    query: {
      type: String
    },
  },
  setup(props) {
    const statusClass = computed(() => {
      switch (props.processStatus) {
        case 1: {
          return "status--awaiting-empty";
        }
        case 2: {
          return "status--awaiting";
        }
        case 3: {
          return "status--registered";
        }
        default: {
          return "";
        }
      }
    });

    const {highlight} = useTextHighlight();
    return {statusClass, highlight};
  },
};
</script>

<style>
.status {
  display: flex;
  font-size: 16px;
  line-height: 20px;
  color: var(--secondary-blue-darker);
}

.status__icon {
  width: 20px;
  height: 20px;
  margin-right: 12px;
}

.status--awaiting {
  color: var(--basic-yellow);
}

.status--awaiting .status__icon {
  background-image: url("../../../assets/svg/doc-statuses/awaiting.svg");
}

.status--awaiting-empty .status__icon {
  background-image: url("../../../assets/svg/doc-statuses/awaiting_empty.svg");
}

.status--registered {
  color: var(--basic-green);
}

.status--registered .status__icon {
  background-image: url("../../../assets/svg/doc-statuses/registered.svg");
}
</style>