export class OrganizationDto {
  constructor(name, organizationalForm, inn, ogrn, phone, email, subjectStatusKey, guid) {
    this.name = name;
    this.organizationalForm = organizationalForm;
    this.inn = inn;
    this.ogrn = ogrn;
    this.phone = phone;
    this.email = email;
    this.subjectStatusKey = subjectStatusKey;
    this.guid = guid;
  }
}