export class CreateRegistrationRequestDto {
  constructor(number, requestDate, outgoingNumber, outgoingDate, nameOfSenderSystem, requestSender = {}, wayOfReceival = {}, dataFormat = {},  files = []) {
    this.requestDate = requestDate;
    this.outgoingNumber = outgoingNumber;
    this.outgoingDate = outgoingDate;
    this.nameOfSenderSystem = nameOfSenderSystem;
    this.requestSender = requestSender;
    this.wayOfReceival = wayOfReceival;
    this.dataFormat = dataFormat;
    this.files = files;
  }
}


export class UpdateRegistrationRequestDto extends CreateRegistrationRequestDto {
  constructor(id, number, requestDate, outgoingNumber, outgoingDate, nameOfSenderSystem, requestSender = {}, wayOfReceival = {}, dataFormat = {},  files = []) {
    super(number, requestDate, outgoingNumber, outgoingDate, nameOfSenderSystem, requestSender, wayOfReceival, dataFormat, files)
    this.id = id;
    this.number = number;
  }
}