<template>
    <svg class="spinner spinner--center" width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M30 58C25.5783 58 21.2195 56.9528 17.2804 54.9442C13.3413 52.9355 9.93387 50.0225 7.33713 46.4437C4.7404 42.8648 3.02816 38.7219 2.34059 34.354C1.65302 29.9861 2.00968 25.5174 3.38137 21.3139C4.75305 17.1104 7.10076 13.2914 10.2323 10.1698C13.3638 7.04812 17.1901 4.71248 21.398 3.35407C25.6058 1.99567 30.0756 1.65312 34.4413 2.35448C38.807 3.05583 42.9445 4.78114 46.5152 7.38917" stroke="#518CFF" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
</template>
<script>
export default {
}
</script>
<style scoped>
.spinner--center {
  margin: auto;
  display: block;
}
</style>