import { StorageFile } from "./storageFile";
import { RegistrationDocument } from "./registrationDocument";

export class RegistrationProcess {
  constructor(inboxdataNumber = "", documents = [], files = [], rejectedFiles = []) {
    this.inboxdataNumber = inboxdataNumber;
    this.documents = documents;
    this.files = files;
    this.rejectedFiles = rejectedFiles;
  }
  get readyDocuments(){
    return this.documents.filter(d=>d.status==="Готов к размещению")
  }

  get isAllDocumentRegistered(){
    return this.documents.length>0 && this.documents.every(d=>d.status==="Размещен");
  }

  get hasRegisteredDocuments(){
    return this.documents.length>0 && this.documents.some(d=>d.status==="Размещен");
  }

  static fromDto(dto) {
    const files = dto.files.map(
      (f) => new StorageFile( f.fileName, f.fileStorageUrl, f.contentType, f.size)
    );
    const rejectedFiles = dto.rejectedFiles.map(
      (f) => new StorageFile( f.fileName, f.fileStorageUrl, f.contentType, f.size)
    );
    const documents = dto.documents.map(d=>RegistrationDocument.fromDto(d));
    return new RegistrationProcess(
      dto.inboxdataNumber,
      documents,
      files,
      rejectedFiles
    );
  }
}
