<template>
  <div>
    <TextField
      :modelValue="modelValue.lastName"
      @update:modelValue="update('lastName', $event)"
      :errors="v$.lastName.$errors"
      :isReadonly="isReadonly"
    >
      <label class="label label--required" for="registration-request-name-is"
        >Фамилия</label
      >
    </TextField>
    <TextField
      :modelValue="modelValue.firstName"
      @update:modelValue="update('firstName', $event)"
      :errors="v$.firstName.$errors"
      :isReadonly="isReadonly"
    >
      <label class="label label--required" for="registration-request-name-is"
        >Имя</label
      >
    </TextField>
    <TextField
      :modelValue="modelValue.middleName"
      @update:modelValue="update('middleName', $event)"
      :errors="v$.middleName.$errors"
      :isReadonly="isReadonly"
    >
      <label class="label" for="registration-request-name-is">Отчество</label>
    </TextField>
    <TextField
      :modelValue="modelValue.phone"
      @update:modelValue="update('phone', $event)"
      :errors="v$.phone.$errors"
      :isReadonly="isReadonly"
    >
      <label class="label" for="registration-request-name-is">Телефон</label>
    </TextField>
    <TextField
      :modelValue="modelValue.email"
      @update:modelValue="update('email', $event)"
      :errors="v$.email.$errors"
      :isReadonly="isReadonly"
    >
      <label class="label" for="registration-request-name-is"
        >Электронная почта</label
      >
    </TextField>
    <TextField
      :modelValue="modelValue.ogrnip"
      @update:modelValue="update('ogrnip', $event)"
      :errors="v$.ogrnip.$errors"
      :isReadonly="isReadonly"
    >
      <label class="label">ОГРНИП</label>
    </TextField>
    <TextField
      :modelValue="modelValue.passportSeries"
      @update:modelValue="update('passportSeries', $event)"
      :errors="v$.passportSeries.$errors"
      :isReadonly="isReadonly"
    >
      <label class="label">Серия паспорта</label>
    </TextField>
    <TextField
      :modelValue="modelValue.passportNumber"
      @update:modelValue="update('passportNumber', $event)"
      :errors="v$.passportNumber.$errors"
      :isReadonly="isReadonly"
    >
      <label class="label">Номер паспорта</label>
    </TextField>
    <TextField
      :modelValue="modelValue.snils"
      @update:modelValue="update('snils', $event)"
      :errors="v$.snils.$errors"
      :isReadonly="isReadonly"
    >
      <label class="label">СНИЛС</label>
    </TextField>
    <TextField
      :modelValue="modelValue.inn"
      @update:modelValue="update('inn', $event)"
      :errors="v$.inn.$errors"
      :isReadonly="isReadonly"
    >
      <label class="label">ИНН</label>
    </TextField>
    <UniversalSelectField
      v-if="!isReadonly"
      :modelValue="modelValue.subjectStatus || defaultSubjectStatus"
      @update:modelValue="update('subjectStatus', $event)"
      :keyProp="'id'"
      :displayProp="'displayName'"
      :canFind="!isReadonly"
      :options="subjectStatusOptions"
      :forceDropUp="true"
    >
      <label class="label">Актуальность:</label>
    </UniversalSelectField>
  </div>
</template>

<script>
import Person from "../../models/person";
import TextField from "../../components/basic/form/TextField";
import useSubject from "../../hooks/subject";
import { ref, computed, watch } from "vue";
import UniversalSelectField from "@/components/basic/form/select/UniversalSelectField.vue";

export default {
  emits: ["update:modelValue", "update:isValid"],
  props: {
    modelValue: {
      type: Object,
      default: () => new Person(),
    },
    validator: {
      type: Object,
      required: true,
    },
    isReadonly: {
      type: Boolean,
      default: false,
    }
  },
  components: {
    UniversalSelectField,
    TextField
  },
  setup(props, { emit }) {
    const { getSubjectStatusDictionary } = useSubject();
    const subjectStatusOptions = ref([]);
    getSubjectStatusDictionary().then((result) => {
      subjectStatusOptions.value = result;
    });

    const defaultSubjectStatus = computed(() => {
      return subjectStatusOptions.value.find((s) => s.id == 1);
    });

    watch(
      () => defaultSubjectStatus.value,
      () => {
        if (!props.isReadonly) {
          if (
            !props.modelValue.SubjectStatus &&
            defaultSubjectStatus.value !== undefined
          ) {
            update("subjectStatus", defaultSubjectStatus.value);
          }
        }
      }
    );

    const v$ = ref(props.validator);

    function update(fieldName, value, field) {
      const newValue = { ...props.modelValue };
      newValue[fieldName] = value;
      if (field) field.$model = value;
      emit("update:modelValue", newValue);
      emit("update:isValid", !v$.value.$invalid);
    }

    return {
      v$,
      TextField,
      update,
      subjectStatusOptions,
      defaultSubjectStatus,
    };
  },
};
</script>

<style scoped>
</style>