import DocumentSignatureInfoProxy from "../proxies/documentSignatureInfoProxy";

export default function useDocumentSignatureInfo() {
  async function GetSignatureInfoForDocument(documentNumber) {
    return await DocumentSignatureInfoProxy.getSignatureInfoForDocument(
      documentNumber
    );
  }

  async function GetSignatureInfoByTechnicalCorrectionId(
    sectionNumber,
    technicalCorrectionId
  ) {
    return await DocumentSignatureInfoProxy.getSignatureInfoByTechnicalCorrectionId(
      sectionNumber,
      technicalCorrectionId
    );
  }

  return {
    GetSignatureInfoForDocument,
    GetSignatureInfoByTechnicalCorrectionId,
  };
}
