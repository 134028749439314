<template>
  <div v-for="(error, index) of errors" :key="index">
    <div class="field-message--error">{{ error.$message }}</div>
  </div>
</template>
<script>
export default {
  props: {
    errors: {
      type: Array,
      required: true
    }
  }
}
</script>