import mgr from './oidcUserManager';
import useProjectAlias from '../hooks/projectAlias';
import {ref} from 'vue';

export default function useAuthorization() {
	async function getUser() {
		try {
			const user = await mgr.getUser();
			if (user == null) {
				await signIn();
				return null;
			} else {
				return user;
			}
		} catch (err) {
			console.log(err);
			return err;
		}
	}

	async function signIn() {
		await mgr.signinRedirect({state:window.location.pathname})
			.catch(function (err) {
				console.log(err);
			});
	}

	function getCallback() {
		return mgr.signinRedirectCallback({state:window.location.pathname})			
			.catch(function (err) {
				console.log(err);
				if (err.message.includes('No matching state found in storage')) {
					return signIn();
				} else {
					throw err;
				}
			});
	}

	function renewToken() {
		return new Promise((resolve, reject) => {
			mgr
				.signinSilentCallback()
				.then((user) => {
					console.log(user);
					if (user == null) {
						signIn();
					} else {
						return resolve(user);
					}
				})
				.catch(function (err) {
					console.log(err);
					return reject(err);
				});
		});
	}

	async function signOut() {
		await mgr
			.signoutRedirect()
			.then(function (resp) {
				console.log('signed out', resp);
			})
			.catch(function (err) {
				console.log(err);
			});
	}

	function canEdit() {
		const {currentProject} = useProjectAlias();
		const haveEditRight = ref(currentProject.value.editable);
		return haveEditRight;
	}

	function getCurrentUserName() {
		const userName = ref('');
		const {projectAlias} = useProjectAlias();

		mgr.getUser().then((user) => {
			var userFullnameClaim = user.profile['fullname'].find((x) =>
				x.includes(projectAlias.value)
			);
			if (userFullnameClaim !== undefined)
				userName.value = userFullnameClaim.split('.')[1];
		});
		return userName;
	}

	return {
		getUser,
		getCallback,
		signOut,
		renewToken,
		canEdit,
		getCurrentUserName,
	};
}