export default function useTextHighlight(cssClass = 'text-highlight') {
  const specialCharactes = /[-[\]{}()*+?.,\\^$|#\s]/g;

  function highlight(query, content) {
    if (!query || !content) {
      return content;
    }
    const escapedQuery = query.replace(specialCharactes, '\\$&');
    return content
      .toString()
      .replace(new RegExp(escapedQuery, 'gi'), (match) => {
        return `<mark class="${cssClass}">${match}</mark>`;
      });
  }

  return { highlight };
}
