<template>
  <button
    class="button territorial-planning-regulation-button"
    title="Является положением о территориальном планировании"
    :class="buttonStyle"
    @click="toggleActive"
    :disabled="disabled"
    data-qa="position"
  >
    Положение
  </button>
</template>
<script>
import { computed } from 'vue';

export default {
  props: {
    modelValue: {
      type: Boolean,
      required: true,
      default: () => false,
    },
    disabled: {
      type: Boolean,
      default: () => false,
    },
  },

  setup(props, { emit }) {
    function toggleActive() {
      emit('update:modelValue', !props.modelValue);
    }

    const buttonStyle = computed(() => {
      return props.modelValue ? 'button--primary' : 'button--outline';
    });

    return { toggleActive, buttonStyle };
  },
};
</script>
<style scoped lang="scss">
.territorial-planning-regulation-button {
  border-radius: 4px;
  padding-top: 0;
  padding-bottom: 1px;
  padding-left: 8px;
  padding-right: 8px;
  font-size: 14px;
  min-width: 90px;
}
</style>
