class SignatureCertificateInfo {
  constructor(certificateName, fullName, organization, email, address, post) {
    (this.certificateName = certificateName),
      (this.fullname = fullName),
      (this.organization = organization),
      (this.email = email),
      (this.address = address),
      (this.post = post);
  }

  static fromDto(dto) {
    if (dto == null) return null;
    return new SignatureCertificateInfo(
      dto.certificateName,
      dto.fullName,
      dto.organization,
      dto.email,
      dto.address,
      dto.post
    );
  }
}

export default class DocumentSignatureInfo {
  constructor(certificateInfo) {
    this.certificateInfo = certificateInfo;
  }

  static fromDto(dto) {
    if (dto == null) return null;
    var certificateInfo = SignatureCertificateInfo.fromDto(
      dto.certificateInfoDto
    );
    if (certificateInfo == null) return null;
    return new DocumentSignatureInfo(certificateInfo);
  }
}
