import instance from '../api.js';
import InboxData from '../models/inboxData';
import { StorageFileDto } from '../dtos/storageFileDto';
import { CreateInboxDataDto } from '../dtos/inboxDataDto';

class InboxDataProxy {
  static async fetch(number) {
    return instance.get(`inboxdata/${number}`).then((response) => {
      return InboxData.fromDto(response);
    });
  }

  static createInboxData(registrationRequest) {
    const files = registrationRequest.files.map((f) => new StorageFileDto(f.id, f.name, f.fileUrl, f.contentType, f.size));
    const dto = new CreateInboxDataDto(
      registrationRequest.number,
      registrationRequest.requestDate,
      registrationRequest.outgoingNumber,
      registrationRequest.outgoingDate,
      registrationRequest.nameOfSenderSystem,
      registrationRequest.requestSender,
      registrationRequest.wayOfReceival,
      registrationRequest.dataFormat,
      files);
    return instance.put(`inboxdata`, dto);
  }

  static completeInboxdata(number) {
    return instance.patch(`inboxdata/complete/${number}`);
  }

  static completePartiallyInboxdata(number) {
    return instance.patch(`inboxdata/completePartially/${number}`);
  }

  static rejectPlacementInboxdata(number, rejectionReason) {
    return instance.patch(`inboxdata/${number}/reject-placement`, rejectionReason);
  }
}

export default InboxDataProxy;
