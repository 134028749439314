<template>
  <div class="certificate-info" v-if="modelValue && modelValue.certificateInfo">
    <a target="_blank">
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11 19H6V4H15L19 8V11"
          stroke-width="1.4"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M19 9H14V4"
          stroke-width="1.4"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M11 12H9"
          stroke-width="1.4"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M11 9H9"
          stroke-width="1.4"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M11 15H9"
          stroke-width="1.4"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <circle cx="17" cy="16" r="3" stroke-width="1.4" />
        <path
          d="M15 19V21.5L17 20.75L19 21.5V19"
          stroke-width="1.4"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </a>
    <div class="form">
      <div class="uneditable-field">
        <label class="label uneditable-field__value"
          >Наименование сертификата</label
        >
        <span
          class="uneditable-field__value"
          :class="
            modelValue.certificateInfo.certificateName ? '' : 'nullable-data'
          "
          >{{
            modelValue.certificateInfo.certificateName
              ? modelValue.certificateInfo.certificateName
              : "&lt;Данные не указаны&gt;"
          }}</span
        >
      </div>
      <div class="uneditable-field">
        <label class="label uneditable-field__value">Подписант</label>
        <span
          class="uneditable-field__value"
          :class="modelValue.certificateInfo.fullname ? '' : 'nullable-data'"
          >{{
            modelValue.certificateInfo.fullname
              ? modelValue.certificateInfo.fullname
              : "&lt;Данные не указаны&gt;"
          }}</span
        >
      </div>
      <div class="uneditable-field">
        <label class="label uneditable-field__value">Организация</label>
        <span
          class="uneditable-field__value"
          :class="
            modelValue.certificateInfo.organization ? '' : 'nullable-data'
          "
          >{{
            modelValue.certificateInfo.organization
              ? modelValue.certificateInfo.organization
              : "&lt;Данные не указаны&gt;"
          }}</span
        >
      </div>
      <div class="uneditable-field">
        <label class="label uneditable-field__value">Должность</label>
        <span
          class="uneditable-field__value"
          :class="modelValue.certificateInfo.post ? '' : 'nullable-data'"
          >{{
            modelValue.certificateInfo.post
              ? modelValue.certificateInfo.post
              : "&lt;Данные не указаны&gt;"
          }}</span
        >
      </div>
      <div class="uneditable-field">
        <label class="label uneditable-field__value">Электронная почта</label>
        <span
          class="uneditable-field__value"
          :class="modelValue.certificateInfo.email ? '' : 'nullable-data'"
          >{{
            modelValue.certificateInfo.email
              ? modelValue.certificateInfo.email
              : "&lt;Данные не указаны&gt;"
          }}</span
        >
      </div>
    </div>
  </div>
</template>
<script>
import DocumentSignatureInfo from "./models/documentSignatureInfo";

export default {
  components: {},
  props: {
    modelValue: {
      type: Object,
      default: () => new DocumentSignatureInfo(),
    },
  },
};
</script>
<style scoped>
.certificate-info {
  position: relative;
  display: flex;
  flex-direction: column;
  vertical-align: top;
  height: 24px;
  width: 24px;
}

.certificate-info > a > svg {
  stroke: var(--secondary-blue-darker);
}

.certificate-info:hover > a > svg {
  stroke: var(--primary-blue-lighter);
}

.certificate-info:hover .form {
  display: unset;
}

.certificate-info .form {
  display: none;
  position: absolute;
  top: 100%;
  left: 100%;
  z-index: 20;
  min-width: 320px;
  max-width: 800px;
  max-height: 600px;
  padding: 16px;
  border-radius: 6px;
  background-color: var(--basic-white);
  filter: var(--dropdown-shadow);
}

.uneditable-field:first-of-type {
  margin-top: 0px;
}

.uneditable-field__value {
  font-weight: normal;
  font-size: 16px;
}

.nullable-data {
  color: #b9b9b9;
}
</style>
