<template>
    <button 
        title="Удалить файл" 
        aria-label="Удалить файл">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.3999 13.6L13.5999 2.39999M13.5999 13.6L2.3999 2.39999" stroke="#518CFF" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    </button>
</template>
<script>
export default {
}
</script>