import { ref, computed } from 'vue';
import GeometaProjectProxy from '../proxies/geometaProjectProxy';

const currentProjectAlias = ref(null);
const _projects = ref([]);

export default function useProjectAlias() {
  async function getGeometaProjects() {
    if (_projects.value.length === 0)
      _projects.value = await GeometaProjectProxy.fetch();
    return _projects.value;
  }

  function setProjectAlias(value) {
    if (!value) currentProjectAlias.value = null;
    else currentProjectAlias.value = value;
  }

  async function setProjectByDocument(value) {
    if(value.length === 0)
      return currentProjectAlias.value;
    currentProjectAlias.value = await GeometaProjectProxy.getDocumentProject(value);
  }

  const projectAlias = computed(() => {
    return currentProjectAlias.value;
  });

  const currentProject = computed(() => {
    return _projects.value.find(
      (p) => p.projectAlias === currentProjectAlias.value
    );
  });

  const projects = computed(() => {
    return _projects.value;
  });

  return {
    getGeometaProjects,
    setProjectAlias,
    projectAlias,
    projects,
    currentProject,
    setProjectByDocument
  };
}
