<template>
  <section class="wrapper">
    <div class="no-documents__image"></div>
    <p class="no-documents__text">Здесь появятся добавленные документы.</p>
    <AddRegisrationProcessItemButton :number="number" />
  </section>
</template>

<script>
import AddRegisrationProcessItemButton from './AddRegisrationProcessItemButton';

export default {
  props: {
    number: {
      type: String,
      required: true,
    },
  },
  components: { AddRegisrationProcessItemButton },
};
</script>
<style scoped>
.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.no-documents__image {
  margin-top: 78px;
  margin-bottom: 48px;
  width: 300px;
  height: 200px;
  background-image: url('../../assets/svg/document.svg');
}

.no-documents__text {
  margin-bottom: 24px;
  font-size: 16px;
}
</style>
