<template>
    <div class="form-control">
    <label class="label"><slot></slot></label>
      <!-- <input
        class="field"
        :class="{ 'field--error': errors && errors.length > 0 }"
        type="checkbox"
        :value="modelValue"
        @input="$emit('update:modelValue', $event.target.value)"
        :maxlength="maxLength"
        :readonly="isReadonly"
      /> -->
      <input
        type="checkbox"
        v-model="value"
        @change="onChange"
        :readonly="isReadonly"
      />
      <div v-for="(error, index) of errors" v-bind:key="index">
        <div class="field-message--error">{{ error.$message }}</div>
      </div>
    </div>
</template>

<script>
import { computed } from 'vue';
export default {
  emits: ['update:modelValue'],
    props: {
        modelValue: {
          type: Boolean
        },
        errors: {
          type: Array
        },
        isReadonly: {
          type: Boolean,
          default: false
        }
    },
    setup(props, { emit }) {
      const value = computed({
        get: () => {
          return props.modelValue
        },
        set: (value) => {
          emit('update:modelValue', value)
        }
      })
      return { value }
    }
};
</script>
<style scoped>
</style>