<template>
  <div :class="hasProjects ? 'page-layout' : ''">
    <TheSidebar v-if="hasProjects" class="page-layout__sidebar"></TheSidebar>
    <div id="page-layout" class="page-layout__wrapper">
      <TheNavbar class="page-layout__navbar"></TheNavbar>
      <main>
        <router-view />
      </main>
    </div>
  </div>
</template>

<script>
import TheSidebar from "./TheSidebar.vue";
import TheNavbar from "./navbar/TheNavbar";
import { computed } from 'vue';
import useProjectAlias from "../hooks/projectAlias";

export default {
  components: {
    TheSidebar,
    TheNavbar,
  },
  setup() {
    const { projects } = useProjectAlias();
    const hasProjects = computed(() => {
      return projects.value.length > 0;
    });
    return {
      hasProjects
    }
  },
};
</script>

<style scoped>
.page-layout {
  display: grid;
  grid-template-columns: min-content 1fr;
}
.page-layout__sidebar {
  height: 100vh;
}
.page-layout__wrapper {
  height: 100vh;
  overflow-y: auto;
}
.page-layout__navbar {
  min-height: 76px;
}
</style>
