<template>
  <div class="page-header">
    <div>
      <h3 class="page-header__title title-24">
        <slot name="title"></slot>
      </h3>
      <p class="page-header__subtitle">
        <slot name="subtitle"></slot>
      </p>
    </div>
    <div class="page-header__buttons">
      <slot name="buttons"></slot>
    </div>
  </div>
</template>

<style lang="scss">
@media screen and (max-width: 1030px) {
  .page-header {
    display: flex;
    flex-direction: column;
  }
  .page-header__buttons {
    display: flex;
    justify-content: flex-start !important;
    align-items: center;
    margin-left: 0 !important;
    margin-top: 8px;
  }
}

.page-header {
  display: flex;
  justify-content: space-between;
  padding-top: 32px;
  padding-bottom: 24px;
}

.page-header__title {
  padding-bottom: 10px;
  min-width: 300px;
}

.page-header__subtitle {
  margin: 0;
}

.page-header__buttons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-left: 3%;
  min-width: 40%;
}

.page-header__buttons > :not(:first-child) {
  margin-left: 12px;
}
</style>