<template>
  <div class="navbar-dropdown" :class="{ 'navbar-dropdown--active': showMenu }" data-qa="currentProject">
    <OnClickOutside :do="close">
      <div v-show="hasProjects" class="navbar-dropdown__value" @click="showMenu = !showMenu">
        <p class="navbar-dropdown__text title-20">{{ selectedProjectName }}</p>
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5 8.69995L10 14.2L15 8.69995"
            stroke="#465362"
            stroke-width="1.4"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
    </OnClickOutside>
    <ul class="navbar-dropdown__menu navbar-dropdown__menu--left">
      <li
        class="navbar-dropdown__item"
        v-for="project in projects"
        :key="project.projectAlias"
        @click="selectProject(project)"
        :data-qa="project.projectAlias"
      >
        {{ project.name }}
      </li>
    </ul>
  </div>
</template>

<script>
import useProjectAlias from "../../hooks/projectAlias";
import { ref, watch, computed } from "vue";
import { useRouter } from "vue-router";
import OnClickOutside from "../basic/OnClickOutside.vue";

export default {
  components: { OnClickOutside },
  setup() {
    const showMenu = ref(false);
    const { projects, currentProject } = useProjectAlias();
    const selectedProjectName = ref("");
    const router = useRouter();
    const { setProjectAlias } = useProjectAlias();

    watch(currentProject, (newVal) => {
      selectedProjectName.value = newVal.name;
    });

     const hasProjects = computed(() => {
      return projects.value.length > 0;
    });

    function selectProject(project) {
      setProjectAlias(project.projectAlias);
      showMenu.value = false;
      router.push({
        name: "home",
      });
    }

    function close() {
      showMenu.value = false;
    }

    return { showMenu, projects, selectedProjectName, selectProject, hasProjects, close };
  },
};
</script>

<style scoped>
.navbar-dropdown {
  position: relative;
}

.navbar-dropdown__value {
  display: flex;
  align-items: center;
  padding-top: 9px;
  padding-bottom: 9px;
  cursor: pointer;
}

.navbar-dropdown__text {
  margin-right: 5px;
}

.navbar-dropdown__value:hover .navbar-dropdown__text {
  color: var(--primary-blue-light);
}

.navbar-dropdown__value:hover path {
  stroke: var(--primary-blue-light);
}

.navbar-dropdown__menu {
  position: absolute;
  top: 100%;
  z-index: 20;
  display: none;
  width: 100%;
  min-width: 200px;
  max-width: 800px;
  max-height: 270px;
  padding: 6px 0px;
  margin-top: 0;
  overflow-y: auto;
  border: 1px solid var(--basic-grey);
  border-radius: 6px;
  background-color: var(--basic-white);
  filter: var(--dropdown-shadow);
}

.navbar-dropdown--active .navbar-dropdown__menu {
  display: block;
}

.navbar-dropdown__menu--left {
  left: 0%;
}

.navbar-dropdown__item {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 6px 12px;
  color: var(--secondary-blue-darker);
  font-size: 16px;
  line-height: 20px;
  cursor: pointer;
}

.navbar-dropdown__item:hover {
  background-color: var(--secondary-blue-lighter);
  color: var(--basic-black);
}

a.navbar-dropdown__item {
  text-align: inherit;
  white-space: nowrap;
  width: 100%;
}
</style>
