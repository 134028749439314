<template>
  <div v-if="isActive" :data-step="name">
    <!--  <div> -->
    <slot></slot>
    <!-- </div> -->
  </div>
</template>

<script>
import { toRefs, inject, watchEffect } from "vue";
export default {
  props: {
    name: { required: true },
    description: { type: String, default: "" },
    isActive: { default: false },
    done: { default: false },
  },
  setup(props) {
    const { isActive, done, name } = toRefs(props);
    const steps = inject("WizardSteps");
    watchEffect(() => {
      if (isActive.value) steps.selectedStep = name.value;
    });
    watchEffect(() => {
      if (done.value) steps.doneSteps.push(name.value);
      else {
        const indx = steps.doneSteps.indexOf(name.value);
        if (indx > -1) steps.doneSteps.splice(indx, 1);
      }
    });
    return {};
  },
};
</script>
