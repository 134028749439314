import {computed, reactive, ref, watch} from 'vue';

function convertToQueryString(additionalParameters) {
	if (!additionalParameters)
		return null;
	const props = Object.getOwnPropertyNames(additionalParameters);
	if (!props.length)
		return null;
	//преобразуем исходный объект в тот который позволит получить итоговый URL который распарсится на контроллере корректно
	//почему так:
	//допустим есть объект obj = {foo:['a', 'b'], bar: 123}
	//если передать его как есть на вход new URLSearchParams(obj).toString()
	//то итоговый урл будет выглядеть как foo=a,b&bar=123
	//контроллер на апи без танцев с бубном понимает формат foo=a&foo=b&bar=123
	//чтобы такого добиться необходимо передавать в new URLSearchParams(obj).toString() массив вида:
	//[['foo', 'a'], ['foo', 'b'], ['bar', 123]]
	//это и делаем ниже
	const queryParamsArray = [];
	props.forEach(tag => {
		const val = additionalParameters[tag];
		if (Array.isArray(val))
			val.forEach(x => queryParamsArray.push([tag, x]))
		else
			queryParamsArray.push([tag, val]);
	});
	return new URLSearchParams(queryParamsArray).toString();
}

export default function useSearch(baseUrl, page = 1, limit = 20, search = '', additionalParameters = null) {
	const state = reactive({
		baseUrl,
		page,
		limit,
		search: ref(search),
		additionalParameters: additionalParameters
	});
	const url = computed(
		() => {
			const mainUrl = `${state.baseUrl}?page=${state.page}&limit=${state.limit}&search=${state.search}`;
			const additionalQuery = convertToQueryString(state.additionalParameters);
			if (!additionalQuery || !additionalQuery.length)
				return mainUrl;
			return `${mainUrl}&${additionalQuery}`;
		}
	);


	watch(
		()=>[state.search, state.additionalParameters], () => {
			state.page = 1;//сбрасываем страницу при изменении параметров поиска
		});

	// watch(	
	// 	() => state.search,
	// 	() => {
	// 		state.page = 1;
	// 	}
	// );

	return {state, url};
}
