import instance from "../api.js";
import { RegistrationProcess } from "../models/registrationProcess";
import { RegistrationDocument } from "../models/registrationDocument";
import {
  NewRegistrationItemDto,
  UpdateRegistrationItemDto,
} from "../dtos/registrationItemDto";
import { StorageFileDto } from "../dtos/storageFileDto";
import { RegisterDocumentDto } from "../dtos/registerDocumentDto.js";

class RegistrationProcessProxy {
  static async fetch(number) {
    return instance
      .get(`registration-processes/${number}`, {
        validateStatus: function(status) {
          return status < 500; // Resolve only if the status code is less than 500
        },
      })
      .then((response) => {
        if (response.status && response.status === 404) return null;
        //todo NullObject
        else return RegistrationProcess.fromDto(response);
      });
  }

  static async create(number) {
    const dto = await instance.post(`registration-processes/${number}`);
    return RegistrationProcess.fromDto(dto);
  }

  static async delete(number) {
    return instance.delete(`registration-processes/${number}`);
  }

  static async getItem(number, itemId) {
    const dto = await instance.get(
      `registration-processes/${number}/items/${itemId}`
    );
    return RegistrationDocument.fromDto(dto);
  }

  static async addItem(number, item) {
    const files = item.files
      .filter((x) => !x.isTerritorialPlanningRegulation)
      .map(
        (f) =>
          new StorageFileDto(f.id, f.name, f.fileUrl, f.contentType, f.size)
      );
    const territorialPlanningRegulationFiles = item.files
      .filter((x) => x.isTerritorialPlanningRegulation)
      .map(
        (f) =>
          new StorageFileDto(f.id, f.name, f.fileUrl, f.contentType, f.size)
      );
    const dto = new NewRegistrationItemDto(
      item.documentType.id,
      files,
      territorialPlanningRegulationFiles,
      JSON.stringify(item.stateSnapshot),
      JSON.stringify(item.geometrySource.geometrySourceInfo),
      item.geometrySource.geometry !== ""
        ? JSON.stringify(item.geometrySource.geometry)
        : null
    );
    const docDto = await instance.post(
      `registration-processes/${number}/items`,
      dto
    );
    return RegistrationDocument.fromDto(docDto);
  }

  static async removeItem(number, itemId) {
    return instance.delete(`registration-processes/${number}/items/${itemId}`);
  }

  static async updateItem(number, item) {
    const files = item.files
      .filter((x) => !x.isTerritorialPlanningRegulation)
      .map(
        (f) =>
          new StorageFileDto(f.id, f.name, f.fileUrl, f.contentType, f.size)
      );
    const territorialPlanningRegulationFiles = item.files
      .filter((x) => x.isTerritorialPlanningRegulation)
      .map(
        (f) =>
          new StorageFileDto(f.id, f.name, f.fileUrl, f.contentType, f.size)
      );
    const dto = new UpdateRegistrationItemDto(
      item.id,
      item.documentType.id,
      files,
      territorialPlanningRegulationFiles,
      JSON.stringify(item.stateSnapshot),
      item.geometrySource.geometrySourceInfo
        ? JSON.stringify(item.geometrySource.geometrySourceInfo)
        : null,
      item.geometrySource.geometry
        ? JSON.stringify(item.geometrySource.geometry)
        : null,
      item.affectedMunicipalities
    );
    return await instance.put(
      `registration-processes/${number}/items/${item.id}`,
      dto
    );
  }

  static async registerDocument(
    number,
    docId,
    regNumber,
    regDate,
    docJson,
    sig,
    sigFN
  ) {
    const regDocDto = new RegisterDocumentDto(
      number,
      docId,
      regNumber,
      regDate,
      docJson ? JSON.stringify(docJson) : null,
      sig,
      sigFN
    );

    return await instance.patch(`register-document`, regDocDto);
  }

  static async getSignData(docId) {
    return await instance.get(`register-document/signdata/${docId}`);
  }
}

export default RegistrationProcessProxy;
