<template>
  <div class="page" data-qa="registrationRequestCreatePage">
    <page-header class="container">
      <template #title>Создание записи реестра учета сведений</template>
      <template #subtitle>
        <to-reesters-link data-qa="back">Назад в реестр учета сведений</to-reesters-link>
      </template>
      <template #buttons>
        <!-- <toolbar @create="createRegistrationRequest"></toolbar> -->
        <toolbar
          @save="createRegistrationRequest"
          @readyToInboxData="createInboxData"
        ></toolbar>
      </template>
    </page-header>
    <reqistration-request
      class="box container"
      v-model="registrationRequest"
      :validator="validator"
    >
    </reqistration-request>
  </div>
</template>
<script>
import ReqistrationRequest from "./RegistrationRequest";
import Toolbar from "./RegistrationRequestPageToolbar";
import ToReestersLink from "../basic/ToReestersLink";
import { useRouter } from "vue-router";
import useValidator from "./validator";
import useRegistrationRequest from "../../hooks/registrationRequest";

export default {
  components: {
    ReqistrationRequest,
    Toolbar,
    ToReestersLink,
  },
  setup() {
    const router = useRouter();

    const {
      create,
      registrationRequest,
      createInboxdata,
      remove,
    } = useRegistrationRequest();

    const validator = useValidator(registrationRequest);

    async function createRegistrationRequest() {
      validator.value.$touch();
      if (validator.value.$invalid) return;

      await create(registrationRequest.value);
      router.push({
        name: "registrationRequest",
        params: { number: registrationRequest.value.number },
      });
    }

    let isInboxDataCreating = false;

    async function createInboxData() {
      if (!isInboxDataCreating) {
        isInboxDataCreating = true;

        validator.value.$touch();
        if (validator.value.$invalid)
        {
          isInboxDataCreating = false;
          return;
        } 
        await create(registrationRequest.value);
        await createInboxdata();
        await remove(registrationRequest.value.number);
        router.push({
          name: "inboxData",
          params: { number: registrationRequest.value.number },
        });

        isInboxDataCreating = false;
      }
    }

    return {
      registrationRequest,
      createRegistrationRequest,
      validator,
      createInboxData,
    };
  },
};
</script>