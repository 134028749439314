<template>
  <div>
    <ul class="tabs" data-qa="tabs">
      <li
        v-for="(tab, index) in tabs"
        :key="index"
        @click="selectTab(index)"
        class="tabs__item"
        :class="[
          {
            'tabs__item--active': selectedIndex == index,
            'tabs__item--hide': tab.props.hasRight === false,
          },
        ]"
      >
        <a>
          {{ tab.props.name }}
        </a>
      </li>
    </ul>
    <div class="tabs__item-content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs, provide, watchEffect } from "vue";
export default {
  setup(_, { slots }) {
    const state = reactive({
      selectedIndex: 0,
      tabs: [],
      count: 0,
    });
    provide("Tabs", state);
    watchEffect(() => {
      if (slots.default) {
        state.tabs = slots.default();
      }
    });

    function selectTab(index) {
      state.selectedIndex = index;
    }

    return {
      selectTab,
      ...toRefs(state),
    };
  },
};
</script>
<style scoped>
.tabs {
  list-style: none;
  height: 40px;
  border-bottom: 1px solid var(--basic-grey);
  padding-left: 0;
}

.tabs__item {
  display: inline-block;
  height: 40px;
  padding: 0 28px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  cursor: pointer;
}

.tabs__item a {
  color: var(--secondary-blue-darker);
}

.tabs__item:hover a {
  color: var(--basic-black);
}

.tabs__item--active a {
  color: var(--primary-blue-lighter);
}

.tabs__item--active:hover a {
  color: var(--primary-blue-lighter);
}

.tabs__item--active {
  position: relative;
}

.tabs__item--hide {
  display: none;
}

.tabs__item--active::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 3px;
  border-radius: 10px;
  background-color: var(--primary-blue-lighter);
  left: 0;
  bottom: -1px;
}
</style>

