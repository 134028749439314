import useVuelidate from "@vuelidate/core";
import { helpers, required, minValue, maxValue, maxLength } from "@vuelidate/validators";
import subjectValidators from "@/models/subjectValidators"

const rules = {
  outgoingNumber: {
    maxLength: helpers.withMessage("Количество символов не должно превышать 50", maxLength(50)),
  },
  requestDate: {
    minValue: helpers.withMessage("Дата должна быть позднее 01.01.1970", minValue(new Date(1970, 0, 1))),
    required: helpers.withMessage("Заполните поле", required),
    maxValue: helpers.withMessage("Дата должна быть не позднее сегодняшнего дня", maxValue(new Date(new Date().setHours(23, 59, 59)))),
  },
  outgoingDate: {
    minValue: helpers.withMessage("Дата должна быть позднее 01.01.1970", minValue(new Date(1970, 0, 1))),
    maxValue: helpers.withMessage("Дата должна быть не позднее сегодняшнего дня", maxValue(new Date(new Date().setHours(23, 59, 59)))),
  },
  wayOfReceival: { required: helpers.withMessage("Заполните поле", required) },
  dataFormat: { required: helpers.withMessage("Заполните поле", required) },
  requestSender: { 
    required: helpers.withMessage("Заполните поле", required),
    ...subjectValidators
  }
};

function useValidator(data) {
  return useVuelidate(rules, data);
}

export default useValidator