export class StorageFile {
  constructor(name, fileUrl, contentType, size) {    
    this.name = name;
    this.fileUrl = fileUrl;
    this.contentType = contentType;
    this.size = size;
  }
  get id(){
    return this.fileUrl;
  }
}