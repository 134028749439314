import instance from '../api.js';
import DictionaryValue from '../models/dictionaryValue';

class RegistrationRequestDictionaryProxy {

  static async getWaysOfReceival() {
    const dtos = await instance.get(`dictionaries/registration-request/ways-of-receival`);
    return DictionaryValue.fromDtos(dtos);
  }
  static async getDataFormats() {
    const dtos = await instance.get(`dictionaries/registration-request/data-formats`);
    return DictionaryValue.fromDtos(dtos);
  }
  static async getStatuses() {
    const dtos = await instance.get(`dictionaries/registration-request/statuses`);
    return DictionaryValue.fromDtos(dtos);
  }
}

export default RegistrationRequestDictionaryProxy;
