import instance from "../../../api";
import CorrectionHistory from "../models/correctionHistory";
import Correction from "../models/correction";
import DocumentWithCorrection from "../models/documentWithCorrection";

class CorrectionHistoryProxy {
    static async getCorrectionHistory(documentNumber, sectionNumber) {
        const result = await instance.get(
            `correction-history/section/${sectionNumber}/document/${documentNumber}`
        )

        const correctionHistory = result.map(
            (r) => new CorrectionHistory(r.id, r.author, r.date, r.isFirst)
        );

        return correctionHistory;
    }

    static async getCorrectionInfo(correctionId, sectionNumber) {
        const correctionDto = await instance.get(
            `correction-history/section/${sectionNumber}/correction/${correctionId}`
        )

        return Correction.fromDto(correctionDto);
    }

    static async getDocumentWithCorrection(sectionNumber, documentNumber, correctionId) {
        const document = await instance.get(
            `correction-history/section/${sectionNumber}/document/${documentNumber}/correction/${correctionId}`
        )
        const inboxDataNumber = await instance.get(
            `gisogd-documents/${documentNumber}/inboxdata`
        );

        return DocumentWithCorrection.fromDto(document, inboxDataNumber);
    }
}

export default CorrectionHistoryProxy;