<template>
  <div>
    <div class="form-control">
      <MultiSelectField
          :modelValue="geometrySourceInfo.kn"
          @update:modelValue="updateKn($event)"
          :options="vectors"
          @reload="fetchWithDelay"
          data-qa="fromCadastryNumber"
          keyProp="externalId"
      >
        <label class="label">Кадастровый номер</label>
      </MultiSelectField>
    </div>
    <br/>
    <GeometryToolbar
        :apply-geometry-fn="applyGeometry"
        :remove-geometry-fn="removeGeometry"
    ></GeometryToolbar>
  </div>
</template>

<script>
import GeometryProxy from "../../../proxies/geometryProxy";
import MultiSelectField from "../../basic/MultiSelectField.vue";
import {useVectorLandPlotEgrn} from "../../../hooks/vector";
import {
  GeometrySource,
  GeometrySourceInfoFromKN,
} from "../../../models/geometrySource";
import GeometryToolbar from "./GeometryToolbar";
import {inject, ref} from "vue";

export default {
  props: {
    geometrySourceInfo: {
      type: Object,
      required: true,
    },
    geometry: {
      type: [Object, String],
      required: true,
    },
  },
  emits: ["update:geometrySource", "error"],
  components: {GeometryToolbar, MultiSelectField},
  setup(props, {emit}) {
    const {vectors, fetchWithDelay} = useVectorLandPlotEgrn();
    const toast = inject("toast");
    const geometry = ref({});

    function applyGeometry() {
      if (!props.geometrySourceInfo.kn) {
        toast.warning(
            "Чтобы сформировать территорию действия, укажите кадастровый номер земельного участка."
        );
        return;
      }
      removeGeometry();
      try {
        GeometryProxy.getFromKN(
            props.geometrySourceInfo.kn.filter(
                (x) => JSON.stringify(x) !== JSON.stringify({})
            )
        ).then((result) => {
          geometry.value = result;
          emit(
              "update:geometrySource",
              new GeometrySource(
                  new GeometrySourceInfoFromKN(props.geometrySourceInfo.kn),
                  geometry.value
              )
          );
          if (geometry.value.original.geometry.includes("Line"))
          {
            emit("error", "Тип геометрии у объекта должен быть полигон");
          }
        }).catch((error) => {
          if (error == "Cancel") return;
          emit("error", "У объекта нет геометрии");
        });
        // DS: Try-catch оставлен в целях обработки ошибок основанных не на promises
      } catch
          (error) {
        if (error == "Cancel") return;
        emit("error", "У объекта нет геометрии");
      }
    }

    function onKeyPress(event) {
      let char = String.fromCharCode(event.keyCode);
      if (/^[:0-9]+$/.test(char)) return true;
      else event.preventDefault();
    }

    function removeGeometry() {
      emit(
          "update:geometrySource",
          new GeometrySource(
              new GeometrySourceInfoFromKN(props.geometrySourceInfo.kn),
              ''
          )
      );
    }

    function updateKn(kn) {
      emit(
          "update:geometrySource",
          new GeometrySource(
              new GeometrySourceInfoFromKN(kn),
              props.geometrySourceInfo.geometry
          )
      );
    }

    return {
      vectors,
      fetchWithDelay,
      applyGeometry,
      onKeyPress,
      removeGeometry,
      updateKn,
    };
  },
};
</script>
