import DocIzBase from "./docIzBase";

export default class DocIZRs extends DocIzBase {
	constructor(
		id,
		docStatus,
		docClass,
		docName,
		docNum,
		docDate,
		orgName,
		note,
		zu = [],
		description,
		documentsOriginRegistration = [],
		isChangeForProlongation = false,
		prolongationReason,
		prolongationLimit,
		prolongationDate,
		affectedMunicipalities = [],
		guid
	) {
		super(
			id,
			docStatus,
			docClass,
			docName,
			docNum,
			docDate,
			orgName,
			note,
			description,
			documentsOriginRegistration,
			affectedMunicipalities,
			guid
		);
		this.isChangeForProlongation = isChangeForProlongation;
		this.prolongationReason = prolongationReason;
		this.prolongationLimit = prolongationLimit;
		this.prolongationDate = prolongationDate;
		this.zu = zu;
	}
}
