export default function useSectionRoutes() {
  const sections = [
    {
      number: 1,
      name: 'Документы ТП РФ',
    },
    {
      number: 2,
      name: 'Документы ТП двух и более СРФ, документы ТП СРФ',
    },
    {
      number: 3,
      name: 'Документы ТП МО',
    },
    {
      number: 4,
      name: 'Нормативы градостроительного проектирования',
    },
    {
      number: 5,
      name: 'Градостроительное зонирование',
    },
    {
      number: 6,
      name: 'Правила благоустройства территории',
    },
    {
      number: 7,
      name: 'Планировка территории',
    },
    {
      number: 8,
      name: 'Инженерные изыскания',
    },
    {
      number: 9,
      name: 'Искусственные земельные участки',
    },
    {
      number: 10,
      name: 'Зоны с особыми условиями использования территории',
    },
    {
      number: 11,
      name: 'Материалы по надземным и подземным коммуникациям',
    },
    {
      number: 12,
      name: 'Резервирование земель и изъятие земельных участков',
    },
    {
      number: 13,
      name: 'Дела о застроенных или подлежащих застройке земельных участках',
    },
    {
      number: 14,
      name: 'Программы реализации документов территориального планирования',
    },
    {
      number: 15,
      name: 'Особо охраняемые природные территории',
    },
    {
      number: 16,
      name: 'Лесничества',
    },
    {
      number: 17,
      name: 'Информационные модели объектов капитального строительства',
    },
    {
      number: 18,
      name: 'Иные сведения, документы, материалы',
    },
  ];

  function getSection(sectionNumber) {
    return sections.find((s) => s.number === +sectionNumber);
  }

  return {
    sections,
    getSection,
  };
}
