import { ref } from 'vue';
import CorrectionHistoryProxy from '../proxies/correctionHistoryProxy';

export default function useCorrectionHistory(documentNumber, sectionNumber) {
    const correctionHistory = ref([]);
    const correctionInfo = ref(null);

    fetchCorrectionHistory(documentNumber, sectionNumber);

    async function fetchCorrectionHistory(documentNumber, sectionNumber) {
        correctionHistory.value = await CorrectionHistoryProxy.getCorrectionHistory(
            documentNumber,
            sectionNumber
        );
    }

    async function getCorrectionInfo(correctionId, sectionNumber) {
        correctionInfo.value = await CorrectionHistoryProxy.getCorrectionInfo(correctionId, sectionNumber);
        return correctionInfo.value;
    }

    async function getDocumentWithCorrection(sectionNumber, documentNumber, correctionId) {
        return await CorrectionHistoryProxy.getDocumentWithCorrection(sectionNumber, documentNumber, correctionId);
    }

    return {
        correctionHistory,
        getCorrectionInfo,
        getDocumentWithCorrection,
        fetchCorrectionHistory
    };
}