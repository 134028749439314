import useVuelidate from '@vuelidate/core';
// import { helpers, required, minValue } from '@vuelidate/validators';

// const rules = {
//   docNum: { required: helpers.withMessage('Заполните поле', required) },
//   docDate: {
//     minValue: helpers.withMessage('Дата должна быть позднее 01.01.1970', minValue(new Date(1970, 0, 1))),
//     required: helpers.withMessage('Заполните поле', required)
//   },
//   orgName: { required: helpers.withMessage('Заполните поле', required) },
// };

function useValidator(data, appendRules) {
  let tempRules = {};
  // for (const [key, value] of Object.entries(rules)) {
  //   tempRules[key] = value;
  // }
  if (appendRules !== undefined)
    for (const [key, value] of Object.entries(appendRules)) {
      tempRules[key] = value;
    }
  return useVuelidate(tempRules, data);
}

export default useValidator;
