import instance from '../api.js';

const baseURL =
  process.env.NODE_ENV === 'production'
    ? window.location.origin + '/api/search/spatialObjects'
    : 'http://localhost:65070/api/search/spatialObjects';

class VectorProxy {
  static async fetchLandPlot(filter) {
    return await instance.post(`/land-plot`, { value: filter }, { baseURL });
  }
  static async fetchLandPlotOther(filter) {
    return await instance.post(`/land-plot-other`, { value: filter }, { baseURL });
  }
  static async fetchLandPlotEgrn(filter) {
    return await instance.post(`/land-plot-egrn`, { value: filter }, { baseURL });
  }
  static async fetchLandPlotPPM(filter) {
    return await instance.post(`/land-plot-ppm`, { value: filter }, { baseURL });
  }
  static async fetchLandPlotEgrnOther(filter) {
    return await instance.post(`/land-plot-egrn-other`, { value: filter }, { baseURL });
  }
  static async fetchOks(filter) {
    return await instance.post(`/oks`, { value: filter }, { baseURL });
  }
  static async fetchEgrnOks(filter) {
    return await instance.post(`/egrn-oks`, { value: filter }, { baseURL });
  }
  static async fetchProjectHB(filter) {
    return await instance.post(`/project-hb`, { value: filter }, { baseURL });
  }
  static async fetchEasement(filter) {
    return await instance.post(`/easement`, { value: filter }, { baseURL });
  }
  static async fetchArtificialLandPlot(filter) {
    return await instance.post(
      `/artificial-land-plot`,
      { value: filter },
      { baseURL }
    );
  }
  static async fetchForestry(filter) {
    return await instance.post('/forestry', { value: filter }, { baseURL });
  }
  static async fetchForestLandPlot(filter) {
    return await instance.post(
      '/forest-land-plot',
      { value: filter },
      { baseURL }
    );
  }
  static async fetchZouit(filter) {
    return await instance.post('/zouit', { value: filter }, { baseURL });
  }

  static async fetchSettlement(filter) {
    return await instance.get(`/settlement?filter=${filter}&limit=5`, {
      baseURL,
    });
  }
  static async fetchOopt(filter) {
    return await instance.get(`/oopt?filter=${filter}&limit=5`, {
      baseURL,
    });
  }
  static async fetchLandBoundary(filter) {
    return await instance.post('/land-boundary', { value: filter }, { baseURL });
  }
}

export default VectorProxy;
