import DocIzBase from "./docIzBase";

export default class DocSection10Iz extends DocIzBase {
  constructor(
      id,
      docStatus,
      docClass,
      docName,
      docNum,
      docDate,
      orgName,
      note,
      zouitRef = [],
      description,
      documentsOriginRegistration = [],
      affectedMunicipalities = [],
      guid
  ) {
    super(
        id,
        docStatus,
        docClass,
        docName,
        docNum,
        docDate,
        orgName,
        note,
        description,
        documentsOriginRegistration,
        affectedMunicipalities,
        guid
    );
    this.zouitRef = zouitRef || [];
  }
}