<template>
  <div v-if="totalPages > 1" class="pagination" data-qa="pagination">
    <a
      v-if="currentPage > 1"
      class="pagination__link"
      @click="goToPreviousPage()"
      data-qa="goToPreviousPage"
    >
      <svg
        width="6"
        height="10"
        viewBox="0 0 6 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5 9L1 5L5 1"
          stroke="#90A0B7"
          stroke-width="1.4"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </a>
    <a
      v-for="page in localTotalPages"
      v-bind:key="page"
      class="pagination__link"
      :class="{ 'pagination__link--active': page === currentPage, 'pagination__link--dots':page === '...' }"
      @click="changePage(page)"
      :data-qa="page"
    >
      {{ page }}
    </a>
    <a
      v-if="currentPage < totalPages"
      class="pagination__link"
      @click="goToNextPage()"
      data-qa="goToNextPage"
    >
      <svg
        width="6"
        height="10"
        viewBox="0 0 6 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1 9L5 5L1 1"
          stroke="#90A0B7"
          stroke-width="1.4"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </a>
  </div>
</template>
<script>
import { computed } from 'vue';
export default {
  emits: ["update:currentPage"],
  props: {
    currentPage: {
      type: Number,
      required: true,
    },
    totalPages: {
      type: Number,
    },
    zoneLenght: {
      type: Number,
      default: 3,
    },
  },
  setup(props, { emit }) {
    function changePage(page) {
      if (page !== '...')
        emit("update:currentPage", page);
    }
    function goToPreviousPage() {
      emit("update:currentPage", props.currentPage - 1);
    }
    function goToNextPage() {
      emit("update:currentPage", props.currentPage + 1);
    }

    const localTotalPages = computed(() => {
      let pages = [];
      for (let index = 1; index <= props.totalPages; index++) {
        if (index === 1 || index === props.totalPages) {
          pages.push(index);
          continue;
        }
        if (Math.abs(index - props.currentPage) <= props.zoneLenght) {
          pages.push(index);
        } else {
          if (pages[pages.length - 1] !== "...")
            pages.push("...");
        }

      }
      return pages;
    })

    return { changePage, goToPreviousPage, goToNextPage, localTotalPages };
  },
};
</script>
<style scoped lang="scss">
.pagination {
  margin-top: 38px;
  text-align: right;
}

.pagination__link {
  font-family: inherit;
  font-size: 16px;
  border-radius: 2px;
  padding: 1px 4px 3px;
  margin-left: 12px;
  border: none;
  outline: none;
  cursor: pointer;
  color: var(--secondary-blue-darker);
}

.pagination__link:hover {
  color: var(--primary-blue-lighter);
}

.pagination__link--dots {
  cursor: default;
}

.pagination__link:active,
.pagination__link:focus {
  color: var(--primary-blue-dark);
}

.pagination__link--active {
  color: var(--basic-white);
  background-color: var(--primary-blue-lighter);
}

.pagination__link--active:hover {
  color: var(--basic-white);
  background-color: var(--primary-blue-light);
}

.pagination__link--active:active,
.pagination__link--active:focus {
  color: var(--basic-white);
  background-color: var(--primary-blue);
}

.pagination__link:hover path {
  stroke: var(--primary-blue-lighter);
}

.pagination__link:active path,
.pagination__link:focus path {
  stroke: var(--primary-blue-dark);
}
</style>
