<script>
import { ref, computed, toRefs } from "vue";
export default {
  props: {
    modelValue: {
      type: Array,
      default: () => [],
    },
    fieldName: {
      type: String,
      default: "displayName",
    },
  },
  setup(props, ctx) {
    const { modelValue } = toRefs(props);
    const filterStr = ref("");
    const filtredOptions = computed(() => {
      return modelValue.value.filter((x) =>
        x[props.fieldName].toLowerCase().includes(filterStr.value.trim().toLowerCase())
      );
    });

    function filter(value) {
      filterStr.value = value;
    }

    return () =>
      ctx.slots.default({
        filter,
        filtredOptions,
      });
  },
};
</script>
<style scoped>
</style>