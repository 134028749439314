import DocBase from "./docBase";
import DocEGRZ from "./docEGRZ";
import DocGPZU from "./docGPZU";
import DocRS from "./docRS";
import DocWithOKS from "./docWithOKS";
import DocTechPlan from "./docTechPlan";
import DocRV from "./docRV";
import GisogdInfoModelOKS from "./gisogdInfoModelOKS";
import DocFieldsNotificationAboutBuilding from "./docFieldsNotificationAboutBuilding";
import DocWithServitute from "./docWithServitute";
import DocSection1 from "./docSection1";
import DocSection5 from "./docSection5";
import DocSection7 from "./docSection7";
import DocSection8 from "./docSection8";
import DocSection9 from "./docSection9";
import DocSection10 from "./docSection10";
import DocSection12 from "./docSection12";
import DocSection14 from "./docSection14";
import DocSection15 from "./docSection15";
import DocForestRegulation from "./docForestRegulation";
import DocGisogdForestProject from "./docGisogdForestProject";
import DocGisogdLesParcelPd from "./docGisogdLesParcelPd";
import DocIzSTPRF from "./docIzSTPRF";
import DocIZRs from "./docIZRs";
import DocSection10Iz from "./docSection10Iz";
import DocExpertise from "./docExpertise";
import DocVectorZu from "./docVectorZu";
import DocGPLoc from "./docGPLoc";
import DocNGPLoc from "./docNGPLoc";
import DocResolutionUseLand from "./DocResolutionUseLand";
import DocIzBase from "./docIzBase";

const defaultFields = "DocFields";
const defaultFieldsZu = "DocFieldsZu";

export default class DocFactory {
  static getDocTypeName(documentType) {
    if (!documentType) return null;
    switch (documentType.id) {
      case 101:
        return "DocFieldsSection1";
      case 201:
      case 202:
      case 301:
        return defaultFields;
      case 302:
        return "DocFieldsWithSettlement";
      case 303:
        return defaultFields;
      case 402:
        return "DocFieldsWithSettlement";
      case 401:
      case 403:
      case 404:
        return defaultFields;
      case 501:
        return "DocFieldsSection5";
      case 601:
        return defaultFields;
      case 701:
      case 702:
        return "DocFieldsSection7";
      case 801:
        return "DocFieldsSection8";
      case 901:
      case 902:
      case 903:
        return "DocFieldsSection9";
      case 1001:
      case 1002:
        return "DocFieldsSection10";
      case 1201:
      case 1203:
        return "DocFieldsZuPPM";
      case 1202:
      case 1204:
        return "DocFieldsSection12";
      case 1306:
        return "DocFieldsIZRS";
      case 1308:
      case 1309:
      case 1313:
      case 1314:
      case 1316:
      case 1317:
      case 1328:
      case 1329:
      case 1331:
      case 1332:
        return "DocFieldsZuEgrn";
      case 1305:
      case 1311:
      case 1312:
      case 1322:
      case 1323:
      case 1325:
      case 1326:
      case 1338:
        return defaultFieldsZu;
      case 1334:
        return "DocFieldsZuOther";
      case 1335:
        return "DocFieldsResolutionUseLand";
      case 1302:
      case 1303:
        return "DocFieldsEGRZ_EGRN";
      case 1310:
        return "DocFieldsEGRZ";
      case 1307:
        return "DocFieldsExpertise";
      case 1301:
        return "DocFieldsGPZU";
      case 1318:
        return "DocFieldsRV";
      case 1304:
        return "DocFieldsRS";
      case 1319:
        return "DocFieldsTechPlan";
      case 1324:
        return "DocFieldsWithOKSHb";
      case 1320:
      case 1321:
        return "DocFieldsNotificationAboutBuilding";
      case 1327:
        return "DocFieldsNotificationAboutBuildingEgrn";
      case 1333:
      case 1330:
        return "DocFieldsNotificationAboutDemolition";
      case 1336:
      case 1337:
        return "DocFieldsWithServitute";
      case 1401:
      case 1402:
      case 1403:
      case 1404:
      case 1405:
      case 1406:
        return "DocFieldsSection14";
      case 1501:
        return "DocFieldsSection15";
      case 1601:
        return "DocFieldsForestRegulation";
      case 1602:
        return "DocFieldsGisogdForestProject";
      case 1603:
        return "DocFieldsGisogdLesParcelPd";
      case 1701:
        return "DocFieldsGisogdInfoModelOKS";
      case 1801:
        return defaultFields;
      case 21001:
        return 'DocFieldsIzDocument10'      
      case 20102:
      case 20202:
      case 20201:
      case 20301:
      case 20302:
      case 20303:
      case 20401:
      case 20501:
      case 20601:
      case 20701:
      case 20702:
      case 21304:
      case 21401:
      case 21501:
      case 21601:      
        return "DocFieldsBaseIz";
      case 20101:      
        return "DocFieldsIzSTPRF";
      default:
        throw new Error("Неизвестный тип документа");
    }
  }

  static create(documentType, stateSnapshot) {
    switch (documentType.id) {
      case 101:
        return new DocSection1(
          stateSnapshot.id,
          stateSnapshot.docStatus,
          stateSnapshot.docClass,
          stateSnapshot.docName,
          stateSnapshot.docNum,
          stateSnapshot.docDate ? new Date(stateSnapshot.docDate) : null,
          stateSnapshot.orgName,
          stateSnapshot.canceledDocuments,
          stateSnapshot.note,
          stateSnapshot.stprfName,
          stateSnapshot.affectedMunicipalities,
          stateSnapshot.guid
        );
      case 201:
      case 202:
      case 301:
      case 303:
        return new DocBase(
          stateSnapshot.id,
          stateSnapshot.docStatus,
          stateSnapshot.docClass,
          stateSnapshot.docName,
          stateSnapshot.docNum,
          stateSnapshot.docDate ? new Date(stateSnapshot.docDate) : null,
          stateSnapshot.orgName,
          stateSnapshot.canceledDocuments,
          stateSnapshot.note,
          stateSnapshot.affectedMunicipalities,
          stateSnapshot.guid
        );
      case 302:
        return new DocGPLoc(
          stateSnapshot.id,
          stateSnapshot.docStatus,
          stateSnapshot.docClass,
          stateSnapshot.docName,
          stateSnapshot.docNum,
          stateSnapshot.docDate ? new Date(stateSnapshot.docDate) : null,
          stateSnapshot.orgName,
          stateSnapshot.canceledDocuments,
          stateSnapshot.note,
          stateSnapshot.settlement,
          stateSnapshot.affectedMunicipalities,
          stateSnapshot.guid
        );
      case 402:
        return new DocNGPLoc(
          stateSnapshot.id,
          stateSnapshot.docStatus,
          stateSnapshot.docClass,
          stateSnapshot.docName,
          stateSnapshot.docNum,
          stateSnapshot.docDate ? new Date(stateSnapshot.docDate) : null,
          stateSnapshot.orgName,
          stateSnapshot.canceledDocuments,
          stateSnapshot.note,
          stateSnapshot.settlement,
          stateSnapshot.affectedMunicipalities,
          stateSnapshot.guid
        );
      case 501:
        return new DocSection5(
          stateSnapshot.id,
          stateSnapshot.docStatus,
          stateSnapshot.docClass,
          stateSnapshot.docName,
          stateSnapshot.docNum,
          stateSnapshot.docDate ? new Date(stateSnapshot.docDate) : null,
          stateSnapshot.orgName,
          stateSnapshot.canceledDocuments,
          stateSnapshot.note,
          stateSnapshot.settlement,
          stateSnapshot.affectedMunicipalities,
          stateSnapshot.guid
        );
      case 401:
      case 403:
      case 404:
      case 601:
        return new DocBase(
          stateSnapshot.id,
          stateSnapshot.docStatus,
          stateSnapshot.docClass,
          stateSnapshot.docName,
          stateSnapshot.docNum,
          stateSnapshot.docDate ? new Date(stateSnapshot.docDate) : null,
          stateSnapshot.orgName,
          stateSnapshot.canceledDocuments,
          stateSnapshot.note,
          stateSnapshot.affectedMunicipalities,
          stateSnapshot.guid
        );
      case 701:
      case 702:
        return new DocSection7(
          stateSnapshot.id,
          stateSnapshot.docStatus,
          stateSnapshot.docClass,
          stateSnapshot.docName,
          stateSnapshot.docNum,
          stateSnapshot.docDate ? new Date(stateSnapshot.docDate) : null,
          stateSnapshot.orgName,
          stateSnapshot.canceledDocuments,
          stateSnapshot.note,
          stateSnapshot.affectedMunicipalities,
          stateSnapshot.documentsOriginRegistration,
          stateSnapshot.guid
        );
      case 1305:
      case 1308:
      case 1309:
      case 1311:
      case 1312:
      case 1313:
      case 1314:
      case 1316:
      case 1317:
      case 1322:
      case 1323:
      case 1325:
      case 1326:
      case 1328:
      case 1329:
      case 1331:
      case 1332:
      case 1334:
      case 1338:
        return new DocVectorZu(
          stateSnapshot.id,
          stateSnapshot.docStatus,
          stateSnapshot.docClass,
          stateSnapshot.docName,
          stateSnapshot.docNum,
          stateSnapshot.docDate ? new Date(stateSnapshot.docDate) : null,
          stateSnapshot.orgName,
          stateSnapshot.canceledDocuments,
          stateSnapshot.note,
          stateSnapshot.zu,
          stateSnapshot.affectedMunicipalities,
          stateSnapshot.guid
        );
      case 1335:
        return new DocResolutionUseLand(
          stateSnapshot.id,
          stateSnapshot.docStatus,
          stateSnapshot.docClass,
          stateSnapshot.docName,
          stateSnapshot.docNum,
          stateSnapshot.docDate ? new Date(stateSnapshot.docDate) : null,
          stateSnapshot.orgName,
          stateSnapshot.canceledDocuments,
          stateSnapshot.note,
          stateSnapshot.zu,
          stateSnapshot.landUseBoundary,
          stateSnapshot.affectedMunicipalities,
          stateSnapshot.guid
        );
      case 1401:
      case 1402:
      case 1403:
      case 1404:
      case 1405:
      case 1406:
        return new DocSection14(
          stateSnapshot.id,
          stateSnapshot.docStatus,
          stateSnapshot.docClass,
          stateSnapshot.docName,
          stateSnapshot.docNum,
          stateSnapshot.docDate ? new Date(stateSnapshot.docDate) : null,
          stateSnapshot.orgName,
          stateSnapshot.canceledDocuments,
          stateSnapshot.note,
          stateSnapshot.settlement,
          stateSnapshot.affectedMunicipalities,
          stateSnapshot.guid
        );
      case 801:
        return new DocSection8(
          stateSnapshot.id,
          stateSnapshot.docStatus,
          stateSnapshot.docClass,
          stateSnapshot.docName,
          stateSnapshot.docNum,
          stateSnapshot.docDate ? new Date(stateSnapshot.docDate) : null,
          stateSnapshot.orgName,
          stateSnapshot.canceledDocuments,
          stateSnapshot.note,
          stateSnapshot.kindsOfEngIz,
          stateSnapshot.organizationWhoPrepared,
          stateSnapshot.affectedMunicipalities,
          stateSnapshot.guid
        );
      case 901:
      case 902:
      case 903:
        return new DocSection9(
          stateSnapshot.id,
          stateSnapshot.docStatus,
          stateSnapshot.docClass,
          stateSnapshot.docName,
          stateSnapshot.docNum,
          stateSnapshot.docDate ? new Date(stateSnapshot.docDate) : null,
          stateSnapshot.orgName,
          stateSnapshot.canceledDocuments,
          stateSnapshot.note,
          stateSnapshot.artZuRef,
          stateSnapshot.affectedMunicipalities,
          stateSnapshot.guid
        );
      case 1001:
      case 1002:
        return new DocSection10(
          stateSnapshot.id,
          stateSnapshot.docStatus,
          stateSnapshot.docClass,
          stateSnapshot.docName,
          stateSnapshot.docNum,
          stateSnapshot.docDate ? new Date(stateSnapshot.docDate) : null,
          stateSnapshot.orgName,
          stateSnapshot.canceledDocuments,
          stateSnapshot.note,
          stateSnapshot.zouitRef,
          stateSnapshot.affectedMunicipalities,
          stateSnapshot.guid
        );
      case 1201:
      case 1202:
      case 1203:
      case 1204:
        return new DocSection12(
          stateSnapshot.id,
          stateSnapshot.docStatus,
          stateSnapshot.docClass,
          stateSnapshot.docName,
          stateSnapshot.docNum,
          stateSnapshot.docDate ? new Date(stateSnapshot.docDate) : null,
          stateSnapshot.orgName,
          stateSnapshot.canceledDocuments,
          stateSnapshot.note,
          stateSnapshot.zu,
          stateSnapshot.reserveDate
            ? new Date(stateSnapshot.reserveDate)
            : null,
          stateSnapshot.affectedMunicipalities,
          stateSnapshot.guid
        );
      case 1307:
        return new DocExpertise(
          stateSnapshot.id,
          stateSnapshot.docStatus,
          stateSnapshot.docClass,
          stateSnapshot.docName,
          stateSnapshot.docNum,
          stateSnapshot.docDate ? new Date(stateSnapshot.docDate) : null,
          stateSnapshot.orgName,
          stateSnapshot.canceledDocuments,
          stateSnapshot.note,
          stateSnapshot.zu,
          stateSnapshot.egrzDate ? new Date(stateSnapshot.egrzDate) : null,
          stateSnapshot.isGovernmental,
          stateSnapshot.affectedMunicipalities,
          stateSnapshot.guid
        );
      case 1302:
      case 1303:
      case 1310:
        return new DocEGRZ(
          stateSnapshot.id,
          stateSnapshot.docStatus,
          stateSnapshot.docClass,
          stateSnapshot.docName,
          stateSnapshot.docNum,
          stateSnapshot.docDate ? new Date(stateSnapshot.docDate) : null,
          stateSnapshot.orgName,
          stateSnapshot.canceledDocuments,
          stateSnapshot.note,
          stateSnapshot.zu,
          stateSnapshot.egrzDate ? new Date(stateSnapshot.egrzDate) : null,
          stateSnapshot.affectedMunicipalities,
          stateSnapshot.guid
        );
      case 1301:
        return new DocGPZU(
          stateSnapshot.id,
          stateSnapshot.docStatus,
          stateSnapshot.docClass,
          stateSnapshot.docName,
          stateSnapshot.docNum,
          stateSnapshot.docDate ? new Date(stateSnapshot.docDate) : null,
          stateSnapshot.orgName,
          stateSnapshot.canceledDocuments,
          stateSnapshot.note,
          stateSnapshot.zu,
          stateSnapshot.untilDate ? new Date(stateSnapshot.untilDate) : null,
          stateSnapshot.affectedMunicipalities,
          stateSnapshot.guid
        );
      case 1304:
        return new DocRS(
          stateSnapshot.id,
          stateSnapshot.docStatus,
          stateSnapshot.docClass,
          stateSnapshot.docName,
          stateSnapshot.docNum,
          stateSnapshot.docDate ? new Date(stateSnapshot.docDate) : null,
          stateSnapshot.orgName,
          stateSnapshot.canceledDocuments,
          stateSnapshot.note,
          stateSnapshot.zu,
          stateSnapshot.docViewConstruction,
          stateSnapshot.nameFromPD,
          stateSnapshot.developers,
          stateSnapshot.untilDate ? new Date(stateSnapshot.untilDate) : null,
          stateSnapshot.oks,
          stateSnapshot.affectedMunicipalities,
          stateSnapshot.guid
        );
      case 1319:
        return new DocTechPlan(
          stateSnapshot.id,
          stateSnapshot.docStatus,
          stateSnapshot.docClass,
          stateSnapshot.docName,
          stateSnapshot.docNum,
          stateSnapshot.docDate ? new Date(stateSnapshot.docDate) : null,
          stateSnapshot.canceledDocuments,
          stateSnapshot.note,
          stateSnapshot.zu,
          stateSnapshot.oks,
          stateSnapshot.engineer,
          stateSnapshot.affectedMunicipalities,
          stateSnapshot.guid
        );
      case 1324:
        return new DocWithOKS(
          stateSnapshot.id,
          stateSnapshot.docStatus,
          stateSnapshot.docClass,
          stateSnapshot.docName,
          stateSnapshot.docNum,
          stateSnapshot.docDate ? new Date(stateSnapshot.docDate) : null,
          stateSnapshot.orgName,
          stateSnapshot.canceledDocuments,
          stateSnapshot.note,
          stateSnapshot.zu,
          stateSnapshot.oks,
          stateSnapshot.affectedMunicipalities,
          stateSnapshot.guid
        );
      case 1318:
        return new DocRV(
          stateSnapshot.id,
          stateSnapshot.docStatus,
          stateSnapshot.docClass,
          stateSnapshot.docName,
          stateSnapshot.docNum,
          stateSnapshot.docDate ? new Date(stateSnapshot.docDate) : null,
          stateSnapshot.orgName,
          stateSnapshot.canceledDocuments,
          stateSnapshot.note,
          stateSnapshot.zu,
          stateSnapshot.docViewConstruction,
          stateSnapshot.nameFromPD,
          stateSnapshot.developers,
          stateSnapshot.oks,
          stateSnapshot.affectedMunicipalities,
          stateSnapshot.guid
        );
      case 1320:
      case 1321:
      case 1327:
      case 1330:
      case 1333:
        return new DocFieldsNotificationAboutBuilding(
          stateSnapshot.id,
          stateSnapshot.docStatus,
          stateSnapshot.docClass,
          stateSnapshot.docName,
          stateSnapshot.docNum,
          stateSnapshot.docDate ? new Date(stateSnapshot.docDate) : null,
          stateSnapshot.orgName,
          stateSnapshot.canceledDocuments,
          stateSnapshot.note,
          stateSnapshot.zu,
          stateSnapshot.oks,
          stateSnapshot.developers,
          stateSnapshot.affectedMunicipalities,
          stateSnapshot.guid
        );
      case 1336:
      case 1337:
        return new DocWithServitute(
          stateSnapshot.id,
          stateSnapshot.docStatus,
          stateSnapshot.docClass,
          stateSnapshot.docName,
          stateSnapshot.docNum,
          stateSnapshot.docDate ? new Date(stateSnapshot.docDate) : null,
          stateSnapshot.orgName,
          stateSnapshot.canceledDocuments,
          stateSnapshot.note,
          stateSnapshot.zu,
          stateSnapshot.servitutes,
          stateSnapshot.affectedMunicipalities,
          stateSnapshot.guid
        );
      case 1501:
        return new DocSection15(
          stateSnapshot.id,
          stateSnapshot.docStatus,
          stateSnapshot.docClass,
          stateSnapshot.docName,
          stateSnapshot.docNum,
          stateSnapshot.docDate ? new Date(stateSnapshot.docDate) : null,
          stateSnapshot.orgName,
          stateSnapshot.canceledDocuments,
          stateSnapshot.note,
          stateSnapshot.oopts,
          stateSnapshot.affectedMunicipalities,
          stateSnapshot.guid
        );
      case 1601:
        return new DocForestRegulation(
          stateSnapshot.id,
          stateSnapshot.docStatus,
          stateSnapshot.docClass,
          stateSnapshot.docName,
          stateSnapshot.docNum,
          stateSnapshot.docDate ? new Date(stateSnapshot.docDate) : null,
          stateSnapshot.orgName,
          stateSnapshot.canceledDocuments,
          stateSnapshot.note,
          stateSnapshot.forestryRef,
          stateSnapshot.affectedMunicipalities,
          stateSnapshot.guid
        );
      case 1602:
        return new DocGisogdForestProject(
          stateSnapshot.id,
          stateSnapshot.docStatus,
          stateSnapshot.docClass,
          stateSnapshot.docName,
          stateSnapshot.docNum,
          stateSnapshot.docDate ? new Date(stateSnapshot.docDate) : null,
          stateSnapshot.orgName,
          stateSnapshot.canceledDocuments,
          stateSnapshot.note,
          stateSnapshot.forestLandPlotRef,
          stateSnapshot.affectedMunicipalities,
          stateSnapshot.guid
        );
      case 1603:
        return new DocGisogdLesParcelPd(
          stateSnapshot.id,
          stateSnapshot.docStatus,
          stateSnapshot.docClass,
          stateSnapshot.docName,
          stateSnapshot.docNum,
          stateSnapshot.docDate ? new Date(stateSnapshot.docDate) : null,
          stateSnapshot.orgName,
          stateSnapshot.canceledDocuments,
          stateSnapshot.note,
          stateSnapshot.forestLandPlotRef,
          stateSnapshot.affectedMunicipalities,
          stateSnapshot.guid
        );
      case 21001:
        return new DocSection10Iz(
			stateSnapshot.id,
            stateSnapshot.docStatus,
            stateSnapshot.docClass,
            stateSnapshot.docName,
            stateSnapshot.docNum,
            stateSnapshot.docDate ? new Date(stateSnapshot.docDate) : null,
            stateSnapshot.orgName,
            stateSnapshot.note,
            stateSnapshot.zouitRef,
            stateSnapshot.description,
            stateSnapshot.documentsOriginRegistration,
            stateSnapshot.affectedMunicipalities,
            stateSnapshot.guid
        );
      case 20101:
        return new DocIzSTPRF(
          stateSnapshot.id,
          stateSnapshot.docStatus,
          stateSnapshot.docClass,
          stateSnapshot.docName,
          stateSnapshot.docNum,
          stateSnapshot.docDate ? new Date(stateSnapshot.docDate) : null,
          stateSnapshot.orgName,
          stateSnapshot.note,
          stateSnapshot.description,
          stateSnapshot.stprfName,
          stateSnapshot.documentsOriginRegistration,
          stateSnapshot.affectedMunicipalities,
          stateSnapshot.guid
        );
      case 20102:
      case 20202:
      case 20201:
      case 20301:
      case 20302:
      case 20303:
      case 20401:
      case 20501:
      case 20601:
      case 20701:
      case 20702:
      case 21401:
      case 21501:
      case 21601:      
        return new DocIzBase(
          stateSnapshot.id,
          stateSnapshot.docStatus,
          stateSnapshot.docClass,
          stateSnapshot.docName,
          stateSnapshot.docNum,
          stateSnapshot.docDate ? new Date(stateSnapshot.docDate) : null,
          stateSnapshot.orgName,
          stateSnapshot.note,
          stateSnapshot.description,
          stateSnapshot.documentsOriginRegistration,
          stateSnapshot.affectedMunicipalities,
          stateSnapshot.guid
        );
      case 1306:
        return new DocIZRs(
          stateSnapshot.id,
          stateSnapshot.docStatus,
          stateSnapshot.docClass,
          stateSnapshot.docName,
          stateSnapshot.docNum,
          stateSnapshot.docDate ? new Date(stateSnapshot.docDate) : null,
          stateSnapshot.orgName,
          stateSnapshot.note,
          stateSnapshot.zu,
          stateSnapshot.description,
          stateSnapshot.documentsOriginRegistration,
          stateSnapshot.isChangeForProlongation,
          stateSnapshot.prolongationReason,
          stateSnapshot.prolongationLimit,
          stateSnapshot.prolongationDate
            ? new Date(stateSnapshot.prolongationDate)
            : null,
          stateSnapshot.affectedMunicipalities,
          stateSnapshot.guid
        );
      case 1701:
        return new GisogdInfoModelOKS(
          stateSnapshot.id,
          stateSnapshot.docStatus,
          stateSnapshot.docClass,
          stateSnapshot.docName,
          stateSnapshot.docNum,
          stateSnapshot.docDate ? new Date(stateSnapshot.docDate) : null,
          stateSnapshot.orgName,
          stateSnapshot.canceledDocuments,
          stateSnapshot.note,
          stateSnapshot.zu,
          stateSnapshot.nameFromPD,
          stateSnapshot.developers,
          stateSnapshot.oks,
          stateSnapshot.affectedMunicipalities,
          stateSnapshot.guid
        );
      case 1801:
        return new DocBase(
          stateSnapshot.id,
          stateSnapshot.docStatus,
          stateSnapshot.docClass,
          stateSnapshot.docName,
          stateSnapshot.docNum,
          stateSnapshot.docDate ? new Date(stateSnapshot.docDate) : null,
          stateSnapshot.orgName,
          stateSnapshot.canceledDocuments,
          stateSnapshot.note,
          stateSnapshot.affectedMunicipalities,
          stateSnapshot.guid
        );
      default:
        throw new Error("Неизвестный тип документа");
    }
  }

  static isDocIz(documentType) {
    switch (documentType.id) {
      case 20101:
      case 20102:
      case 20202:
      case 20201:
      case 20301:
      case 20302:
      case 20303:
      case 20401:
      case 20501:
      case 20601:
      case 20701:
      case 20702:
      case 21304:
      case 21401:
      case 21501:
      case 21601:
      case 21001:
      case 1306:
        return true;
      default:
        return false;
    }
  }

  /*  static createFromDto(dto) {
    return new DocGP.fromDto(dto);
  } */
}
