import { ref } from "vue";
import RegistrationProcessProxy from "../proxies/registrationProcessProxy";
import { RegistrationProcess } from "../models/registrationProcess";
import useInboxData from "./inboxData";


export default function useRegistrationProcess(inboxDataNumber) {
  const registrationProcess = ref(new RegistrationProcess());
  const isLoading = ref(true);
  const { isRegistered } = useInboxData(inboxDataNumber);

  async function fetch(inboxDataNumber) {
    registrationProcess.value = await RegistrationProcessProxy.fetch(
      inboxDataNumber
    );

    if (registrationProcess.value === null) {
      await create(inboxDataNumber);
    }
    isLoading.value=false;
    return registrationProcess;
  }

  async function create(inboxDataNumber) {
    registrationProcess.value = await RegistrationProcessProxy.create(
      inboxDataNumber
    );
  }

  async function removeItem(inboxDataNumber, itemId) {
    await RegistrationProcessProxy.removeItem(inboxDataNumber, itemId);
  }

  async function removeRegistrationProcess(inboxDataNumber) {
    await RegistrationProcessProxy.delete(inboxDataNumber);
  }

  if (inboxDataNumber !== undefined) fetch(inboxDataNumber.value);

  return {
    isRegistered,
    isLoading,
    registrationProcess,
    removeItem,
    fetch,
    removeRegistrationProcess,
  };
}
