<template>
  <div
    class="button-dropdown"
    :class="{ 'dropdown--active': isActive }"
    v-if="canComplete"
    @blur="toggleActive"
  >
    <OnClickOutside :do="close">
      <button
        class="button button--outline button--right-icon button-dropdown__trigger"
        aria-haspopup="true"
        aria-controls="dropdown-menu"
        @click="toggleActive"
        data-qa="finish"
      >
        Завершить
      </button>
    </OnClickOutside>
    <div class="button-dropdown__menu" id="dropdown-menu" role="menu">
      <div class="button-dropdown__content">
        <a
          v-if="canComplete"
          class="button-dropdown__item"
          @click="onCompleteInboxdata"
          data-qa="postedBy"
        >
          Размещено
        </a>
        <a
          v-if="canComplete"
          class="button-dropdown__item"
          @click="onCompletePartiallyInboxdata"
          data-qa="partiallyHosted"
        >
          Частично размещено
        </a>
        <InboxdataRejectButton
          v-if="canComplete"
          class="button-dropdown__item"
          :rejectionReasonRefs="rejectionReasonsRefs"
          @confirmReject="onRejectPlacementInboxdata"
          data-qa="refusedPlacement"
          >Отказано в размещении</InboxdataRejectButton
        >
      </div>
    </div>
  </div>
</template>
<script>
import { computed, ref } from 'vue';
import useInboxData from '../../hooks/inboxData';
import InboxdataRejectButton from './InboxDataRejectButton1';
import OnClickOutside from '../basic/OnClickOutside.vue';

export default {
  props: {
    inboxdata: {
      type: Object,
    },
  },
  emits: ['finalized'],
  components: {
    InboxdataRejectButton, OnClickOutside
  },
  setup(props, { emit }) {
    const isActive = ref(false);

    const {
      completeInboxdata,
      completePartiallyInboxdata,
      rejectPlacementInboxdata,
      rejectionReasonsRefs,
    } = useInboxData();

    function toggleActive() {
      isActive.value = !isActive.value;
    }

    async function onCompleteInboxdata() {
      await completeInboxdata(props.inboxdata.number);
      toggleActive();
      emit('finalized');
    }

    async function onCompletePartiallyInboxdata() {
      await completePartiallyInboxdata(props.inboxdata.number);
      toggleActive();
      emit('finalized');
    }

    async function onRejectPlacementInboxdata(eArg) {
      await rejectPlacementInboxdata(props.inboxdata.number, eArg);
      toggleActive();
      emit('finalized');
    }

    const canComplete = computed(() => {
      //todo сейчас этих статусов нету
      return (
        props.inboxdata.status?.id !== 1 ||
        props.inboxdata.processStatus?.id !== 1
      );
    });

    function close() {
      isActive.value = false;
    }

    return {
      isActive,
      rejectionReasonsRefs,
      canComplete,
      toggleActive,
      onCompleteInboxdata,
      onCompletePartiallyInboxdata,
      onRejectPlacementInboxdata,
      close
    };
  },
};
</script>
<style>
.button-dropdown {
  display: inline-flex;
  position: relative;
  vertical-align: top;
}

.button-dropdown__trigger::after {
  background-image: url('~@/assets/svg/chevron-down-blue.svg');
}

.button-dropdown__menu {
  display: none;
  left: 0;
  min-width: 12rem;
  padding-top: 4px;
  position: absolute;
  top: 100%;
  z-index: 20;
}

.dropdown--active .button-dropdown__menu {
  display: block;
}

.button-dropdown__content {
  background-color: var(--basic-white);
  border-radius: 6px;
  filter: var(--dropdown-shadow);
  padding-bottom: 6px;
  padding-top: 6px;
}

.button-dropdown__item {
  color: var(--basic-black);
  display: block;
  font-size: 14px;
  line-height: 20px;
  padding: 6px 12px;
  /* position: relative; */
  cursor: pointer;
}

.button-dropdown__item:hover {
  background-color: var(--secondary-blue-lighter);
  color: var(--basic-black);
}

a.button-dropdown__item {
  padding-right: 3rem;
  text-align: inherit;
  white-space: nowrap;
  width: 100%;
}
</style>
