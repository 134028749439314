<template>
  <button
    class="button button-icon button--small"
    title="Скачать файл"
    @click="downloadFile()"
    aria-label="Скачать файл"
    data-qa="download"
  >
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 0.799988V12"
        stroke="#518CFF"
        stroke-width="1.4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M4 8L8 12L12 8"
        stroke="#518CFF"
        stroke-width="1.4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M2.3999 15.2H13.5999"
        stroke="#518CFF"
        stroke-width="1.4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </button>
</template>
<script>
import FileStorageProxy from '../../../proxies/fileStorageProxy.js';
import Downloader from '../../../utils/downloader.js';
import useProjectAlias from "../../../hooks/projectAlias";
export default {
  props: {
    file: { type: Object },
  },
  methods: {
    async downloadFile() {
      const { currentProject } = useProjectAlias();
      await FileStorageProxy.downloadFile(currentProject.value.projectAlias, this.file.fileUrl).then(
        (response) => {
          Downloader.blob(
            new Blob([response.data], {
              type: response.headers['content-type'],
            }),
            this.file.name
          );
        }
      );
    },
  },
};
</script>
<style scoped lang="scss">
@import '../../../assets/sass/buttons.scss';
</style>
