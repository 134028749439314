<template>
  <div class="">
    <FileUpload
      @startUpload="onStartUpload"
      @endUpload="onEndUpload"
      @failUpload="onFailUpload"
      class="form-control bottom_25"
      acceptingFilesTypes=".tab,.mid,.mif,.shp,.dat,.id,.map,.cpg,.dbf,.prj,.shx"
    />
    <label class="label" v-if="isFilesNotEmpty">Загруженные файлы</label>
    <file-list-input
      :files="geometrySourceInfo.files"
      :modelValue="geometrySourceInfo.files"
      @update:modelValue="onFileRemoved($event)"
      :removable="true"
      class="file-list--outline"
      data-qa="fromUploadingFiles"
    >
      <template #file="{ file }">
        <base-file :file="file" />
      </template>
    </file-list-input>
    <br />
    <GeometryToolbar
      :apply-geometry-fn="applyGeometry"
      :remove-geometry-fn="removeGeometry"
    ></GeometryToolbar>
    <FilterOptions
      v-slot="{ filter, filtredOptions }"
      :modelValue="srses"
      :fieldName="'name'"
    >
      <UniversalSelectField
        v-if="showSrses"
        class="form-control"
        :modelValue="selectedSrs"
        @update:modelValue="changeSrs"
        @filter="filter"
        data-qa="srses"
        :keyProp="'srid'"
        :displayProp="'name'"
        :hasError="srsErrors.length > 0"
        :options="filtredOptions.value"
      >
        <label class="label label--required">Системы координат</label>
        <template #validation>
          <ValidationMsg :errors="srsErrors"></ValidationMsg>
        </template>
      </UniversalSelectField>
    </FilterOptions>
  </div>
</template>

<script>
import FileUpload from "../../basic/files/fileUpload/FileUploadLite";
import GeometryProxy from "../../../proxies/geometryProxy";
import SrsesProxy from "../../../proxies/srsesProxy";
import FileListInput from "../../basic/form/files/FileListInput";
import BaseFile from "../../basic/files/BaseFile";
import GeometryToolbar from "./GeometryToolbar";
import FilterOptions from "../../basic/renderless/FilterOptions.vue";
import ValidationMsg from "../../basic/form/select/ValidationMsg";
import {
  GeometrySource,
  GeometrySourceInfoFromLocalFile,
} from "@/models/geometrySource";
import { ref, computed, inject } from "vue";
import UniversalSelectField from "@/components/basic/form/select/UniversalSelectField.vue";

export default {
  props: {
    geometrySourceInfo: {
      type: Object,
      required: true,
    },
    geometry: {
      type: [Object, String],
      required: true,
    },
  },
  emits: ["update:geometrySource", "error"],
  components: {
    UniversalSelectField,
    FileListInput,
    BaseFile,
    GeometryToolbar,
    FileUpload,
    FilterOptions,
    ValidationMsg,
  },
  setup(props, { emit }) {
    const srses = ref([]);
    const selectedSrs = ref({ name: "", srid: 0 });
    const hasError = ref(false);
    const toast = inject("toast");

    const srsErrors = computed(() => {
      if (props.geometry.original.srid === 0)
        return [{ $message: "Укажите систему координат" }];
      return [];
    });

    const showSrses = computed(() => {
      return props.geometry && !hasError.value;
    });

    const isFilesNotEmpty = computed(() => {
      return props.geometrySourceInfo.files.length > 0;
    });

    function changeSrs(val) {
      if (val) {
        selectedSrs.value = val;
        const newGeometry = setSrid(props.geometry, val.srid);
        emitUpdateGeometry(props.geometrySourceInfo.files, newGeometry);
      }
    }

    async function applyGeometry() {
      if (
        !props.geometrySourceInfo.files ||
        props.geometrySourceInfo.files.length === 0
      ) {
        toast.warning(
          "Чтобы сформировать территорию действия, выберите файлы, содержащие геометрию территории действия."
        );
        return;
      }
      removeGeometry();

      try {
        const geometry = await GeometryProxy.getFromFiles(
          props.geometrySourceInfo.files
        );
        const success = trySelectSrs(geometry.original.srid);
        if (!success) setSrid(geometry, 0);
        addGeometry(geometry);
        hasError.value = false;

        if (geometry.original.geometry.includes("Line"))
        {
          emit("error", "Тип геометрии у объекта должен быть полигон");
        }
      } catch (error) {
        hasError.value = true;
        if (error == "Cancel") return;
        if (error.response.data.code) {
          emitError(error.response.data.message);
        }
      }
    }

    function removeGeometry() {
      selectedSrs.value = null;
      emitUpdateGeometry(props.geometrySourceInfo.files, null);
    }

    function addGeometry(geometry) {
      emitUpdateGeometry(props.geometrySourceInfo.files, geometry);
      if (geometry.original.srid === 0)
        emitError(
          "Система координат, указанная в файле, не обнаружена в настройках системы."
        );
    }

    function emitUpdateGeometry(files, geometry) {
      emit(
        "update:geometrySource",
        new GeometrySource(new GeometrySourceInfoFromLocalFile(files), geometry)
      );
    }

    function emitError(message) {
      emit("error", message);
    }

    function trySelectSrs(srid) {
      const findedSrs = srses.value.find((srs) => srs.srid == srid);
      if (findedSrs) {
        selectedSrs.value = findedSrs;
        return true;
      }
      return false;
    }

    function setSrid(geometry, srid) {
      const newGeometry = { ...geometry };
      newGeometry.original.srid = +srid;
      return newGeometry;
    }

    function onFileRemoved(fileList) {
      emitUpdateGeometry(fileList, null);
    }

    function onStartUpload(inputFiles) {
      const newArr = inputFiles.slice();
      emitUpdateGeometry(props.geometrySourceInfo.files.concat(newArr), null);
    }

    function onEndUpload(file) {
      const uploadedFile = props.geometrySourceInfo.files.find(
        (f) => f.uid === file.uid
      );
      uploadedFile.setLoaded();
      emitUpdateGeometry(props.geometrySourceInfo.files, null);
    }

    function onFailUpload(file) {
      const newArr = props.geometrySourceInfo.files.slice();
      const index = newArr.findIndex((f) => f.uid === file.uid);
      if (index > -1) {
        newArr.splice(index, 1);
        emitUpdateGeometry(newArr, null);
      }
    }

    return {
      srses,
      selectedSrs,
      showSrses,
      isFilesNotEmpty,
      srsErrors,
      changeSrs,
      applyGeometry,
      removeGeometry,
      addGeometry,
      emitUpdateGeometry,
      emitError,
      trySelectSrs,
      setSrid,
      onFileRemoved,
      onStartUpload,
      onEndUpload,
      onFailUpload,
    };
  },
  async created() {
    this.srses = await SrsesProxy.fetch();
    if (this.geometry) {
      const success = this.trySelectSrs(this.geometry.original.srid);
      if (!success) {
        const newGeometry = this.setSrid(this.geometry, 0);
        this.addGeometry(newGeometry);
      }
    }
  },
};
</script>
<style scoped>
.bottom_25 {
  margin-bottom: 25px;
}
</style>
