import ReestersPage from '../components/reesters/ReestersPage';
const routes = [
  {
    path: 'reesters',
    name: 'reesters',
    component: ReestersPage,
    meta: {
      requiresAuth: true,
    },
  },
];

export default routes;
