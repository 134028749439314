<script>
import { watch, onMounted, toRefs } from "vue";
export default {
  props: {
    map: {
      type: Object,
      required: true,
    },
    leaflet: {
      type: Object,
      required: true,
    },
    geoJsonString: {
      type: String,
    },
  },
  setup(props) {
    const { geoJsonString, map, leaflet } = toRefs(props);

    function fitBound(geoJsonString) {
      if (geoJsonString) {
        const geoJson = JSON.parse(geoJsonString);
        const geometry = leaflet.value.geoJSON(geoJson);
        const bound = geometry.getBounds();
        map.value.fitBounds(bound, {});
      }
    }
    onMounted(() => fitBound(geoJsonString.value));

    watch(geoJsonString, () => {
      fitBound(geoJsonString.value);
    });

    return () => {};
  },
};
</script>
