<template>
  <div class="">
    <checked-files-field
      v-if="geometrySourceInfo"
      :modelValue="geometrySourceInfo.files"
      @update:modelValue="updateFiles($event)"
      :files="files"
    >
      <template #file="{ file }">
        <base-file :file="file" />
      </template>
    </checked-files-field>
    <br />
    <GeometryToolbar
      :apply-geometry-fn="applyGeometry"
      :remove-geometry-fn="removeGeometry"
    ></GeometryToolbar>
    <FilterOptions
      v-slot="{ filter, filtredOptions }"
      :modelValue="srses"
      :fieldName="'name'"
    >
      <UniversalSelectField
        v-if="showSrses"
        class="form-control"
        :modelValue="selectedSrs"
        @update:modelValue="changeSrs"
        @filter="filter"
        data-qa="srses"
        :keyProp="'srid'"
        :displayProp="'name'"
        :hasError="srsErrors.length > 0"
        :options="filtredOptions.value"
      >
        <label class="label label--required">Системы координат</label>
        <template #validation>
          <ValidationMsg :errors="srsErrors"></ValidationMsg>
        </template>
      </UniversalSelectField>
    </FilterOptions>
  </div>
</template>

<script>
import GeometryProxy from "../../../proxies/geometryProxy";
import SrsesProxy from "../../../proxies/srsesProxy";
import CheckedFilesField from "../../basic/form/files/CheckedFilesField";
import BaseFile from "../../basic/files/BaseFile";
import GeometryToolbar from "./GeometryToolbar";
import { ref, computed, inject } from "vue";
import FilterOptions from "../../basic/renderless/FilterOptions.vue";
import ValidationMsg from "../../basic/form/select/ValidationMsg";

import {
  GeometrySource,
  GeometrySourceInfoFromFile,
} from "@/models/geometrySource";
import UniversalSelectField from "@/components/basic/form/select/UniversalSelectField.vue";

export default {
  props: {
    geometrySourceInfo: {
      type: Object,
      required: true,
    },
    geometry: {
      type: [Object, String],
    },
    files: Array,
  },
  emits: ["update:geometrySource", "error"],
  components: {
    UniversalSelectField,
    CheckedFilesField,
    BaseFile,
    GeometryToolbar,
    FilterOptions,
    ValidationMsg,
  },
  setup(props, { emit }) {
    const srses = ref([]);
    const selectedSrs = ref({ name: "", srid: 0 });
    const hasError = ref(false);
    const toast = inject("toast");

    const srsErrors = computed(() => {
      if (props.geometry.original.srid === 0)
        return [{ $message: "Укажите систему координат" }];

      return [];
    });

    const showSrses = computed(() => {
      return props.geometry && !hasError.value;
    });

    function changeSrs(val) {
      if (val) {
        selectedSrs.value = val;
        const newGeometry = setSrid(props.geometry, val.srid);
        emitUpdateGeometry(props.geometrySourceInfo.files, newGeometry);
      }
    }

    async function applyGeometry() {
      if (
        !props.geometrySourceInfo.files ||
        props.geometrySourceInfo.files.length === 0
      ) {
        toast.warning(
          "Чтобы сформировать территорию действия, выберите файлы, содержащие геометрию территории действия."
        );
        return;
      }
      removeGeometry();

      try {
        const geometry = await GeometryProxy.getFromFiles(
          props.geometrySourceInfo.files
        );
        const success = trySelectSrs(geometry.original.srid);
        if (!success) setSrid(geometry, 0);
        addGeometry(geometry);
        hasError.value = false;

        if (geometry.original.geometry.includes("Line"))
        {
          emit("error", "Тип геометрии у объекта должен быть полигон");
        }
      } catch (error) {
        hasError.value = true;
        if (error == "Cancel") return;
        if (error.response.data.code) {
          emitError(error.response.data.message);
        }
      }
    }

    function removeGeometry() {
      selectedSrs.value = null;
      emitUpdateGeometry(props.geometrySourceInfo.files, null);
    }

    function addGeometry(geometry) {
      emitUpdateGeometry(props.geometrySourceInfo.files, geometry);
      if (geometry.original.srid === 0)
        emitError(
          "Система координат, указанная в файле, не обнаружена в настройках системы."
        );
    }

    function emitUpdateGeometry(files, geometry) {
      emit(
        "update:geometrySource",
        new GeometrySource(new GeometrySourceInfoFromFile(files), geometry)
      );
    }

    function emitError(message) {
      emit("error", message);
    }

    function updateFiles(files) {
      emitUpdateGeometry(files, props.geometrySourceInfo.geometry);
    }

    function trySelectSrs(srid) {
      const findedSrs = srses.value.find((srs) => srs.srid == srid);
      if (findedSrs) {
        selectedSrs.value = findedSrs;
        return true;
      }
      return false;
    }

    function setSrid(geometry, srid) {
      const newGeometry = { ...geometry };
      newGeometry.original.srid = +srid;
      return newGeometry;
    }

    return {
      srses,
      selectedSrs,
      showSrses,
      srsErrors,
      changeSrs,
      applyGeometry,
      removeGeometry,
      addGeometry,
      emitUpdateGeometry,
      emitError,
      trySelectSrs,
      updateFiles,
    };
  },
  async created() {
    this.srses = await SrsesProxy.fetch();
    if (this.geometry) {
      const success = this.trySelectSrs(this.geometry.original.srid);
      if (!success) {
        const newGeometry = this.setSrid(this.geometry, 0);
        this.addGeometry(newGeometry);
      }
    }
  },
};
</script>
