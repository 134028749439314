import Oidc from 'oidc-client';
const baseUrl =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:8080'
    : window.location.origin;

const baseAuthorityUrl =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:5002'
    : window.location.origin + '/idsrv';

const OidcUserManager = new Oidc.UserManager({
  authority: baseAuthorityUrl,
  client_id: 'diamond.mvc',
  redirect_uri: baseUrl + '/oidc-callback',
  response_type: 'code',
  scope: 'openid profile api1',
  post_logout_redirect_uri: baseUrl + '/oidc-out-callback',
  silent_redirect_uri: baseUrl + '/silent-renew',
  silentRequestTimeout: 2000,
  automaticSilentRenew: true,
  filterProtocolClaims: true,
  loadUserInfo: true,
});

Oidc.Log.logger = console;
Oidc.Log.level = Oidc.Log.INFO;

OidcUserManager.events.addAccessTokenExpired(function() {
  OidcUserManager.signoutRedirect().catch(function(err) {
    console.log(err);
  });
});

OidcUserManager.events.addSilentRenewError(function() {
  console.error('Silent Renew Error：', arguments);
});

OidcUserManager.events.addUserSignedOut(function() {
  OidcUserManager.signoutRedirect().catch(function(err) {
    console.log(err);
  });
});

OidcUserManager.events.addUserSessionChanged(function() {
  console.error('User Session Changed', arguments);
});

OidcUserManager.clearStaleState()
  .then(function() {
    console.log('clearStateState success');
  })
  .catch(function(e) {
    console.log('clearStateState error', e.message);
  });

export default OidcUserManager;
