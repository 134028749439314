<template>
  <transition :name="options.transition" v-show="showing" :ref="setRefNode">
    <div class="toast toast--success" :class="cls">
      <div class="toast__icon"></div>
      <p class="toast__message">
        {{ message }}
      </p>
      <button class="toast__close-button" @click="close()"></button>
    </div>
  </transition>
</template>

<script>
import { TOAST_TYPE } from "./toastType";
import {
  reactive,
  onMounted,
  onUnmounted,
  defineComponent,
  watch,
  ref,
  toRefs,
  computed,
} from "vue";
export default defineComponent({
  props: {
    id: String,
    transition: String,
    message: String,
    duration: Number,
    toastType: {
      type: String,
      default: TOAST_TYPE.success,
      validator: function (value) {
        return (
          [TOAST_TYPE.success, TOAST_TYPE.error, TOAST_TYPE.warning].indexOf(
            value
          ) !== -1
        );
      },
    },
  },
  setup(props) {
    const options = props;
    let refNode = null;
    const state = reactive({
      showing: false,
      timer: null,
    });
    const cls = computed(() => {
      switch (options.toastType) {
        case TOAST_TYPE.success: {
          return "toast--success";
        }
        case TOAST_TYPE.error: {
          return "toast--error";
        }
        case TOAST_TYPE.warning: {
          return "toast--warning";
        }
        default:
          return "";
      }
    });
    let closed = ref(false);
    const startTimer = () => {
      if (options.duration > 0) {
        state.timer = setTimeout(() => {
          if (!closed.value) {
            close();
          }
        }, options.duration);
      }
    };

    const close = () => {
      closed.value = true;
      state.timer = null;
    };

    onMounted(() => {
      startTimer();
      state.showing = true;
    });
    onUnmounted(() => {
      clearTimeout(state.timer);
    });
    const removeHandler = () => {
      state.showing = false;
      document.body.removeChild(document.getElementById(props.id));
      refNode.removeEventListener("transitionend", removeHandler, false);
      refNode.removeEventListener("transitioncancel", removeHandler, false);
      refNode.removeEventListener("webkitTransitionEnd", removeHandler, false);
    };
    const setRefNode = (el) => {
      refNode = el;
    };
    watch(closed, (newVal) => {
      if (newVal) {
        refNode.addEventListener("transitionend", removeHandler, false);
        refNode.addEventListener("transitioncancel", removeHandler, false);
        refNode.addEventListener("webkitTransitionEnd", removeHandler, false);

        state.showing = false;
      }
    });

    return { cls, close, setRefNode, options, ...toRefs(state) };
  },
});
</script>

<style scoped>
.toast {
  position: fixed;
  top: 10%;
  z-index: 9999;
  left: 0;
  right: 0;

  padding: 20px 27px;
  width: 670px;
  max-width: 700px;
  min-width: 500px;
  border-radius: 6px;

  margin: auto;
  text-align: center;
  display: grid;
  grid-template-columns: min-content 1fr min-content;
  gap: 27px;
}

.toast--success {
  box-shadow: 0px 4px 20px var(--basic-green-transparent-45);
  background-color: var(--basic-green);
}

.toast--warning {
  box-shadow: 0px 4px 20px var(--basic-yellow-transparent-45);
  background-color: var(--basic-yellow);
}

.toast--error {
  box-shadow: 0px 4px 20px var(--basic-red-transparent-45);
  background-color: var(--basic-red);
}

.toast__icon {
  height: 36px;
  width: 36px;
  align-self: center;
}

.toast--success .toast__icon {
  background-image: url("../../assets/svg/toast/toast-success.svg");
}

.toast--error .toast__icon {
  background-image: url("../../assets/svg/toast/toast-error.svg");
}

.toast--warning .toast__icon {
  background-image: url("../../assets/svg/toast/toast-warning.svg");
}

.toast__message {
  display: block;
  text-align:left;
  overflow-wrap: anywhere;
}
.toast--success .toast__message,
.toast--error .toast__message,
.toast--warning .toast__message {
  color: var(--basic-white);
}

.toast__close-button {
  position: relative;
  width: 20px;
  height: 20px;
  border: none;
  outline: none;
  cursor: pointer;
  background: transparent;
  background-image: url("../../assets/svg/toast/toast-close.svg");
  background-repeat: no-repeat;
}

/* vue transition animation */
.fade-enter-active {
  transition: all 0.3s ease-out;
}
.fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
.fade-enter-to,
.fade-leave-from {
  opacity: 1;
}
</style>