export const GEOMETRY_SOURCE_TYPES = {
  null: { id: 0, displayName: "" },
  fromFile: { id: 1, displayName: "Из файла" },
  kn: { id: 2, displayName: "Кадастровый номер" },
  oktmo: { id: 3, displayName: "ОКТМО" },
  fromDocument: { id: 4, displayName: "Из указанного ранее объекта" },
  fromLocalFile: { id: 5, displayName: "Загрузить файлы с компьютера" },
  fromInfoSet: { id: 6, displayName: "Из набора данных" },
  fromOriginDocument: { id: 7, displayName: "По связи документа" }
};
