import { ref } from 'vue';
import _debounce from 'lodash.debounce';
import SubjectProxy from '../proxies/subjectProxy';

export default function useSubjectReference() {
  var debounceFunc = undefined;
  const debounceDelay = 1000;
  const persons = ref([]);
  const entrepreneurs = ref([]);
  const organizations = ref([]);

  fetch();

  async function fetch(filter = "") {
    persons.value = await SubjectProxy.fetchPersons(filter);
    entrepreneurs.value = await SubjectProxy.fetchEntrepreneurs(filter);
    organizations.value = await SubjectProxy.fetchOrganizations(filter);
  }

  async function fetchWithDelay(filter = "") {
    if(debounceFunc != undefined) debounceFunc.cancel();
    debounceFunc = _debounce(async () => {
      persons.value = await SubjectProxy.fetchPersons(filter);
      entrepreneurs.value = await SubjectProxy.fetchEntrepreneurs(filter);
      organizations.value = await SubjectProxy.fetchOrganizations(filter);
    }, debounceDelay);
    debounceFunc();
  }

  return {
    persons,
    organizations,
    entrepreneurs,
    fetch,
    fetchWithDelay
  };
}
