export class PersonDto {
    constructor(firstName, lastName, middleName, phone, email, ogrnip, passportSeries, passportNumber, snils, inn, subjectStatusKey, guid) {
        this.firstName = firstName;
        this.lastName = lastName;
        this.middleName = middleName;
        this.phone = phone;
        this.email = email;
        this.ogrnip = ogrnip;
        this.passportSeries = passportSeries;
        this.passportNumber = passportNumber;
        this.snils = snils;
        this.inn = inn;
        this.subjectStatusKey = subjectStatusKey;
        this.guid = guid;
    }
  }

  export class PersonDtoResult {
      constructor(issuccess, error, value) {
          this.issuccess = issuccess;
          this.error = error;
          this.value = value;
      }
  }