import useVuelidate from "@vuelidate/core";
import { helpers, required, numeric, maxLength } from "@vuelidate/validators";

const rules = {
    enterpreneurShortName: {
        maxLength: helpers.withMessage("Количество символов не должно превышать 1000", maxLength(1000)),
    },
    enterpreneurFullName: {
        required: helpers.withMessage("Заполните поле", required),
        maxLength: helpers.withMessage("Количество символов не должно превышать 4000", maxLength(4000)),
    },
    lastName: {
        required: helpers.withMessage("Заполните поле", required),
        maxLength: helpers.withMessage("Количество символов не должно превышать 100", maxLength(100)),
    },
    firstName: {
        required: helpers.withMessage("Заполните поле", required),
        maxLength: helpers.withMessage("Количество символов не должно превышать 100", maxLength(100)),
    },
    middleName: {
        maxLength: helpers.withMessage("Количество символов не должно превышать 100", maxLength(100)),
    },
    phone: {
        maxLength: helpers.withMessage("Количество символов не должно превышать 100", maxLength(100)),
    },
    email: {
        maxLength: helpers.withMessage("Количество символов не должно превышать 255", maxLength(255)),
    },
    ogrnip: {
        required: helpers.withMessage("Заполните поле", required),
        numeric: helpers.withMessage("Поле должно быть числовым", numeric),
        maxLength: helpers.withMessage("Количество символов не должно превышать 50", maxLength(50)),
    },
    snils: {
        numeric: helpers.withMessage("Поле должно быть числовым", numeric),
        maxLength: helpers.withMessage("Количество символов не должно превышать 11", maxLength(11)),
    },
    inn: {
        numeric: helpers.withMessage("Поле должно быть числовым", numeric),
        maxLength: helpers.withMessage("Количество символов не должно превышать 12", maxLength(12)),
    },
    passportNumber: {
        maxLength: helpers.withMessage("Количество символов не должно превышать 10", maxLength(10)),
    },
    passportSeries: {
        maxLength: helpers.withMessage("Количество символов не должно превышать 10", maxLength(10)),
    }
}

function useValidator(data) {
    return useVuelidate(rules, data, { $stopPropagation: true });
} 

export default useValidator;