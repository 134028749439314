<template>
  <div class="form-control">
    <label class="label">{{label}}</label>
     <router-link class="link" :to="to"><slot></slot></router-link>
  </div>
</template>
<script>
export default {
  props: {
    label: {
      type: String,
      required: true,
    },
    to: {
      type: [Object, String],
      required: true,
    },
  },
};
</script>
<style scoped>
.link {
  font-size: 16px;
}
</style>