<template>
  <span class="doc-status">{{ status }}</span>
</template>
<script>
export default {
  props: {
    status: {
      type: String,
      required: true,
    },
  },
};
</script>
<style scoped>
.doc-status {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  height: 100%;
  padding: 3px 12px 3px 32px;
  border-radius: 6px;
  color: var(--secondary-blue-darker);
  white-space: nowrap;
}
.doc-status::before {
  content: '';
  position: absolute;
  width: 16px;
  height: 16px;
  background-repeat: no-repeat;
  background-position: 0 0;
  left: 12px;
  top: calc(50% - 8px);
}

.doc-status--inprocess {
  background-color: var(--basic-grey-light);
}
.doc-status--inprocess::before {
  background-image: url('../../../assets/svg/doc-statuses/inprocess.svg');
}
.doc-status--placed {
  background-color: var(--basic-green-light);
  color: var(--basic-green);
}
.doc-status--placed::before {
  background-image: url('../../../assets/svg/doc-statuses/placed.svg');
}
.doc-status--ready {
  background-color: var(--basic-yellow-light);
  color: var(--basic-yellow);
}
.doc-status--ready::before {
  background-image: url('../../../assets/svg/doc-statuses/ready.svg');
}
</style>
