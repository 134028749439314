import DocumentEditPage from '../components/gisogdDocuments/DocumentEditPage.vue';
import DocumentWithCorrection from '../components/gisogdDocuments/DocumentWithCorrection.vue';

const routes = [
    {
        path: 'fix-errors/document/:number([\\w-]+)',
        name: 'fixErrors',
        component: DocumentEditPage,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: 'document/:number([\\w-]+)/correction/:correctionId(\\w+)',
        name: 'documentWithCorrection',
        component: DocumentWithCorrection,
        meta: {
            requiresAuth: true,
        }
    }
]
export default routes;