import { StorageFile } from "./storageFile.js";

export class UploadFile extends StorageFile {
  constructor(uid, name, contentType, size) {
    super( name, "", contentType, size)
    this.uid = uid
    this.isLoading = true
  }
  addStorageUrl(url) {
    this.fileUrl = url;
    //this.isLoading = false;
  }
  setLoaded() {
    this.isLoading = false;
  }
  get id(){
    return this.uid;
  }
}
