import instance from '../api.js';
import DictionaryValue from '../models/dictionaryValue'


class RegistrationProcessDictionaryProxy {
  static async getDocumentTypes() {
      const dtos= await instance.get(`dictionaries/registration-process/document-types`);
      return DictionaryValue.fromDtos(dtos);
  }

  static async getDocumentStatuses() {
    const dtos= await instance.get(`dictionaries/registration-process/document-statuses`);
    return DictionaryValue.fromDtos(dtos);
  }

  static async getDocViewConstructions() {
    const dtos = await instance.get(`dictionaries/registration-process/document-view-constructions`);
    return DictionaryValue.fromDtos(dtos);
  }

  static async getDocRefSTPRFName() {
    const dtos = await instance.get(`dictionaries/registration-process/document-ref-stprf-name`);
    return DictionaryValue.fromDtos(dtos);
  }

  static async getDocRefKindEngIz() {
    const dtos = await instance.get(`dictionaries/registration-process/document-ref-kind-engiz`);
    return DictionaryValue.fromDtos(dtos);
  }
}

export default RegistrationProcessDictionaryProxy;
