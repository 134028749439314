import NoProjects from '../components/support/NoProjects';
const routes = [
  {
    path: '/support/noprojects',
    name: 'noprojects',
    component: NoProjects,
    meta: {
      requiresAuth: false,
    },
  },
];

export default routes;
