import instance from '../api.js';

class FileStorageProxy {
  static downloadFile(projectAlias, fileStorageId) {
    return instance.get(`/filestorage/${projectAlias}/${fileStorageId}`, {
      responseType: 'blob',
      noProjectContext: true
    });
  }
}

export default FileStorageProxy;
