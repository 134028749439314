<script>
import { toRefs, watch, onMounted } from "vue";
export default {
  props: {
    map: {
      type: Object,
      required: true,
    },
    leaflet: {
      type: Object,
      required: true,
    },
    geoJsonString: {
      type: String,
    },
  },

  setup(props) {
    const { geoJsonString, map, leaflet } = toRefs(props);
    const canvas = leaflet.value.canvas();
    let _selection = null;

    function highlightGeometry(geoJsonString) {
      if (!canvas) return;
      if (_selection) {
        _selection.remove();
        _selection = null;
      }
      if (geoJsonString) {
        const geoJson = JSON.parse(geoJsonString);
        _selection = leaflet.value.geoJSON(geoJson, {
          pointToLayer: (geojson, latlng) => {
            return leaflet.value.circleMarker(latlng);
          },
          renderer: canvas,
          fillColor: "rgba(230, 74, 25)",
          fillOpacity: 0.15,
          color: "#E64A19",
          opacity: 1,
          interactive: false,
        });
        _selection.addTo(map.value);
      }
    }
    onMounted(() => highlightGeometry(geoJsonString.value));

    watch(geoJsonString, () => {
      highlightGeometry(geoJsonString.value);
    });

    return () => {};
  },
};
</script>
