<template>
  <div class="wizard">
    <ul class="wizard__steps">
      <li
        class="wizard__steps-segment"
        v-for="(step, index) in steps"
        :key="index"
        :class="[
          { 'wizard__steps-segment--active': step.props.name === selectedStep },
          {
            'wizard__steps-segment--done':
              doneSteps.indexOf(step.props.name) >= 0,
          },
        ]"
      >
        <span class="wizard__steps-marker">{{ step.props.name }}</span>
        <div class="wizard__steps-content">
          <p class="">{{ step.props.description }}</p>
        </div>
      </li>
    </ul>

    <div>
      <slot></slot>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs, onBeforeMount, provide } from "vue";
export default {
  setup(_, { slots }) {
    const state = reactive({
      steps: [],
      selectedStep: "",
      doneSteps: [],
    });
    provide("WizardSteps", state);
    onBeforeMount(() => {
      if (slots.default) {
        state.steps = slots.default().filter(x=>typeof x.type !== 'symbol');
      }
    });

    return {
      ...toRefs(state),
    };
  },
};
</script>
<style scoped lang="scss">
.wizard__steps {
  width: 77%;
  list-style: none;
  padding: 0;
  display: flex;
  justify-content: space-between;
  margin-right: auto;
  margin-left: auto;
}

.wizard__steps-segment {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-basis: 50px;
  flex-grow: 1;
  flex-shrink: 1;
  max-width: 300px;
}

.wizard__steps-segment:not(:last-child) {
  margin-right: 20px;
}

.wizard__steps-segment:not(:last-child)::after {
  content: " ";
  position: absolute;
  height: 2px;
  width: calc(100% - 50px);
  background-color: var(--basic-grey);
  left: calc(50% + 35px);
  top: 40px;
}

.wizard__steps-marker {
  width: 40px;
  height: 40px;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: var(--basic-grey-dark);
  color: var(--basic-grey-slightly-lighter);
  font-size: 22px;
  letter-spacing: 0.02em;
}

.wizard__steps-segment--done .wizard__steps-marker {
  background-color: var(--primary-blue-lighter);
  background-image: url("../../../assets/svg/check.svg");
  background-repeat: no-repeat;
  background-position: 50% 50%;
  color: transparent;
}

.wizard__steps-segment--active .wizard__steps-marker {
  background-color: var(--primary-blue-lighter);
}

.wizard__steps-content {
  font-size: 15px;
  line-height: 19px;
  color: var(--secondary-blue-darker);
  margin-bottom: 7px;
  text-align: center;
}
</style>