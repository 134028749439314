<script>
import { toRefs } from "vue"
export default {
  props: {
    modelValue: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, ctx) {
    const { modelValue } = toRefs(props);
    function add() {
      modelValue.value.push({})
      return modelValue;
    }

    function remove(i){
      modelValue.value.splice(i,1);
      return modelValue;
    }
    return () => ctx.slots.default({
      add: add,
      remove: remove
    })
  },
};
</script>

<style scoped>
</style>