<template>
  <router-link
    class="button button--primary"
    :to="{
      name: 'registrationProcess',
      params: { number: number },
    }"
    ><slot></slot>
  </router-link>
</template>
<script>
export default {
  props: {
    number: Number,
  },
};
</script>