import { StorageFile } from './storageFile';

export default class InboxData {
  constructor(
    id,
    number,
    requestDate,
    outgoingNumber,
    outgoingDate,
    nameOfSenderSystem,
    requestSender = {},
    wayOfReceival = {},
    dataFormat = {},
    status,
    files = [],
    registeredDocuments = [],
    rejectionReason,
    processStatus,
    userCreatorName = ""
  ) {
    this.id = id;
    this.number = number;
    this.requestDate = requestDate;
    this.outgoingNumber = outgoingNumber;
    this.outgoingDate = outgoingDate;
    this.nameOfSenderSystem = nameOfSenderSystem;
    this.requestSender = requestSender;
    this.wayOfReceival = wayOfReceival;
    this.dataFormat = dataFormat;
    this.status = status;
    this.files = files;
    (this.registeredDocuments = registeredDocuments),
    (this.rejectionReason = rejectionReason),
    (this.processStatus = processStatus);
    this.userCreatorName = userCreatorName;
  }

  static fromDto(dto) {
    const files = dto.files.map(
      (f) =>
        new StorageFile(f.fileName, f.fileStorageUrl, f.contentType, f.size)
    );
    return new InboxData(
      dto.id,
      dto.number,
      new Date(dto.requestDate),
      dto.outgoingNumber,
      dto.outgoingDate !== null ? new Date(dto.outgoingDate) : null,
      dto.nameOfSenderSystem,
      dto.requestSender,
      dto.wayOfReceival,
      dto.dataFormat,
      dto.status,
      files,
      dto.registeredDocuments,
      dto.rejectionReason,
      dto.processStatus,
      dto.userCreatorName
    );
  }
}
