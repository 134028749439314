export default class CorrectionHistory {
    constructor(
        id,
        author,
        date,
        isFirst
    ) {
        this.id = id,
        this.author = author,
        this.date = date ? new Date(date) : null
        this.isFirst = isFirst
    }

    static fromDto(dto) {
        return new CorrectionHistory(dto.id, dto.author, dto.date, dto.isFirst)
    }
}