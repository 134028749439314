<template>
  <section class="wrapper">
    <div class="no-documents__image"></div>
    <p class="no-documents__text">Отсутствуют права на работу в ГИСОГД.</p>
  </section>
</template>

<script>
export default {
  components: {},
};
</script>

<style scoped>
.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.no-documents__image {
  margin-top: 78px;
  margin-bottom: 48px;
  width: 720px;
  height: 360px;
  background-image: url('../../assets/svg/we-are-working.svg');
  background-repeat: no-repeat;
}

.no-documents__text {
  margin-bottom: 24px;
  font-size: 16px;
}
</style>
