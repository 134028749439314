<template>
    <svg class="spinner" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.40261 2.22618C9.47229 2.13185 10.5491 2.29218 11.5449 2.69407C12.5407 3.09596 13.4272 3.72796 14.1317 4.53835C14.8363 5.34874 15.3389 6.31447 15.5984 7.35647C15.8579 8.39847 15.867 9.48713 15.6248 10.5333C15.3827 11.5795 14.8962 12.5534 14.2052 13.3754C13.5143 14.1974 12.6385 14.8441 11.6495 15.2625C10.6605 15.6809 9.5865 15.8591 8.5154 15.7826C7.44429 15.7061 6.40653 15.377 5.48709 14.8222" stroke="#518CFF" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
</template>
<script>
export default {
}
</script>
<style scoped>
</style>