<template>
  <div class="form-control">
    <div v-for="(error, index) of errors" :key="index">
      <div class="field-message--error">{{ error.$message }}</div>
    </div>
    <label class="label"><slot></slot></label>
    <file-list-input
      :files="files"
      :modelValue="modelValue"
      @update:modelValue="$emit('update:modelValue', $event)"
      :checkable="true"
      class="file-list--outline"
      data-qa="fromFiles"
    >
      <template #file="{file}">
        <slot name="file" :file="file">
        </slot>
      </template>
    </file-list-input>
  </div>
</template>
<script>
import FileListInput from "./FileListInput";

export default {
  emits: ["update:modelValue"],
  props: {
    files: {
      type: Array,
      required: true,
      default: () => [],
    },
    modelValue: {
      type: Array,
      required: true,
    },
    errors: {
      type: Array,
      default: () => [],
    },
  },
  components: { FileListInput },
};
</script>