import SubjectStatus from "./subjectStatus";

export default class Organization {
    constructor(name, organizationalForm, inn, ogrn, phone, email, externalId, displayName, type, subjectStatusKey, guid) {
        this.name = name;
        this.organizationalForm = organizationalForm;
        this.inn = inn;
        this.ogrn = ogrn;
        this.phone = phone;
        this.email = email;
        this.externalId = externalId;
        this.displayName = displayName;
        this.type = type;
        this.subjectStatus = SubjectStatus.fromDto(subjectStatusKey);
        this.guid = guid;
    }

    static fromDto(dto)
    {
        return new Organization(dto.name, dto.organizationalForm, dto.inn, dto.ogrn, dto.phone, dto.email, dto.externalId, dto.displayName, dto.type, dto.subjectStatusKey, dto.guid);
    }

    static fromDtos(dtos) {
        return dtos.map((dto) => new Organization(dto.name, dto.organizationalForm, dto.inn, dto.ogrn, dto.phone, dto.email, dto.externalId, dto.displayName, dto.type, dto.subjectStatusKey, dto.guid));
    }
}