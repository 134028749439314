<template>
  <div class="container">
    <section class="wrapper">
      <div class="not-found__image"></div>
      <h3 class="title-24">Здесь ничего нет</h3>
      <p class="not-found__text">Мы не смогли найти такую страницу. Это могло случиться из-за неверного адреса, или потому что страницы больше не существует.</p>
    </section>
  </div>
</template>
<style scoped>
.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.not-found__image {
  margin-top: 78px;
  margin-bottom: 48px;
  width: 500px;
  height: 404px;
  background-image: url("../assets/svg/404.svg");
  background-repeat: no-repeat;
}
.not-found__text {
  margin-bottom: 24px;
  font-size: 16px;
  max-width: 580px;
  text-align: center;
}
</style>