<template>
  <div class="form-control">
    <file-list-input
      :removable="!readonly"
      :files="files"
      :modelValue="files"
      @update:modelValue="$emit('update:modelValue', $event)"
      :class="{'file-list--outline':outline}"
    >
      <template #file="{file}">
        <base-file :file="file"/>
      </template>
    </file-list-input>
  </div>
</template>
<script>
import FileListInput from "./FileListInput";
import BaseFile from "../../files/BaseFile";

export default {
  emits: ["update:modelValue"],
  props: {
    files: {
      type: Array,
      required: true,
    },
    readonly:{
      type:Boolean,
      default:false
    },
    outline:{
      type:Boolean,
      default:true
    }
  },
  components: { FileListInput, BaseFile },
};
</script>