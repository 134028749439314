<template>
  <div class="">
    <OnClickOutside :do="unchooseCertificate">
      <div class="card" @click="chooseCertificate">
        <div class="card-list">
          <button class="card-item" :class="{ error: isError }">
            <div class="card-item__property">
              <div class="card-item__header">
                Субъект:
              </div>
              <div class="card-item__value">
                {{ subjectName }}
              </div>
            </div>
            <div class="card-item__property">
              <div class="card-item__header">
                Выдан:
              </div>
              <div class="card-item__value">
                {{ formatDateTime(new Date(certificate.validFrom)) }}
              </div>
            </div>
            <div class="card-item__property">
              <div class="card-item__header">
                Действителен до:
              </div>
              <div class="card-item__value">
                {{ formatDateTime(new Date(certificate.validTo)) }}
              </div>
            </div>
          </button>
        </div>
      </div>
    </OnClickOutside>
    <div class="card-error" v-if="isError">
      Выбран недействительный сертификат
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import OnClickOutside from "../basic/OnClickOutside";
export default {
  props: {
    certificate: {
      required: true,
      type: Object,
    },
  },
  components: { OnClickOutside },
  emits: ["onCertificateClick"],
  setup(props, { emit }) {
    const subjectName = ref("");
    const isError = ref(false);

    subjectName.value = props.certificate.subjectName.split(",").filter((x) => {
      return x.includes("CN=");
    })[0];
    const creationDate = new Date(props.certificate.validFrom);
    const expiredDate = new Date(props.certificate.validTo);

    function formatDateTime(value) {
      if (!value) return "";

      const date = `${value
        .getDate()
        .toString()
        .padStart(2, "0")}.${(value.getMonth() + 1)
        .toString()
        .padStart(2, "0")}.${value.getFullYear()}`;

      const time = `${value
        .getHours()
        .toString()
        .padStart(2, "0")}:${value
        .getMinutes()
        .toString()
        .padStart(2, "0")}`;

      return date + " " + time;
    }

    function chooseCertificate() {
      if (new Date(props.certificate.validTo) < new Date())
        isError.value = true;

      emit("onCertificateClick", props.certificate);
    }

    function unchooseCertificate() {
      isError.value = false;
    }

    return {
      subjectName,
      expiredDate,
      creationDate,
      formatDateTime,
      chooseCertificate,
      isError,
      unchooseCertificate,
    };
  },
};
</script>
<style lang="scss" scoped>
.card-item {
  background: #fefefe;
  border: 1px solid #dfe2e7;
  box-sizing: border-box;
  border-radius: 6px;
  padding: 29px 24px;
  position: static;
  width: 466px;
  height: 130px;
  color: #465362;
  margin-top: 0px;
  margin-bottom: 12px;
  text-align: left;

  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.04em;
}

.card-item:hover {
  cursor: pointer;
  border: 1px solid #518cff;
}

.card-item:focus {
  background: #eaf1ff;
  color: #518cff;
  border: 0px;
}

.card-item__property {
  display: flex;
  margin-bottom: 10px;
}

.card-item__header {
  margin-right: 16px;
}

.card-error {
  color: #ff4d3d;
  margin-top: -4px;
  margin-bottom: 8px;
  margin-left: 4px;

  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.04em;
}

.error {
  border: 1px solid #ff4d3d !important;
  border-radius: 6px;
}
</style>
