import DocBase from './docBase';
export default class DocNGPLoc extends DocBase {
  constructor(
    id,
    docStatus,
    docClass,
    docName,
    docNum,
    docDate,
    orgName,
    canceledDocuments=[],
    note,
    settlement,
    affectedMunicipalities = [],
    guid
  ) {
    super(
      id,
      docStatus,
      docClass,
      docName,
      docNum,
      docDate,
      orgName,
      canceledDocuments,
      note,
      affectedMunicipalities,
      guid
    );
    this.settlement = settlement || null;
  }
}
