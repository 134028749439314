import { render, h } from "vue";
import ToastComponent from "./Toast.vue";
import {TOAST_TYPE} from "./toastType";

let globalConfig = {
  transition: "fade",
  duration: 3000,
  message: "",
};
let seed = 1;

class Toast {
  constructor(options) {
    globalConfig = Object.assign({}, globalConfig, options);
  }
  show(text, config = {}) {
    let [toastWrapper, toastVNode] = [null, null];
    const id = "toast_" + seed++;
    toastWrapper = document.createElement("div");
    toastWrapper.id = id;
    toastVNode = h(
      ToastComponent,
      { ...globalConfig, ...config, message: text, id },
      null
    );
    render(toastVNode, toastWrapper);
    document.body.appendChild(toastWrapper);
  }
  error(text) {
    this.show(text, {toastType:TOAST_TYPE.error});
  }
  success(text) {
    this.show(text, {toastType:TOAST_TYPE.success});
  }
  warning(text) {
    this.show(text, {toastType:TOAST_TYPE.warning});
  }
}
export default Toast;
