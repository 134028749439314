import { ref } from "vue";
import EditableDocument from "../models/editableDocument";
import SectionDocumentsProxy from "../proxies/sectionDocumentsProxy";

export default function useEditableDocument(documentNumber) {
  const editableDocument = ref(new EditableDocument());

  fetch(documentNumber);

  async function fetch(documentNumber) {
    editableDocument.value = await SectionDocumentsProxy.getEditableDocument(
      documentNumber
    );
  }

  async function updateEditableDocument(
    registrationNumber,
    updatingDate,
    editableDocument,
    rawJson,
    signResult,
    certificateFN
  ) {
    await SectionDocumentsProxy.updateEditableDocument(
      registrationNumber,
      updatingDate,
      editableDocument,
      rawJson,
      signResult,
      certificateFN
    );
  }

  async function getSignData(item) {
    return await SectionDocumentsProxy.getSignData(item);
  }

  return {
    editableDocument,
    updateEditableDocument,
    getSignData,
  };
}
