import DocVectorZu from "./docVectorZu";
export default class DocGPZU extends DocVectorZu {
  constructor(
    id,
    docStatus,
    docClass,
    docName,
    docNum,
    docDate,
    orgName,
    canceledDocuments = [],
    note,
    zu = [],
    untilDate,
    affectedMunicipalities = [],
    guid
  ) {
    super(
      id,
      docStatus,
      docClass,
      docName,
      docNum,
      docDate,
      orgName,
      canceledDocuments,
      note,
      zu,
      affectedMunicipalities,
      guid
    );
    this.untilDate = untilDate;
  }
}
