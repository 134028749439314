
<script>
import useProjectAlias from "../hooks/projectAlias";

export default {
  async beforeRouteEnter() {
    const { projects, setProjectAlias, projectAlias, getGeometaProjects } = useProjectAlias();
    await getGeometaProjects();
    let goToProjectAlias = projects.value[0].projectAlias;
    if (projectAlias.value) goToProjectAlias = projectAlias.value;
    setProjectAlias(goToProjectAlias);
    return {
      name: "reesters",
      params: { projectAlias: goToProjectAlias },
    };
  },
};
</script>