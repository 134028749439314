<template>
  <ul class="checkbox-list">  
    <li class="checkbox checkbox-list__item" v-for="(project, index) of moList" :key="index">
      <input class="checkbox__checkbox visually-hidden"
        :id="project.projectAlias"
        type="checkbox"
        v-model="values"
        :value="project.projectAlias"
        @change="update(values)">
      <label class="checkbox__text" :for="project.projectAlias">{{ project.name }}</label>   
    </li>
  </ul>
</template>

<script>
import {toRefs, ref} from "vue";
import useProjectAlias from "../../hooks/projectAlias";

export default {
  emits: ["update:modelValue"],
  props: {
    modelValue: {
      type: Object,
      required: true,
    }
  },
  components: {},
  setup(props, {emit}) {
    const {modelValue: data} = toRefs(props);
    const {projects} = useProjectAlias();
    const moList = projects.value.filter(x => !x.isRegion);
    const values = ref([]);

    if (props.modelValue.affectedMunicipalities) {
      //если пришло значение из вне - проверяем если МО есть в списке доступных - добавляем в список выбраных
      for (const val of props.modelValue.affectedMunicipalities) {
        if (moList.find(x => x.projectAlias === val))
          values.value.push(val);
      }
    }

    function update(values) {
      data.value.affectedMunicipalities = values;
      emit("update:modelValue", data);
    }

    return {data, update, moList, values};
  },
};
</script>