import instance from "../../../api";
import DocumentSignatureInfo from "../models/documentSignatureInfo";

class DocumentSignatureInfoProxy {
  static async getSignatureInfoForDocument(documentNumber) {
    const result = await instance.get(`sign/${documentNumber}`);
    const documentSignatureInfo = DocumentSignatureInfo.fromDto(result);
    return documentSignatureInfo;
  }

  static async getSignatureInfoByTechnicalCorrectionId(
    sectionNumber,
    technicalCorrectionId
  ) {
    const result = await instance.get(
      `sign/section/${sectionNumber}/correction/${technicalCorrectionId}`
    );
    const documentSignatureInfo = DocumentSignatureInfo.fromDto(result);
    return documentSignatureInfo;
  }
}

export default DocumentSignatureInfoProxy;
