<script>
  export default {
    props: {
      do: {
        type: Function,
        required: true
      }
    },
    mounted() {
      const me = this;
      const listener = (e) => {
        if (e.target === this.$el || this.$el.contains(e.target)) {
          return;
        }
        me.do();
      };
      document.addEventListener('click', listener);
    },
    render() {
      return this.$slots.default()[0];
    }
  }
</script>