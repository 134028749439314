import { GeometrySource, geometrySourceFactory } from "../../../models/geometrySource";
import { StorageFile } from "../../../models/storageFile";
import { TerritorialPlanningRegulationStorageFile } from "../../../models/territorialPlanningRegulationStorageFile"


export default class EditableDocument {
    constructor(
        fullname,
        registrationNumber,
        registrationDate,
        createDate,
        stateSnapshot,
        geometrySource = new GeometrySource(),
        documentType,
        files = []
    ) {
        this.fullname = fullname,
            this.registrationNumber = registrationNumber,
            this.registrationDate = registrationDate,
            this.createDate = createDate,
            this.stateSnapshot = stateSnapshot,
            this.geometrySource = geometrySource,
            this.documentType = documentType,
            this.files = files
    }

    static fromDto(dto) {
        let files = [];
        let territorialPlanningRegulationFiles = [];
        if (dto.files != null) {
            files = dto.files.map(
                (f) =>
                    new StorageFile(f.fileName, f.fileStorageUrl, f.contentType, f.size)
            );
        }
        if (dto.territorialPlanningRegulationFiles != null) {
            territorialPlanningRegulationFiles = dto.territorialPlanningRegulationFiles.map(
                (f) =>
                    new TerritorialPlanningRegulationStorageFile(f.fileName, f.fileStorageUrl, f.contentType, f.size)
            );
        }
        const geometrySource = geometrySourceFactory(dto.geometrySource);

        return new EditableDocument(
            dto.fullName,
            dto.registrationNumber,
            dto.registrationDate ? new Date(dto.registrationDate) : null,
            dto.createDate,
            JSON.parse(dto.stateSnapshot),
            geometrySource,
            dto.documentType,
            files.concat(territorialPlanningRegulationFiles),
        )
    }
}