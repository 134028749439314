<template>
  <div class="page" data-qa="reestersPage">
    <page-header>
      <template #title> Реестр учета сведений</template>
      <!-- <template #subtitle>
        <span class=""> Последнее обновление 20 минут назад </span>
      </template> -->
      <template #buttons>
        <router-link
            v-if="haveEditRight"
            :to="{ name: 'registrationRequestCreate' }"
            class="button button--primary button--left-icon button-plus"
            data-qa="addEntry"
        >Добавить запись
        </router-link
        >
      </template>
    </page-header>
    <div class="box">
      <BasicTabs>
        <BasicTab name="Записи реестра" :hasRight="true">
          <FetchJson :url="inboxUrl">
            <template #default="{ json: inboxData, loading: inboxLoading }">
              <SearchField
                  v-model="inboxState.search"
                  class="table-search-field"
              ></SearchField>
              <div class="reesters-page__table-container">
                <TabSpinner v-if="inboxLoading"/>
                <InboxDataTable
                    v-else
                    :data="inboxData.items"
                    :query="inboxState.search"
                ></InboxDataTable>
                <Pagination
                    v-if="inboxData"
                    v-model:currentPage="inboxState.page"
                    :totalPages="inboxData.totalPages"
                />
              </div>
            </template>
          </FetchJson>
        </BasicTab>
        <BasicTab name="Черновики" :hasRight="haveEditRight">
          <FetchJson :url="registrationRequestUrl">
            <template #default="{ json: rrData, loading: rrLoading }">
              <SearchField
                  v-model="registrationRequestState.search"
                  class="table-search-field"
              ></SearchField>
              <div class="reesters-page__table-container">
                <TabSpinner v-if="rrLoading"/>
                <RegistrationRequestTable
                    v-else
                    :data="rrData.items"
                    :query="registrationRequestState.search"
                ></RegistrationRequestTable>
                <Pagination
                    v-if="rrData"
                    v-model:currentPage="registrationRequestState.page"
                    :totalPages="rrData.totalPages"
                />
              </div>
            </template>
          </FetchJson>
          <footer class="table-section__footer"></footer>
        </BasicTab>
      </BasicTabs>
    </div>
  </div>
</template>
<script>
import FetchJson from "../renderless/FetchJson";
import BasicTabs from "../basic/tabs/BasicTabs";
import BasicTab from "../basic/tabs/BasicTab";
import SearchField from "../basic/SearchField";
import RegistrationRequestTable from "./RegistrationRequestTable";
import InboxDataTable from "./InboxDataTable";
import TabSpinner from "../basic/tabs/TabSpinner";
import Pagination from "../basic/Pagination.vue";

import useAuthorization from "../../authorization";
import useSearch from "../../hooks/useSearch";

export default {
  components: {
    FetchJson,
    BasicTabs,
    BasicTab,
    SearchField,
    RegistrationRequestTable,
    InboxDataTable,
    TabSpinner,
    Pagination,
  },
  setup() {
    const {canEdit} = useAuthorization();
    const haveEditRight = canEdit();

    const {state: inboxState, url: inboxUrl} = {...useSearch("inboxdata")};
    const {state: registrationRequestState, url: registrationRequestUrl} = {
      ...useSearch("registration-request"),
    };

    return {
      inboxState,
      inboxUrl,
      registrationRequestState,
      registrationRequestUrl,
      haveEditRight,
    };
  },
};
</script>
<style scoped>
@media screen and (max-height: 1024px) {
  .reesters-page__table-container {
    height: 43vh !important;
  }
}

@media screen and (max-height: 830px) {
  .reesters-page__table-container {
    height: 35.5vh !important;
  }
}

@media screen and (max-height: 770px) {
  .reesters-page__table-container {
    height: 26vh !important;
  }
}

@media screen and (max-height: 768px) and (min-width: 1366px){
  .reesters-page__table-container {
    height: 32vh !important;
  }
}

.reesters-page__table-container {
  height: 54.5vh;
}

.button-plus::before {
  background-image: url("../../assets/svg/plus.svg");
}
</style>
