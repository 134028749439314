<template>
  <div>
    <div>
      <slot name="header"></slot>
      <form
        class="registration-request__form form form--fullwidth grid3on2"
        @submit.prevent
      >
        <fieldset class="registration-request__group">
          <legend class="title-20">Основные свойства</legend>
          <DateField
            :modelValue="modelValue.requestDate"
            @update:modelValue="update('requestDate', $event, v$.requestDate)"
            :errors="v$.requestDate.$errors"
            data-qa="requestDate"
            :max="maxDate"
          >
            <label class="label label--required"
              >Дата поступления на размещение</label
            >
          </DateField>
          <TextField
            :modelValue="modelValue.outgoingNumber"
            @update:modelValue="update('outgoingNumber', $event)"
            :errors="v$.outgoingNumber.$errors"
            data-qa="outgoingNumber"
          >
            <label class="label" for="registration-request-outgoing-number"
              >Исходящий номер сопроводительного письма</label
            >
          </TextField>
          <DateField
            :modelValue="modelValue.outgoingDate"
            @update:modelValue="update('outgoingDate', $event, v$.outgoingDate)"
            :errors="v$.outgoingDate.$errors"
            data-qa="outgoingDate"
            :max="maxDate"
          >
            <label class="label">Исходящая дата сопроводительного письма</label>
          </DateField>
          <UniversalSelectField
            :modelValue="modelValue.wayOfReceival"
            @update:modelValue="update('wayOfReceival', $event, v$.wayOfReceival)"
            :hasError="v$.wayOfReceival.$error"
            :canFind="false"
            :options="waysOfReceival"
            data-qa="wayOfReceival"
          >
            <label class="label label--required"
              >Способ направления данных</label
            >
            <template #validation>
              <ValidationMsg :errors="v$.wayOfReceival.$errors"></ValidationMsg>
            </template>
          </UniversalSelectField>
          <TextField
            :modelValue="modelValue.nameOfSenderSystem"
            @update:modelValue="update('nameOfSenderSystem', $event)"
            data-qa="senderSystemName"
          >
            <label class="label" for="registration-request-name-is"
              >Наименование ИС, из которой переданы данные</label
            >
          </TextField>
          <UniversalSelectField
            :modelValue="modelValue.dataFormat"
            @update:modelValue="update('dataFormat', $event, v$.dataFormat)"
            :hasError="v$.dataFormat.$error"
            :canFind="false"
            :options="dataFormats"
            data-qa="dataFormat"
          >
            <label class="label label--required"
              >Форма данных</label
            >
            <template #validation>
              <ValidationMsg :errors="v$.dataFormat.$errors"></ValidationMsg>
            </template>
          </UniversalSelectField>
          <!-- <DiamondUserField>
            <label class="label">Лицо, обработавшее запрос</label>
          </DiamondUserField> -->
          <SubjectField
            :modelValue="modelValue.requestSender"
            @update:modelValue="update('requestSender', $event)"
            :errors="v$.requestSender.$errors"
            data-qa="requestSender"
          >
            <template #label
              ><label
                class="label label--required"
                for="registration-request-person-who-sent"
              >
                Лицо, направившее сведения
              </label></template
            >
          </SubjectField>
        </fieldset>
        <fieldset class="form__fieldset">
          <legend class="title-20">Прикрепленные файлы</legend>
          <FilesField
            v-if="modelValue.files.length > 0"
            :files="modelValue.files"
            @update:modelValue="update('files', $event)"
            class="form-control"
            data-qa="files"
          ></FilesField>
          <FileUpload
            @startUpload="onStartUpload"
            @endUpload="onEndUpload"
            @failUpload="onFailUpload"
            class="form-control"
          />
        </fieldset>
      </form>
    </div>
  </div>
</template>
<script>
import FileUpload from "../basic/files/fileUpload/FileUpload";
import FilesField from "../basic/form/files/FilesField";
import SubjectField from "../basic/form/SubjectField";
//import DiamondUserField from "../basic/form/DiamondUserField";
import DateField from "../basic/form/DateField";
import TextField from "../basic/form/TextField";
import { ref } from "vue";
import RegistrationRequest from "../../models/registrationRequest";
import useRegistrationRequestRefs from "../../hooks/registrationRequestRefs";
import ValidationMsg from "../basic/form/select/ValidationMsg";
import UniversalSelectField from "@/components/basic/form/select/UniversalSelectField.vue";

export default {
  props: {
    modelValue: {
      type: Object,
      required: true,
      default: () => new RegistrationRequest(),
    },
    validator: {
      type: Object,
      required: true,
    },
  },
  components: {
    UniversalSelectField,
    ValidationMsg,
    FileUpload,
    FilesField,
    SubjectField,
    //DiamondUserField,
    DateField,
    TextField,
  },
  emits: ["update:modelValue", "update:isValid"],
  setup(props, { emit }) {
    const { waysOfReceival, dataFormats } = useRegistrationRequestRefs();

    function onStartUpload(inputFiles) {
      const files = props.modelValue.files;
      const newArr = inputFiles.slice();
      update("files", newArr.concat(files));
    }

    function onEndUpload(file) {
      const files = props.modelValue.files;
      const uploadedFile = files.find((f) => f.uid === file.uid);
      uploadedFile.setLoaded();
      update("files", files.slice());
    }

    function onFailUpload(file) {
      const files = props.modelValue.files;
      const newArr = files.slice();
      const index = newArr.findIndex((f) => f.uid === file.uid);
      if (index > -1) {
        newArr.splice(index, 1);
        update("files", newArr);
      }
    }

    const v$ = ref(props.validator);

    function update(fieldName, value, field) {
      const newValue = { ...props.modelValue };
      newValue[fieldName] = value;
      if (field) field.$model = value;
      emit("update:modelValue", newValue);
      emit("update:isValid", !v$.value.$invalid);
    }

    const maxDate = new Date().toISOString().split('T')[0];

    return {
      v$,
      onStartUpload,
      onEndUpload,
      onFailUpload,
      waysOfReceival,
      dataFormats,
      update,
      maxDate
    };
  },
};
</script>
<style scoped>
@media screen and (max-width: 890px) {
  .registration-request__form {
    display: flex;
    flex-direction: column;
  }
}

.registration-request__group {
  border: none;
  margin: 0;
  padding: 0;
}
.registration-request__form-buttons {
  display: flex;
  justify-content: flex-end;
}
</style>
