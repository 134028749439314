import DocBase from './docBase';
export default class DocForestRegulation extends DocBase {
  constructor(
      id,
      docStatus,
      docClass,
      docName,
      docNum,
      docDate,
      orgName,
      canceledDocuments=[],
      note,
      forestryRef,
      affectedMunicipalities = [],
      guid
  ) {
    super(
      id,
      docStatus,
      docClass,
      docName,
      docNum,
      docDate,
      orgName,
      canceledDocuments,
      note,
      affectedMunicipalities,
      guid
    );
    this.forestryRef = forestryRef || [];
  }
}
