export class RegisterDocumentDto {
  constructor(
    inboxNumber,
    itemId,
    registrationNumber,
    registrationDate,
    documentJson,
    signature,
    signatureFN
  ) {
    this.inboxNumber = inboxNumber;
    this.itemId = itemId;
    this.registrationNumber = registrationNumber;
    this.registrationDate = registrationDate;
    this.documentJson = documentJson;
    this.signature = signature;
    this.signatureFN = signatureFN;
  }
}
