import DocIzBase from "./docIzBase";

export default class DocIzSTPRF extends DocIzBase {
	constructor(
		id,
		docStatus,
		docClass,
		docName,
		docNum,
		docDate,
		orgName,
		note,
		description,
		stprfName = [],
		documentsOriginRegistration = [],
		affectedMunicipalities = [],
		guid
	) {
		super(
			id,
			docStatus,
			docClass,
			docName,
			docNum,
			docDate,
			orgName,
			note,
			description,
			documentsOriginRegistration,
			affectedMunicipalities,
			guid
		);
		this.stprfName = stprfName;
	}
}
