<template>
  <div class="page" v-if="!isLoading">
    <page-header class="container">
      <template #title>
        Запись реестра учета сведений №{{ registrationRequest.number }}
      </template>
      <template #subtitle>
        <to-reesters-link>Назад в реестр учета сведений</to-reesters-link>
      </template>
      <template #buttons>
        <toolbar
          @save="saveRegistrationRequest"
          @readyToInboxData="createInboxData"
        ></toolbar>
      </template>
    </page-header>
    <reqistration-request
      class="box container"
      v-model="registrationRequest"
      :validator="validator"
    >
    </reqistration-request>
  </div>
</template>
<script>
import ReqistrationRequest from "./RegistrationRequest";
import ToReestersLink from "../basic/ToReestersLink";
import Toolbar from "./RegistrationRequestPageToolbar";
import { useRoute, useRouter } from "vue-router";
import useValidator from "./validator";
import useRegistrationRequest from "../../hooks/registrationRequest";

export default {
  components: {
    ReqistrationRequest,
    ToReestersLink,
    Toolbar,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();

    const {
      registrationRequest,
      update,
      isLoading,
      createInboxdata,
      remove,
    } = useRegistrationRequest(route.params.number);

    const validator = useValidator(registrationRequest);

    async function saveRegistrationRequest() {
      validator.value.$touch();
      if (validator.value.$invalid) return;
      return update().then(() => {
        router.push({ name: 'registrationRequest', params: { number: route.params.number }});
      });
    }

    async function createInboxData() {
      validator.value.$touch();
      if (validator.value.$invalid) return;
      await createInboxdata();
      await remove(registrationRequest.value.number);
      router.push({ name: 'inboxData', params: { number: registrationRequest.value.number }});
    }

    return {
      registrationRequest,
      saveRegistrationRequest,
      createInboxData,
      validator,
      isLoading,
    };
  },
};
</script>
<style scoped>
.registration-request__header {
  padding-bottom: 36px;
}
.registration-request__header::before {
  background-image: url("../../assets/svg/edit.svg");
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
}

/* временная хрень */
.registration-request__select-button {
  margin-right: 15px;
}

.registration-request__select-button-list {
  position: absolute;
  margin-top: 50px;
}

.registration-request__select-button-list div {
  cursor: pointer;
}

.chevron::before {
  border-style: solid;
  border-width: 0.15em 0.15em 0 0;
  content: "";
  display: inline-block;
  height: 0.45em;
  left: 0.5em;
  position: relative;
  top: 0.25em;
  transform: rotate(135deg);
  vertical-align: top;
  width: 0.45em;
}
</style>
