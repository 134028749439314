export class CreateInboxDataDto {
  constructor(number,requestDate, outgoingNumber, outgoingDate, nameOfSenderSystem, requestSender = {}, wayOfReceival = {}, dataFormat = {},  files = [], registeredDocuments) {
    this.number = number;
    this.requestDate = requestDate;
    this.outgoingNumber = outgoingNumber;
    this.outgoingDate = outgoingDate;
    this.nameOfSenderSystem = nameOfSenderSystem;
    this.requestSender = requestSender;
    this.wayOfReceival = wayOfReceival;
    this.dataFormat = dataFormat;
    this.files = files;
    this.registeredDocuments = registeredDocuments;
  }
}