import 'es6-promise/auto';

import { createApp } from 'vue';
import router from './router/';
import { store } from './store';
import Toast from './plugins/toast/index';
import eventBus from './eventBus';

import App from './App.vue';
import PageHeader from './components/basic/PageHeader';
import BackLink from './components/basic/BackLink';
import ModalWindow from './components/basic/ModalWindow';
import FetchJson from './components/renderless/FetchJson';
import DocFieldsLazyList from './components/RegistrationProcess/RegistrationDocumentFields/_lazy';

const app = createApp(App);
app.config.globalProperties.eventBus = eventBus;
app.config.globalProperties.eventBus.on('404', () => {
  router.push('/404');
});
app.config.globalProperties.eventBus.on('403', () => {
  router.push('/403');
});

app.config.globalProperties.$consts = {
  userManualUrl: process.env.NODE_ENV === "development" ? 'https://help.gemsdev.ru/user/usages/ias/ias.gisogd/index.html' :'https://zemlya.dvinaland.ru/help/user/usages/ias/ias.gisogd/index.html',
  geometaUrl: process.env.NODE_ENV === "development" ? 'http://localhost:5000' :'https://zemlya.dvinaland.ru',
  bacgroundLayerUrl: process.env.NODE_ENV === "development"
      ? "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      : 'https://a.tile.openstreetmap.org',
  bacgroundLayerAttribution: process.env.NODE_ENV === "development"
      ? '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      : '',
};

app.config.globalProperties.$filters = {
  toDateString(value) {
    if (value instanceof Date) return value.toLocaleDateString('ru');
    else if (value === null) return null;
    return new Date(value).toLocaleDateString('ru');
  },
  empty(value) {
    if (value) return value;
    return '(Данные отсутствуют)';
  },
};

DocFieldsLazyList.forEach((cmp) => app.component(cmp.name, cmp.value));

app.component('page-header', PageHeader);
app.component('back-link', BackLink);
app.component('modal-window', ModalWindow);
app.component('FetchJson', FetchJson);

app.use(store);
app.use(router);
app.use(Toast, { duration: 5000 });

app.mount('#app');
