<template>
  <div class="file" :class="fileClass">
    <span class="file__name" :title="file.name">{{ file.name }}</span>
    <div class="file__buttons">
      <file-spinner v-if="isLoading" />
      <div class="group" v-else>
        <slot name="ext-buttons"></slot>
        <download-file-button :file="file" class="file__button group__item" />
      </div>
    </div>
  </div>
</template>
<script>
import DownloadFileButton from './DownloadFileButton.vue';
import FileSpinner from './FileSpinner.vue';
export default {
  props: {
    file: {
      type: Object,
      required: true,
    }
  },
  components: { DownloadFileButton, FileSpinner },
  created() {
    this.supportedFileExtensions = {
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        'file--doc',
      'application/msword':
        'file--doc',
      'application/vnd.oasis.opendocument.text':
        'file--doc',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        'file--exls',
      'application/vnd.ms-excel':
        'file--exls',
      'application/vnd.oasis.opendocument.spreadsheet':
        'file--exls',
      'application/pdf': 
        'file--pdf',
      'image/bmp': 
        'file--img',
      'image/gif': 
        'file--img',
      'image/jpeg': 
        'file--img',
      'image/png': 
        'file--img',
      'image/tiff': 
        'file--img',
    };
  },
  computed: {
    fileClass() {
      if (
        this.file.contentType &&
        this.file.contentType in this.supportedFileExtensions
      )
        return this.supportedFileExtensions[this.file.contentType];
      else return '';
    },
    isLoading() {
      return this.file.isLoading;
    },
  },
};
</script>
<style scoped>
.file {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-left: 32px;
  overflow: hidden;
  min-height: 21px;
}
.file__name {
  margin-right: 12px;
  word-break: break-all;
}
.file__buttons {
  display: flex;
}

.file__button {
  width: 20px;
  height: 20px;
  border: none;
  outline: none;
  cursor: pointer;
  background-color: transparent;
}

.file::before {
  content: '';
  position: absolute;
  width: 20px;
  height: 20px;
  background-image: url('../../../assets/svg/file-other.svg');
  background-repeat: no-repeat;
  background-position: 0 0;
  left: 0;
  top: -1px;
}

.file--doc::before {
  background-image: url('../../../assets/svg/file-doc.svg');
}

.file--exls::before {
  background-image: url('../../../assets/svg/file-exls.svg');
}

.file--pdf::before {
  background-image: url('../../../assets/svg/file-pdf.svg');
}

.file--img::before {
  background-image: url('../../../assets/svg/file-jpg.svg');
}
</style>
