import instance from '../api.js';
import DictionaryValue from '../models/dictionaryValue'


class InboxDataDictionaryProxy {
  static async getRejectionReasons() {
      const dtos= await instance.get(`dictionaries/inboxdata/rejection-reasons`);
      return DictionaryValue.fromDtos(dtos);
  }
}

export default InboxDataDictionaryProxy;
