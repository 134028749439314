export default class Downloader {
    static blob(blobData, filename) {

        // IE doesn't allow using a blob object directly as link href
        // instead it is necessary to use msSaveOrOpenBlob
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blobData);
            return;
        }

        let a = document.createElement('a');
        a.style = "display: none";
        const url = window.URL.createObjectURL(blobData);
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        setTimeout(function () {
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);
        }, 200);
    }
}
