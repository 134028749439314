<template>
  <div class="search-field">
    <input
      class="search-field__input field text"
      type="text"
      maxlength="1000"
      :placeholder="state.placeholder"
      v-model="state.searchText"
      data-qa="searchInput"
    />
    <button
      class="search-field__clear-button button button-icon"
      title="Сбросить поиск"
      @click="clear"
      v-if="state.searchText"
      data-qa="resetSearchInput"
    >
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.3999 13.6L13.5999 2.39999M13.5999 13.6L2.3999 2.39999"
          stroke="#D5D5D5"
          stroke-width="1.4"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </button>
  </div>
</template>

<script>
import { watch, ref, onBeforeUnmount } from "vue";
import _debounce from "lodash.debounce";
export default {
  props: {
    modelValue: {
      type: String,
      default: null,
    },
    searchDelay: {
      type: Number,
      default: 500,
    },
  },
  emits: ["update:modelValue"],
  setup(props, { emit }) {
    const state = ref({
      placeholder: "Поиск",
      searchText: props.modelValue,
    });

    function clear() {
      state.value.searchText = "";
    }

    let debounceFunc = undefined;
    function debouncedSearchText(searchText = "") {
      if (debounceFunc != undefined) debounceFunc.cancel();
      debounceFunc = _debounce(
        () => emit("update:modelValue", searchText),
        props.searchDelay
      );
      debounceFunc();
    }

    watch(
      () => state.value.searchText,
      (newValue) => debouncedSearchText(newValue)
    );

    watch(
      () => props.modelValue,
      (newValue, oldValue) => {
        if (newValue !== oldValue && !newValue) {
          clear();
        }
      }
    );

    onBeforeUnmount(() => {
      if (debounceFunc != undefined) debounceFunc.cancel();
    });

    return {
      state,
      clear,
    };
  },
};
</script>

<style scoped>
.search-field {
  position: relative;
}

.search-field__input {
  padding-top: 11px;
  padding-bottom: 11px;
  padding-right: 40px;
  padding-left: 16px;
}

.search-field__input::-webkit-input-placeholder {
  color: var(--secondary-blue-darker);
  padding-left: 28px;
  background-image: url("../../assets/svg/search.svg");
  background-repeat: no-repeat;
  background-size: 20px 20px;
}

.search-field__input::-moz-placeholder {
  color: var(--secondary-blue-darker);
  padding-left: 28px;
  background-image: url("../../assets/svg/search.svg");
  background-repeat: no-repeat;
  background-size: 20px 20px;
}

.search-field__input::placeholder {
  color: var(--secondary-blue-darker);
  padding-left: 28px;
  background-image: url("../../assets/svg/search.svg");
  background-repeat: no-repeat;
  background-size: 20px 20px;
}

.search-field__clear-button {
  position: absolute;
  top: calc(50% - 20px);
  right: 0;
}
</style>
