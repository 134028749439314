import { StorageFile } from "../../../models/storageFile";

export default class Correction {
    constructor(
        key,
        fields = [],
        removedFiles = [],
        addedFiles = [],
        removedTprFiles = [],
        addedTprFiles = []
    ) {
        this.key = key,
        this.fields = fields,
        this.removedFiles = removedFiles,
        this.addedFiles = addedFiles, 
        this.removedTprFiles = removedTprFiles,
        this.addedTprFiles = addedTprFiles
    }

    static fromDto(dto) {
        let removedFiles = [];
        let addedFiles = [];
        let removedTprFiles = [];
        let addedTprFiles = [];

        if(dto.removedFiles != null) {
            removedFiles = dto.removedFiles.map(
                (f) => new StorageFile(f.fileName, f.fileStorageUrl, f.contentType, f.size)
            );
        }
        if(dto.addedFiles != null) {
            addedFiles = dto.addedFiles.map(
                (f) => new StorageFile(f.fileName, f.fileStorageUrl, f.contentType, f.size)
            );
        }
        if(dto.removedTprFiles != null) {
            removedTprFiles = dto.removedTprFiles.map(
                (f) => new StorageFile(f.fileName, f.fileStorageUrl, f.contentType, f.size)
            );
        }
        if(dto.addedTprFiles != null) {
            addedTprFiles = dto.addedTprFiles.map(
                (f) => new StorageFile(f.fileName, f.fileStorageUrl, f.contentType, f.size)
            );
        }

        return new Correction(dto.key, dto.fields, removedFiles, addedFiles, removedTprFiles, addedTprFiles);
    }
}